import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  addIELTSReadingSet,
  getIELTSSingleReadingSet,
  getIELTSSingleReadingSetSuccess,
} from "store/actions"
import { getCourseType } from "store/CourseType/actions"

const CreateSet = ({ edit, history }) => {
  const { serviceId, setId } = useParams()
  const {
    authToken,
    data,
    typeLoading,
    adding,
    singlePackage,
    singleLoading,
    loading,
    singleReadingSet,
  } = useSelector(state => ({
    authToken: state.Login.token,
    data: state.courseType.data,
    typeLoading: state.courseType.loading,
    singlePackage: state.packageReducer.singlePackage?.singlePackage,
    singleLoading: state.packageReducer.singleLoading,
    adding: state.readingReducer.adding,
    loading: state.readingReducer.loading,
    singleReadingSet: state.readingReducer.singleReadingSet,
  }))
  console.log(
    `🦄 ~ file: CreateSet.js ~ line 16 ~ CreateSet ~ loading`,
    loading,
    singleReadingSet
  )
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Reading Set",
      link: `/reading-set/${serviceId}`,
    },
    {
      title: "Create Set",
      link: "#",
    },
  ]
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getIELTSSingleReadingSetSuccess({}))
    dispatch(getCourseType(authToken))
    // return () => {
    // }
    if (edit) {
      dispatch(getIELTSSingleReadingSet(authToken, setId))
    }
  }, [])

  function handleSubmit(event, errors, values) {
    event.preventDefault()
    if (errors.length) {
      return toaster("warning", "Can't submit empty data")
    }
    const { setName, ...rest } = values
    if (edit) {
      dispatch(
        addIELTSReadingSet(
          setName === singleReadingSet?.setName ? rest : values,
          history,
          authToken,
          serviceId,
          setId
        )
      )
    } else {
      dispatch(addIELTSReadingSet(values, history, authToken, serviceId))
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Edit Set" : "Create Set"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <h5>{edit ? "Edit" : "Create"} Set</h5>
            {loading && edit ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <AvForm className="mt-4" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md="3">Set name</Col>
                  <Col md="9">
                    <AvField
                      name="setName"
                      type="text"
                      errorMessage="Type set name"
                      placeholder="Type set name"
                      value={singleReadingSet?.setName || ""}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="3">Course Type</Col>
                  <Col md="9">
                    {typeLoading ? (
                      <Loader />
                    ) : (
                      <AvField
                        type="select"
                        name="courseType"
                        // disabled={edit}
                        errorMessage="Select course type"
                        placeholder="Select course type"
                        value={singleReadingSet?.courseType || ""}
                        required
                      >
                        <option value="">Select Course Type</option>
                        {data?.map((dt, idx) => (
                          <option key={idx} value={dt._id}>
                            {dt.courseTypeName}
                          </option>
                        ))}
                      </AvField>
                    )}
                  </Col>
                </Row>

                {/*  */}

                <Row className="mb-3">
                  <Col md="3">Total Question</Col>
                  <Col md="9">
                    <AvField
                      name="totalQuestion"
                      type="number"
                      errorMessage="Type total question"
                      placeholder="Type total question"
                      value={singleReadingSet?.totalQuestion || ""}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="3">Description</Col>
                  <Col md="9">
                    <AvField
                      name="description"
                      type="textarea"
                      rows="4"
                      errorMessage="Type description"
                      placeholder="Type description"
                      value={singleReadingSet?.description || ""}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="3"></Col>
                  <Col md="9">
                    <div>
                      <button
                        disabled={adding}
                        type="submit"
                        className="btn btn-primary w-md"
                      >
                        {adding
                          ? "Submitting..."
                          : edit
                          ? "Update Set"
                          : "Create Set"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CreateSet)
