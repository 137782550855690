import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Label,
  Row,
  Table,
} from "reactstrap"
import {
  getService,
  getSinglePackage,
  getSinglePackageSuccess,
} from "store/actions"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Package List",
    link: "/packages",
  },
  {
    title: "View Package",
    link: "#",
  },
]

const PackageDetails = () => {
  const dispatch = useDispatch()
  let { id } = useParams()
  const { authtoken, error, value, loading, services, message } = useSelector(
    state => ({
      authtoken: state.Login.token,
      loading: state.packageReducer.loading,
      error: state.packageReducer.error,
      value: state.packageReducer.singlePackage?.singlePackage,
      services: state.serviceReducer.services,
      message: state.packageReducer.message,
    })
  )
  console.log(
    `🦄 ~ file: PackageDetails.js ~ line 29 ~ PackageDetails ~ value`,
    value,
    loading
  )
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [objvalue, setObjValue] = useState({})

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }

  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    }
  }
  useEffect(() => {
    dispatch(getSinglePackage(id, authtoken))
    dispatch(getService(authtoken))
    return () => {
      dispatch(getSinglePackageSuccess({}))
    }
  }, [])

  const handleClick = (serviceId, serviceName) => {
    // console.log("package and service", serviceId, serviceName, authtoken)
    // dispatch(addServiceToPackage(authtoken, serviceId, id))
  }
  const handleAddinObj = (value, id) => {
    let obj = { ...objvalue }
    obj[id] = value
    setObjValue(obj)
  }

  const hanldeShow = () => {
    console.log("hello data", objvalue)
  }
  const handleSubmit = () => {}
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"View Package"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-2">
          <Col md={8} className="offset-md-2">
            <Card>
              <CardImg top className="img-fluid" src={""} alt="packageimage" />
              <CardBody>
                <CardTitle className="mt-0">
                  <h3>{value?.packageName}</h3>
                  <p style={{ marginLeft: "70%", marginTop: "-1.5em" }}>
                    {value?.price} Taka
                  </p>
                </CardTitle>
                <p>
                  Duration {value?.expireDate || "7"} Day
                  {value?.expireDate > 1 ? "s" : ""}
                </p>
                <br />
                <p>{value?.description}</p>
                <br />
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="setservice">Set Your Services</Label>
                    <textarea
                      className="form-control mb-3"
                      placeholder="Please select service from below"
                      rows="3"
                    />
                  </div>
                </Row>
                <Row>
                  <Col xl={12}>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Service Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[]?.map((service, i) => (
                            <tr key={i}>
                              <td>{service.serviceName} Service</td>
                              <td>
                                <button
                                  onClick={() =>
                                    handleClick(
                                      service._id,
                                      service.serviceName
                                    )
                                  }
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                <Link to="#" className="btn btn-primary">
                  Save
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <form onSubmit={handleSubmit}>
          <div className="inner-repeater mb-4">
            <Label>Phone no :</Label>
            <table style={{ width: "100%" }}>
              <tbody>
                {(rows1 || []).map((formRow, key) => (
                  <tr key={key}>
                    <td>
                      <Row className="mb-2">
                        <Col md="10">
                          <Input
                            type="text"
                            onChange={e =>
                              handleAddinObj(e.target.value, formRow.id)
                            }
                            className="inner form-control"
                            placeholder="Enter your phone no..."
                          />
                        </Col>
                        <Col md="2">
                          <Button
                            color="primary"
                            className="btn-block inner"
                            id="unknown-btn"
                            style={{ width: "100%" }}
                            onClick={e => {
                              handleRemoveRow(formRow.id)
                            }}
                          >
                            {" "}
                            Delete{" "}
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              onClick={() => {
                handleAddRowNested()
              }}
              color="success"
              className="mt-1"
            >
              Add Number
            </Button>
            <Button onClick={hanldeShow}>Submit Data</Button>
          </div>
        </form> */}
      </InnerLayer>
    </React.Fragment>
  )
}
export default PackageDetails
