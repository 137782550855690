import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { findTime } from "helpers/Custom/timeHelper"
import InnerLayer from "Layout/InnerLayer"
import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { getSingleSpeakingSetsSingleDay } from "store/Speaking/actions"
import clockIcon from "../ExamSet/Speaking/img/clock.svg"
import profileIcon from "../ExamSet/Speaking/img/Group.png"
const time = {
  10: `10:00 - 10:30`,
  1030: `10:30 - 11:00`,
  11: `11:00 - 11:30`,
  1130: `11:30 - 12:00`,
  12: `12:00 - 12:30`,
  1230: `12:30 - 01:00`,
  1: `01:00 - 01:30`,
  130: `01:30 - 02:00`,
  2: `02:00 - 02:30`,
  230: `02:30 - 03:00`,
  3: `03:00 - 03:30`,
  330: `03:30 - 04:00`,
  4: `04:00 - 04:30`,
  430: `04:30 - 05:00`,
  5:`05:00 - 05:30`,
  530:`05:30 - 06:00`,
  6:`06:00 - 06:30`,
  630:`06:30 - 07:00`,
  7:`07:00 - 07:30`,
  730:`07:30 - 08:00`,
}

const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Teacher Exam",
    link: `/teacher-exam`,
  },
  {
    title: "View time slot",
    link: `#`,
  },
]

const TeacherSingleDay = ({ history }) => {
  const { date } = useParams()
  const dispatch = useDispatch()
  const { authToken, loading, singleDay, teacherId, role } = useSelector(
    state => ({
      authToken: state.Login.token,

      teacherId: state.Login.id,
      role: state.Login.userrole,
      loading: state.speakingSet.loading,
      singleDay: state.speakingSet.singleDay,
    })
  )

  console.log(`teacherId, date`, teacherId, date)
  console.log(`loading`, singleDay)

  useEffect(() => {
    dispatch(getSingleSpeakingSetsSingleDay(authToken, { date, role }))
  }, [])

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Time Slot"
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Time Slot</h4>
            </div>
          </CardComponent>
        </Row>
        {/* <Row>
          <CardComponent></CardComponent>
        </Row> */}

        <Row>
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : singleDay?.length > 0 ? (
            singleDay.map((item, idx) => (
              <Col lg={4} className="mb-3" key={idx}>
                <div className="single-student-time-main-wrap">
                  <div className="single-exam-time-schedule">
                    <div className="single-exam-time-left-part">
                      <div className="single-exam-schedule-left-icon">
                        <img src={clockIcon} alt="" />
                      </div>
                      <div className="single-exam-schedule-left-content">
                        <h5>{time[item?.meetingTime]}</h5>
                        <p>
                          {moment(item?.date?.split("T")?.[0])?.format(
                            "DD MMM YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="single-exam-time-right-part">
                      {/* <button
                        className="btn btn-sm"
                        onClick={() =>
                          history.push(
                            `/teacher-exam/view-class/${date}/single-slot/${item?._id}`
                          )
                        }
                        disabled={!item?.studentId}
                      >
                        Join
                      </button> */}
                      {/* <button
                        className="btn btn-sm"
                        onClick={() =>
                          window.location.replace(item?.teacherId?.meetLink)
                        }
                        disabled={
                          item?.complete === 1 ||
                          !item?.studentId?._id ||
                          !findTime(item?.meetingTime, item, date)
                        }
                      >
                        Join here
                      </button> */}
                    </div>{" "}
                  </div>{" "}
                  {item?.studentId ? (
                    <div
                      className="single-exam-time-schedule"
                      onClick={() =>
                        history.push(
                          `/teacher-exam/view-class/${date}/single-slot/${item?._id}`
                        )
                      }
                    >
                      <div className="single-exam-time-left-part">
                        <div className="single-exam-schedule-left-icon">
                          <img src={profileIcon} alt="" />
                        </div>
                        <div className="single-exam-schedule-left-content">
                          <h5>{item?.studentId?.fullName}</h5>
                          <p>Details</p>
                        </div>
                      </div>
                      <div className="single-exam-time-right-part-student">
                        <a>
                          <i className="bx bx-chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="single-exam-time-schedule text-center">
                      <span className="text-center">No student admitted</span>
                    </div>
                  )}
                </div>
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h3>No data found</h3>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TeacherSingleDay)
