import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { imageBaseUrl } from "helpers/api_helper"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect } from "react"
import { Card, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import {
  getUserProfile,
  submitSpeakingMarks,
} from "store/SpeakingQuestion/actions"
import profileImage from "./img/profile-image.png"
import "./teacher-review.scss"
const TeacherExamReviewResult = ({ history }) => {
  const { date, slotId } = useParams()
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Teacher Exam",
      link: `/teacher-exam`,
    },
    {
      title: "View time slot",
      link: `/teacher-exam/view-class/${date}`,
    },
    {
      title: "Review Slot",
      link: `/teacher-exam/view-class/${date}/single-slot/${slotId}/`,
    },
    {
      title: "Set Result",
      link: `#`,
    },
  ]

  const dispatch = useDispatch()
  const { authToken, loading, adding, profile } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.speakingQues.loading,
    adding: state.speakingQues.adding,
    profile: state.speakingQues.profile,
  }))

  // console.log(`TeacherExamReview ~ findSlot`, profile, loading)

  useEffect(() => {
    dispatch(getUserProfile(authToken, slotId))
  }, [])

  const handleSubmit = (e, errors, values) => {
    e.preventDefault()
    // console.log(`TeacherExamReview ~ handleSubmit`, values)
    if (errors.length > 0) return
    // console.log(`TeacherExamReview ~ handleSubmit`, values)
    dispatch(
      submitSpeakingMarks({ token: authToken, value: values, slotId, history })
    )
  }
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return profileImage
  }

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Result Set"
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Result Set</h4>
            </div>
          </CardComponent>
        </Row>
        <div className="teacher-speaking-exam-review-wrap">
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : (
            <div className="teacher-speaking-exam-review-wrap-single-profile">
              <Card>
                <Card.Header>
                  <div className="exam-review-profile-image">
                    <img
                      src={getPhotoUrl(profile?.studentId?.photo)}
                      alt="Profile"
                      style={{ objectFit: "cover" }}
                    />{" "}
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="exam-review-profile-content-wrap">
                    <div className="profile-name">
                      <h4>{profile?.studentId?.fullName}</h4>
                    </div>
                    <div className="student-result-input-field-title">
                      <h5>{profile?.studentId?.fullName}</h5>
                      <AvForm onSubmit={handleSubmit}>
                        <div className="result-data-field-wrap">
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Fluency and Coherence</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="number"
                                className="form-control"
                                name="grammar"
                                required
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                  min: {
                                    value: 0,
                                    errorMessage: "Minimum value is 0",
                                  },
                                  max: {
                                    value: 9,
                                    errorMessage: "Maximum value is 9",
                                  },
                                }}
                                min="0"
                                max="9"
                              />
                              {/* <input type="number" className="form-control" /> */}
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Lexical Resource</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="number"
                                className="form-control"
                                name="spelling"
                                required
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                  min: {
                                    value: 0,
                                    errorMessage: "Minimum value is 0",
                                  },
                                  max: {
                                    value: 9,
                                    errorMessage: "Maximum value is 9",
                                  },
                                }}
                                min="0"
                                max="9"
                              />
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Pronunciation</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="number"
                                className="form-control"
                                name="adminEvualation"
                                required
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                  min: {
                                    value: 0,
                                    errorMessage: "Minimum value is 0",
                                  },
                                  max: {
                                    value: 9,
                                    errorMessage: "Maximum value is 9",
                                  },
                                }}
                                min="0"
                                max="9"
                              />
                              {/* <input type="number" className="form-control" /> */}
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Grammatical range and accuracy</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="number"
                                className="form-control"
                                name="totalMark"
                                required
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                  min: {
                                    value: 0,
                                    errorMessage: "Minimum value is 0",
                                  },
                                  max: {
                                    value: 9,
                                    errorMessage: "Maximum value is 9",
                                  },
                                }}
                                min="0"
                                max="9"
                              />
                              {/* <input type="number" className="form-control" /> */}
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Time Spent</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="number"
                                className="form-control"
                                name="timeSpent"
                                required
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                  min: {
                                    value: 0,
                                    errorMessage: "Minimum value is 0",
                                  },
                                
                                }}
                                min="0"
                                
                              />
                              {/* <input type="number" className="form-control" /> */}
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Recording Link</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="text"
                                className="form-control"
                                name="recodingLink"
                                required
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                }}
                              />
                              {/* <input type="number" className="form-control" /> */}
                            </div>
                          </div>{" "}
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Remark</label>
                            </div>
                            <div className="result-field">
                              <AvField
                                type="textarea"
                                className="form-control"
                                name="remark"
                                rows="3"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: "This value is required",
                                  },
                                }}
                              />
                              {/* <textarea
                                type="number"
                                className="form-control"
                              /> */}
                            </div>
                          </div>{" "}
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={adding}
                                style={{
                                  padding: "8px 30px",
                                  background: "#0052CC",
                                }}
                              >
                                {adding ? "Submitting..." : "Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TeacherExamReviewResult)
