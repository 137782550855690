import CardComponent from "components/Common/CardComponent"
import UserProfile from "components/Common/UserProfile"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { getSingleTeacher, registerTeacher } from "store/teacher/actions"
import logo from "../../assets/images/Icon/Frame.svg"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Teacher List",
    link: "/teachers-list",
  },
  {
    title: "Register",
    link: "#",
  },
]

const Register = ({ history, edit }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { authToken, teacher, loading, adding } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.teacherReducer.loading,
    teacher: state.teacherReducer.teacher,
    adding: state.teacherReducer.adding,
  }))
  console.log(`loading`, teacher, loading)
  useEffect(() => {
    if (edit) {
      dispatch(getSingleTeacher(id, authToken))
    }
  }, [])
  const [profileImage, setProfileImage] = useState(null)
  const onChangeImage = e => {
    const file = e.target?.files?.[0]
    setProfileImage(file)
  }
  const register = data => {
    const fd = new FormData()
    for (const obj in data) {
      fd.append(obj, data[obj])
    }
    if (profileImage) {
      fd.append("photo", profileImage)
    } else {
      !!data?.photo && fd.append("photo", data.photo)
    }
    for (const obj of fd.entries()) {
      console.log(obj)
    }
    console.log(`data`, data)
    const options = {
      history,
      edit: edit,
      id,
      authToken,
    }
    dispatch(registerTeacher(fd, options))
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return logo;
  };
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Register"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ maxWidth: "500px" }} className="mx-auto">
          <CardComponent>
            <UserProfile
              title={"Teacher Register"}
              data={{
                name: teacher?.fullName || "",
                email: teacher?.email || "",
                designation: toCapitalize(teacher?.role || "Teacher"),
                phone: teacher?.phoneNumber || "",
                bio: teacher?.bio || "",
              }}
              isBio
              submitting={adding}
              image={getPhotoUrl(teacher?.photo)}
              profileImage={profileImage}
              onChangeImage={onChangeImage}
              handleData={register}
              view={false}
              buttonText={adding ? `Submitting...` : `Register`}
            />
          </CardComponent>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(Register)
