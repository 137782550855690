import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { get, imageBaseUrl, imageUrl } from "helpers/api_helper"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import { getStudents } from "store/actions"
import avatar2 from "../../assets/images/Icon/Frame.svg"
import * as XLSX from "xlsx";
const StudentList = ({ history }) => {
  const dispatch = useDispatch()
  const [loadingExcel, setLoadingExcel] = useState(false);
  const { authtoken, students, loading } = useSelector(state => ({
    authtoken: state.Login.token,
    loading: state.studentReducer.loading,
    students: state.studentReducer.students,
  }))
  console.log(`students, loading`, students, loading)

  useEffect(() => {
    dispatch(getStudents(authtoken))
  }, [])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return avatar2
  }
  const handleExcel = async () => {
    setLoadingExcel(true);
    let userData = [];


      await get(
        `admin/get-students`,
        { headers: { Authorization: `Bearer ${authtoken}` } }
      )
        .then((response) => {
          console.log("response", response);
          response?.getData?.forEach((user, index) => {
            let data = {};
            data.UserName = user?.fullName;
            data.Email = user?.email;
            data.RegistrationDate = user?.createdAt?.slice(0,10);
            data.status = user?.accountActivateToken ? "In active" : "Active"
            data.role = user?.role
            data.PhoneNumber = user?.phoneNumber
            data.University = user?.university?.universityName
            
            userData.push(data);
          });
     
        })
        .catch((error) => {
          console.log("kkkk");
          // setResults((prev) => [...prev]);
        });
    

    downloadxls(userData);
  };
  const downloadxls = (data) => {
    console.log(XLSX.version);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "User List.xlsx");
    setLoadingExcel(false);
  };

  return (
    <React.Fragment>
      {/* list */}
      <InnerLayer
        isBreadCrumb={true}
        title="Student List"
        mainTitle={"Dashboard"}
        subTitle={"Student List"}
        wrapperClass="py-3"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Student List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </CardComponent>
        <Row>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : students?.length > 0 ? (
            students?.map((data, idx) => (
              <Col
                mg={6}
                xl={3}
                key={idx}
                style={{ cursor: "pointer" }}
                // onClick={() => history.push(`/student/${data?._id}`)}
              >
                <Card className="text-center pt-4 position-relative">
                  <Link to={`/student/${data?._id}`}>
                    <div >
                      <div
                        className="position-absolute"
                        style={{ top: "4%", right: "4%" }}
                      >
                        <span
                          className={"font-size-10 pill "}
                          style={{
                            backgroundColor: data?.accountActivateToken
                              ? "#F46A6A"
                              : "#34C38F",
                          }}
                        >
                          {data?.accountActivateToken ? "In active" : "Active"}
                        </span>
                      </div>
                      <div className="mx-auto">
                        <img
                          className="d-flex rounded-circle avatar-sm m-auto"
                          src={getPhotoUrl(data?.photo)}
                          style={{ objectFit: "cover" }}
                          alt="XamPro"
                        />
                      </div>
                      <CardBody style={{ textAlign: "center" }}>
                        <h5 className="font-size-15">
                          {toCapitalize(data?.fullName)}
                        </h5>
                        <p className="mb-0 font-size-12">Student</p>
                      </CardBody>
                    </div>
                  </Link>
                  <div onClick={() => history.push(`/purchase/${data?._id}`)}>
                    <button className="btn btn-primary">
                      Purchase History
                    </button>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div>
              <h3>No Student List</h3>
            </div>
          )}
              <div className="text-center">
            <button
              className="btn btn-info m-auto w-25 fw-bold excel_button"
              disabled={loadingExcel}
              onClick={handleExcel}
            >
              {loadingExcel ? "Downloading..." : "Download Excel"}
            </button>
          </div>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(StudentList)
