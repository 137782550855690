import CardComponent from "components/Common/CardComponent"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import React from "react"
import { useParams, withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
const imageHelper = data => {
  switch (data.serviceName?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg"
    case "speaking":
      return "speaking.svg"
    case "writing":
      return "writing.svg"
    case "listening":
      return "listening.svg"
    default:
      return "reading.svg"
  }
}

const PackageCard = ({ userService, history }) => {
  const { id } = useParams()
  const handler = data => {
    switch (data?.serviceName?.toLowerCase()?.trim()) {
      case "reading":
        break
      case "listening":
        break
      case "writing":
        history.push(`/review-answer/writing`)
        break
      default:
        break
    }
  }
  return (
    <React.Fragment>
      <div
        className="single-package-inner-main-wrap mx-auto"
        style={{ maxWidth: "80%" }}
      >
        <Row>
          {(userService || []).map((data, key) => (
            <Col lg={6} md={6} key={key}>
              <div className="card-active-deactive-content">
                <CardComponent className={"text-center py-4"}>
                  <div className="mx-auto mb-3">
                    <img
                      src={require(`./img/${imageHelper(data)}`).default}
                      alt="examImage"
                    />
                  </div>
                  <div>
                    <h5 className="mb-1">{toCapitalize(data.serviceName)}</h5>
                    {/* <p className="mb-3">1st out of 7th attempt</p> */}
                    {/* <Link to={`/reading-exam/${data._id}`}> */}
                    <button
                      className="btn btn-primary"
                      style={{ backgroundColor: "#0052CC" }}
                      onClick={() => handler(data)}
                    >
                      View Result
                    </button>
                    {/* </Link> */}
                  </div>
                </CardComponent>
              </div>
            </Col>
          ))}
          {/* {(packagedesc.packageService || []).map((data, key) => {
            return (
              <Col lg={6} key={key}>
                <div className="single-package-data-column">
                  <Row>
                    <Col lg={7} className="my-auto">
                      <div className="single-package-data-column-left">
                        <h4>{toCapitalize(data.serviceName)}</h4>
                        <p>1st out of 7th attempt</p>
                        <Link to={`/reading-exam/${data._id}`}>
                          <button className="btn btn-primary">
                            Start Your Exam
                          </button>
                        </Link>
                      </div>
                    </Col>
                    <Col lg={5} className="my-auto">
                      <div className="single-package-data-column-right">
                        <img src={icon1} alt="" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })} */}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PackageCard)
