import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import { createPassageSchema } from "components/Schemas/createPassageSchema"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import { postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSReadingPassage,
  getIELTSReadingSinglePassage,
  getIELTSReadingSinglePassageSuccess,
  getIELTSSingleReadingSet,
  getService,
} from "store/actions"
const initialValues = {
  title: "",
  description: [
    {
      passageInfo: "",
    },
  ],
  totalQuestion: "",
  // permissions: [""],
}
const CreatePassage = ({ history, edit }) => {
  const { serviceId, setId, id } = useParams()
  const [listValue, setListValue] = useState(null)
  const dispatch = useDispatch()
  const {
    authToken,
    singleReadingSet,
    loading,
    adding,
    singlePassage,
    services,
  } = useSelector(state => ({
    authToken: state.Login.token,
    singleReadingSet: state.readingReducer.singleReadingSet,
    loading: state.readingReducer.loading,
    adding: state.readingReducer.adding,
    singlePassage: state.readingReducer.singleReadingPassage,
    services: state.serviceReducer.services,
  }))
  // console.log(
  //   `🦄 ~ file: CreatePassage.js ~ line 31 ~ CreatePassage ~ singlePassage`,
  //   singlePassage
  // )
  useEffect(() => {
    if (edit) {
      const data = { ...listValue }
      data.title = singlePassage.readingPessageTopicName
      data.description = singlePassage.readingPessageDescription
      data.totalQuestion = singlePassage.totalQuestion
      setListValue(data)
    }
  }, [singlePassage])
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Reading Passage",
      link: `/reading-Passage/${serviceId}`,
    },
    {
      title: `${singleReadingSet?.setName || "Reading Set"}`,
      link: `/service/${serviceId}/reading/${setId}`,
    },
    {
      title: "Create Passage",
      link: "#",
    },
  ]

  useEffect(() => {
    dispatch(getIELTSSingleReadingSet(authToken, setId))
    dispatch(getService(authToken))
    if (edit) {
      dispatch(getIELTSReadingSinglePassage(authToken, id))
    }

    return () => {
      dispatch(getIELTSReadingSinglePassageSuccess({}))
    }
  }, [])

  const onSubmit = values => {
    const obj = {
      readingPessageTopicName: values.title,
      readingPessageDescription: values.description,
      totalQuestion: values.totalQuestion,
    }
    if (edit) {
      dispatch(
        addIELTSReadingPassage(obj, history, authToken, setId, serviceId, id)
      )
    } else {
      dispatch(
        addIELTSReadingPassage(obj, history, authToken, setId, serviceId)
      )
    }
  }
  const [adding2, setAdding] = useState(false)
  const inputRef = useRef()
  const [imageUrl, setImageUrl] = useState("")

  const handleUploadImage = async e => {
    const { files } = e.target
    if (files?.length > 0) {
      setAdding(true)
      const formData = new FormData()
      formData.append("photo", files[0])
      try {
        const data = await postData("/image-upload", formData, authToken)
        if (data) {
          setAdding(false)
          toaster("success", "Upload image success")
          setImageUrl(data?.photo)
        }
        inputRef.current.value = ""
      } catch (err) {
        setAdding(false)
        toaster("error", err?.response?.data?.message || "Upload image failed")
        inputRef.current.value = ""
      }
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Edit Set" : "Create Passage"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between mb-3">
              <h5>{edit ? "Edit" : "Create"} Passage</h5>
              {/* <div>
                <button
                  className="btn btn-primary"
                  onClick={() => inputRef.current?.click()}
                  disabled={adding2}
                >
                  {adding2 ? "Uploading..." : "Upload Image"}
                </button>
                <input
                  type="file"
                  ref={inputRef}
                  onChange={handleUploadImage}
                  hidden
                />
                {imageUrl && (
                  <button
                    className="btn btn-warning ms-3"
                    onClick={() => {
                      navigator.clipboard.writeText(imageUrl)
                    }}
                  >
                    <i
                      className="bx bx-copy "
                      title="Copy image url"
                      style={{ cursor: "pointer" }}
                    ></i>
                    Copy Link
                  </button>
                )}
              </div> */}
            </div>
            {loading && edit ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <Formik
                initialValues={listValue || initialValues}
                validationSchema={createPassageSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors }) => (
                  <Form>
                    {console.log(errors)}
                    <Row className="mb-3">
                      <Col md="3">Paragraph</Col>
                      <Col md="9">
                        <Field
                          name="title"
                          type="text"
                          component={CustomInput}
                          placeholder="Type paragraph title"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Paragraph</Col>
                      <Col md="9">
                        <FieldArray
                          name="description"
                          render={arrayHelpers => (
                            <div>
                              {values?.description?.map((value, index) => (
                                <div
                                  key={index}
                                  className="mb-2 position-relative"
                                >
                                  <Field
                                    name={`description.${index}.passageInfo`}
                                    type={"textarea"}
                                    rows="4"
                                    component={CustomInput}
                                    placeholder="Type paragraph"
                                  />
                                  {index > 0 ? (
                                    <div
                                      className="position-absolute"
                                      style={{ top: "0", right: "0" }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Button
                                        className="btn-danger btn-sm"
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i
                                          className="bx bx-trash"
                                          style={{ color: "#999" }}
                                        ></i>
                                      </Button>
                                    </div>
                                  ) : null}
                                  <ErrorMessage
                                    name={`description.${index}.passageInfo`}
                                    component={TextError}
                                  />
                                </div>
                              ))}
                              <Button
                                type="button"
                                color="primary"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                                onClick={() =>
                                  arrayHelpers.push({ passageInfo: "" })
                                }
                              >
                                + Add more paragraph
                              </Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Total Question</Col>
                      <Col md="9">
                        <Field
                          name="totalQuestion"
                          placeholder="Type total question"
                          type="number"
                          component={CustomInput}
                          disabled={edit}
                        />
                      </Col>
                    </Row>

                    {/* <Row className="mb-3">
                      <Col md="3">Select</Col>
                      <Col md="9">
                        {loading ? (
                          <div className="">
                            <Loader />
                          </div>
                        ) : (
                          <>
                            <Field
                              name="permissions"
                              id="permissions"
                              component={SelectField}
                              placeholder=""
                              options={services.getDatas || []}
                              value={"_id"}
                              nameData={"serviceName"}
                              isMulti={true}
                            />
                            <ErrorMessage
                              name={`permissions`}
                              component={TextError}
                            />
                          </>
                        )}
                      </Col>
                    </Row> */}
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Button color="primary" disabled={adding} type="submit">
                          {adding
                            ? "Submitting..."
                            : edit
                            ? "Edit Passage"
                            : "Add Passage"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CreatePassage)
