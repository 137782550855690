import CardComponent from 'components/Common/CardComponent';
import Loader from 'components/Common/Loader';
import { toCapitalize } from 'helpers/Custom/toCapitalize';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { getFacilitatorListeningSet, getFacilitatorWritingSet } from 'store/Facilitator/actions';
import alartIcon from '../img/alert-circle.svg';
import './services.scss';

const FacilitatorWritingService = ({ history }) => {
  const { packageId, studentId } = useParams();
  const dispatch = useDispatch();
  const { authtoken, facilitatorWritingSet, loading } = useSelector((state) => ({
    facilitatorWritingSet: state.FacilitatorReducer.facilitatorWritingSet,
    authtoken: state.Login.token,
   
    loading: state.FacilitatorReducer.loading,
    
  }));
  console.log(`profile`, facilitatorWritingSet);

  useEffect(() => {
    dispatch(getFacilitatorWritingSet(authtoken, packageId, studentId));
  }, []);
  const [startXam, setStartXam] = useState(false);
  const [xam, setXam] = useState('');
  return (
    <React.Fragment>
     
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
            <div className="mt-5">
              <h4>Package Set</h4>
            </div>
            <div className="">
              {/* <span>Time Expire</span> */}
              {/* <span>30D : 24H : 58M : 56S</span> */}
            </div>
          </div>
          {loading ? (
            <div className="text-center  pt-5">
              <Loader />
            </div>
          ) : (
            <div className="single-package-inner-main-wrap">
              <Row>
                {(facilitatorWritingSet?.values?.getData?.getData || []).map((data, key) => (
                  <Col lg={4} md={6} key={key}>
                    <div className="card-active-deactive-content">
                      <div className="active-pending-top-right-massage">
                        <div className="course-active-btn">
                          <span className="color-success-text">Active</span>
                        </div>
                      </div>
                      <CardComponent className={'text-center py-4'}>
                        <div className="mx-auto">
                          <img
                            src={
                              require(`../img/writing.svg`)
                                .default
                            }
                            alt="examImage"
                            className="mb-3"
                          />
                        </div>
                        <div>
                          <h4>{toCapitalize(data?.setName)}</h4>
                        
                          <button
                                className="meet-button"
                              onClick={() => {
                                history.push(
                                  `/facilitator/${packageId}/result/${studentId}/writing/${data._id}`
                                )
                              }}
                            >
                             View Answer
                            </button>
                   
                        </div>
                      </CardComponent>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(FacilitatorWritingService);
