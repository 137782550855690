import InnerLayer from "Layout/InnerLayer"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import "./blog-details.scss"

const BlogDetails = ({ history }) => {
  const dataLink = useParams()
  console.log(dataLink.id)

  const dispatch = useDispatch()
  const { blogList, loading, authToken } = useSelector(state => ({
    loading: state.BlogReducer.loading,
    blogList: state.BlogReducer.blogList,
    authToken: state.Login.token,
  }))
  console.log(blogList)

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title=""
        mainTitle={"Dashboard"}
        subTitle={"Blog details"}
        wrapperClass="py-3 blog-details-wrap"
        link={"/dashboard"}
      >
        <div className="content-new-body-main-content-items">
          <Row>
            <Col lg={"8"}>
              <div className="dashboard--new-main-content-area-wrap  blog-details-page">
                <img src={`https://picsum.photos/id/23/2500/1667`} alt="" />
                <div className="blog-details-content-body-area-wrap">
                  <h6>Published date - </h6>

                  <div className="post-author-profile-info-area">
                    <div className="profile-info-left"></div>
                    <div className="profile-info-right">
                      <h4>hdfjds</h4>
                      <p>gvsbdfhg</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={"4"}>
              <aside>
                <div className="dashboard-right-sidebar-wrap single-post-details-page">
                  <div className="blog-post-single-post-right-sidebar">
                    <div className="blog-details-sidebar-header-title">
                      <h3>Popular Blog</h3>
                    </div>
                    <Link to={`/blog-details`}>
                      <div className="blog-details-single-card-item">
                        <div className="blog-image">
                          <img
                            src={`https://picsum.photos/id/13/2500/1667`}
                            alt=""
                          />
                        </div>
                        <div className="blog-card-bottom-content">
                          <h5>vfgdnhf</h5>
                          <p>refgbd</p>
                          <hr />
                          <div className="post-author-single-post-info">
                            <div className="post-author-part-left">
                              <img
                                src={`https://picsum.photos/id/20/100/100`}
                                alt=""
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "25px",
                                }}
                              />
                              <span>erthyjhgrfeds</span>
                            </div>
                            <div className="post-author-part-right">
                              <span>dfaewd</span>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <hr />
                    </Link>{" "}
                    <Link to={`/blog-details`}>
                      <div className="blog-details-single-card-item">
                        <div className="blog-image">
                          <img
                            src={`https://picsum.photos/id/13/2500/1667`}
                            alt=""
                          />
                        </div>
                        <div className="blog-card-bottom-content">
                          <h5>vfgdnhf</h5>
                          <p>refgbd</p>
                          <hr />
                          <div className="post-author-single-post-info">
                            <div className="post-author-part-left">
                              <img
                                src={`https://picsum.photos/id/20/100/100`}
                                alt=""
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "25px",
                                }}
                              />
                              <span>erthyjhgrfeds</span>
                            </div>
                            <div className="post-author-part-right">
                              <span>dfaewd</span>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <hr />
                    </Link>
                  </div>
                </div>
              </aside>
            </Col>
          </Row>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(BlogDetails)
