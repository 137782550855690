import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import QuestionButtonHandler from "components/Common/QuestionButtonHandler"
import AudioSet from "components/Listening/AudioSet"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Col, Row, Table } from "reactstrap"
import {
  getIELTSListeningQuesStatus,
  getSingleListeningAudioSlice,
  getSingleListeningSets,
  updateIELTSListeningQuesStatus,
} from "store/actions"
export const mcqType = "mcq"
export const trueFalseQues = "trueFalse"
export const comprehensionQues = "comprehension"
export const YesNoQues = "YesNoQues"
export const HeadingMatching = "headingMatching"
export const multipleChoice = "multipleSelection"
export const fillGapsWords = "fillGapsWords"
export const fillGapsWithoutWords = "fillGaps"
export const fillGapsOne = "fillGapsOne"
export const fillGapsTwo = "fillGapsTwo"
export const fillGapsThree = "fillGapsThree"

const tableOne = "tableOne"
const tableTwo = "tableTwo"
const tableThree = "tableThree"
const formOne = "formOne"
const formTwo = "formTwo"
const formThree = "formThree"
const noteOne = "noteOne"
const noteTwo = "noteTwo"
const noteThree = "noteThree"
const sortAnswerOne = "sortAnswerOne"
const sortAnswerTwo = "sortAnswerTwo"
const sortAnswerThree = "sortAnswerThree"

const track =
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/1649152157808-audio.ogg"

const ViewQuestions = () => {
  const { serviceId, setId, sliceId } = useParams()
  const dispatch = useDispatch()
  const {
    authToken,
    listeningSet,
    loading,
    questionStatus,
    adding,
    singleSlice,
    objQuestion,
  } = useSelector(state => ({
    listeningAudio: state.listeningSet.listAudio,
    authToken: state.Login.token,
    loading: state.listeningSet.loading,
    adding: state.listeningSet.adding,
    listeningSet: state.listeningSet.listeningSet,
    listSlice: state.listeningSet.listSlice,
    singleSlice: state.listeningSet.singleSlice,
    questionStatus: state.listeningQuestion.questionStatus,
    listening: state.listeningSet,
    objQuestion: state.listeningSet.singleSlice?.objQuestion,
  }))
  console.log(`singleReadingSet`, objQuestion, singleSlice)
  const newSetId = localStorage.getItem("setId")
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Listening Set",
      link: `/listening-set/${serviceId}`,
    },
    {
      title: `${listeningSet?.setName || ""}`,
      link: `/service/${serviceId}/listening/${!!setId ? setId : newSetId}`,
    },
    {
      title: `Create Question`,
      link: "",
    },
  ]

  // console.log(readingPassage)

  useEffect(() => {
    if (setId) {
      localStorage.setItem("setId", setId)
    }
    dispatch(getSingleListeningAudioSlice(authToken, sliceId))
    dispatch(getSingleListeningSets(authToken, setId))
    // dispatch(getIELTSReadingSinglePassage(authToken, sliceId))
    dispatch(getIELTSListeningQuesStatus(authToken, sliceId))
  }, [])

  // console.log(
  //   `🦄 ~ file: ViewPassage.js ~ line 69 ~ findData ~ findData`,
  //   findData
  // )

  // console.log(printToLetter(1))
  const isDone = useMemo(() => {
    const question = singleSlice?.getData
    if (question?.totalQuestion === question?.remainingQuestion) {
      return true
    }
  }, [singleSlice])
  const findEditAble = name => {
    if (questionStatus?.[name]?.status === 1) {
      return true
    } else {
      let value = true
      for (let obj in questionStatus) {
        if (questionStatus[obj].status === 1) {
          value = false
        }
      }
      return value
    }
  }
  // console.log(`findEditAble ~ findEditAble`, findEditAble(trueFalseQues))

  const handleDone = type => {
    dispatch(updateIELTSListeningQuesStatus(authToken, sliceId, type))
  }
  const getAudioUrl = (audio) => {
    if (audio) {
      return audio?.startsWith("h") ? audio : imageBaseUrl + audio;
    }
    return track;
  };
  return (
    <InnerLayer
      isMultiple
      title={"Listening"}
      breadcrumbItems={breadcrumbItems}
    >
      <CardComponent>
        <Row>
          <Col md="10" className="offset-md-1">
            <h4 className="mb-3">{singleSlice?.getData?.audioTopicName}</h4>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <AudioSet
                  track={getAudioUrl(singleSlice?.getData?.audioUrl)}
                />
                <div className="question-list-area mt-3 border">
                  <div className="question-list-single-items">
                    <div className="d-flex justify-content-between p-3">
                      <h5>Question List</h5>
                      <span>
                        Question limit - {singleSlice?.getData?.totalQuestion}
                      </span>
                    </div>
                    <div className="question-list-table-wrap">
                      <Table>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Question Type</th>
                            <th>Question Limit</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>MCQ (Single)</td>
                            <td>{objQuestion?.mcq}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={mcqType}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${mcqType}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${mcqType}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>MCQ (Multi)</td>
                            <td>{objQuestion?.multipleSelection}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={multipleChoice}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${multipleChoice}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${multipleChoice}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>True or False</td>
                            <td>{objQuestion?.trueFalse}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={trueFalseQues}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${trueFalseQues}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${trueFalseQues}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Heading/Matching</td>
                            <td>{objQuestion?.headingMatching}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={HeadingMatching}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${HeadingMatching}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${HeadingMatching}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Yes/NO</td>
                            <td>{objQuestion?.YesNoQues}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={YesNoQues}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${YesNoQues}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${YesNoQues}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Comprehension</td>
                            <td>{objQuestion?.comprehension}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={comprehensionQues}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${comprehensionQues}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${comprehensionQues}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Fill in the Gap (without words)</td>
                            <td>{objQuestion?.fillGaps}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={fillGapsWithoutWords}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${fillGapsWithoutWords}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${fillGapsWithoutWords}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Fill in the Gap One (without words)</td>
                            <td>{objQuestion?.fillGapsOne}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={fillGapsOne}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${fillGapsOne}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${fillGapsOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>Fill in the Gap Two (without words)</td>
                            <td>{objQuestion?.fillGapsTwo}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={fillGapsTwo}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${fillGapsTwo}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${fillGapsTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Fill in the Gap Three (without words)</td>
                            <td>{objQuestion?.fillGapsThree}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={fillGapsThree}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${fillGapsThree}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${fillGapsThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>Fill in the Gap (with words)</td>
                            <td>{objQuestion?.fillGapsWords}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={fillGapsWords}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${fillGapsWords}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${fillGapsWords}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>12</td>
                            <td>Fill in the Gap (with words)</td>
                            <td>{objQuestion?.fillGapsWords}</td>
                            <td>
                              <Link
                                class="btn question-view-btn btn-primary rounded-pill btn-sm"
                                to="/complate-question-table"
                              >
                                Create
                              </Link>
                            </td>
                          </tr>
                          {/* new List */}
                          <tr>
                            <td>12</td>
                            <td>Table one</td>
                            <td>{objQuestion?.tableOne}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={tableOne}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${tableOne}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${tableOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>Table two</td>
                            <td>{objQuestion?.tableTwo}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={tableTwo}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${tableTwo}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${tableTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td>Table three</td>
                            <td>{objQuestion?.tableThree}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={tableThree}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${tableThree}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${tableThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          {/* form */}
                          <tr>
                            <td>15</td>
                            <td>Form one</td>
                            <td>{objQuestion?.formOne}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={formOne}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${formOne}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${formOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>Form two</td>
                            <td>{objQuestion?.formTwo}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={formTwo}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${formTwo}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${formTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>Form three</td>
                            <td>{objQuestion?.formThree}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={formThree}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${formThree}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${formThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          {/* note */}
                          <tr>
                            <td>18</td>
                            <td>Note one</td>
                            <td>{objQuestion?.noteOne}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={noteOne}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${noteOne}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${noteOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>Note two</td>
                            <td>{objQuestion?.noteTwo}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={noteTwo}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${noteTwo}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${noteTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Note three</td>
                            <td>{objQuestion?.noteThree}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={noteThree}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${noteThree}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${noteThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          {/* short answer */}
                          <tr>
                            <td>21</td>
                            <td>Short answer One</td>
                            <td>{objQuestion?.sortAnswerOne}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={sortAnswerOne}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${sortAnswerOne}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${sortAnswerOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>22</td>
                            <td>Short answer two</td>
                            <td>{objQuestion?.sortAnswerTwo}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={sortAnswerTwo}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${sortAnswerTwo}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${sortAnswerTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>23</td>
                            <td>Short answer three</td>
                            <td>{objQuestion?.sortAnswerThree}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={sortAnswerThree}
                                handleDone={handleDone}
                                createLink={`/listening-exam/${sliceId}/${sortAnswerThree}`}
                                previewLink={`/listening-exam/${sliceId}/preview/${sortAnswerThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                objQuestion={objQuestion}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </CardComponent>
    </InnerLayer>
  )
}

export default ViewQuestions
