import { patchData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { updatePasswordFail, updatePasswordSuccess } from "./actions"
import { UPDATE_PASSWORD } from "./actionTypes"

function* workerUpdatePass({
  payload: {
    values,
    options: { history, token },
  },
}) {
  try {
    const url = "/teacher/verify-account"
    const response = yield call(patchData, url, values, token)
    if (response.status == "success") {
      toaster("success", response?.message)
      history.push("/login")
    } else {
      toaster("error", response?.message)
    }
    yield put(updatePasswordSuccess(response.message))
  } catch (error) {
    const message = err?.response?.data?.message
    toaster("error", message)
    yield put(updatePasswordFail(message))
    // yield put(resetPasswordFail(error))
  }
}

function* updatePasswordSaga() {
  yield takeEvery(UPDATE_PASSWORD, workerUpdatePass)
}

export default updatePasswordSaga
