import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import CustomTable from "Layout/CustomTable"
import NoTableData from "Layout/NoTableData"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Row } from "reactstrap"
import { getIssueList } from "store/Chat/actions"
import Select from "react-select"
const statusData = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Pending",
    value: "true",
  },
  {
    name: "Solved",
    value: "false",
  },
]
const TokenList = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState("")
  const [studentName, setStudentName] = useState("")
  const [supporterEmail, setSupporterEmail] = useState("")
  const [problem, setProblem] = useState("")
  const { authtoken, issueList, issueListLoading } = useSelector(state => ({
    authtoken: state.Login.token,
    issueList: state.ChatReducer.issueList,
    issueListLoading: state.ChatReducer.issueListLoading,
  }))

  useEffect(() => {
    dispatch(getIssueList(authtoken,0, status))
  }, [status])
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleSubmit = () => {}
  const handleTokenData = data => {
    setStudentName(data?.userName)
    setSupporterEmail(data?.senderAdmin)
    setProblem(data?.problem)
    setShow(true)
    console.log("data", data)
  }
  const handleStatus = data => {
    console.log("daata", data)
    if (data?.value) {
      setStatus(data?.value)
    } else {
      setStatus("")
    }
  }

  return (
    <div className="page-content">
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton style={{ padding: "18px 32px" }}>
          <h4 style={{ marginBottom: "0", fontSize: "22px" }}>Issue details</h4>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{ padding: "0px 20px 20px 20px" }}
            className="view-token-details-modal"
          >
            <div className="mb-3">
              <h6>Student name: {studentName} </h6>
              <p>
                Student <b>Email: {studentName}</b>
              </p>{" "}
              <p>
                Supporter <b>Email: {supporterEmail}</b>
              </p>
            </div>
            <p>{problem}</p>
            {/* <button>Solve</button> */}
          </div>
        </Modal.Body>
      </Modal>
      <Row className="mt-3">
        <CardComponent className="position-relative">
     <div style={{width: "200px", marginLeft: "auto", marginBottom:"10px"}}>
     <Select
                name="status"
                className="select-form-width"
               
                classNamePrefix="select2-selection"
                placeholder="Select Status"
                cacheOptions
                getOptionLabel={e => e.name}
                getOptionValue={e => e.value}
                isClearable
                options={statusData}
                onChange={handleStatus}
              />
     </div>
          <CustomTable
            paginationClass="paginationContainer text-right"
            tableHead={[
              "Sr",
              "Student Name",
              "Supporter Email Address",
              "Issue Date",
              "Issues",
              "Role",
              "Status",
              "Action",
            ]}
          >
            {issueListLoading ? (
              <NoTableData
                colSpan="4"
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "200px", width: `100%` }}
              >
                <Loader />
              </NoTableData>
            ) : issueList?.length > 0 ? (
              issueList?.map((data, idx) => (
                <React.Fragment key={idx}>
                  <tr>
                    <td scope="row">{idx + 1}</td>
                    <td>{data?.userName}</td>
                    <td>{data?.senderAdmin}</td>
                    <td>{data?.createdAt?.slice(0, 10)}</td>
                    <td>{data?.problem?.slice(0, 15)}</td>
                    <td>{data?.user ? "Registered" : "Not Registered"}</td>
                    <td>
                      {data?.issue_status === false ? (
                        <td>
                          <span
                            style={{
                              background: "rgba(34, 217, 74, 0.12)",
                              display: "inline-block",
                              color: "rgba(34, 217, 74, 1)",
                              padding: "4px 12px",
                              borderRadius: "5px",
                            }}
                          >
                            Solved
                          </span>
                        </td>
                      ) : (
                        <td>
                          {" "}
                          <span
                            style={{
                              background: "rgba(244, 44, 44, 0.12)",
                              display: "inline-block",
                              color: "rgba(244, 44, 44, 1)",
                              padding: "4px 12px",
                              borderRadius: "5px",
                            }}
                          >
                            Pending
                          </span>{" "}
                        </td>
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleTokenData(data)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <NoTableData
                colSpan="4"
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "200px", width: `100%` }}
              >
                <span>No data here</span>
              </NoTableData>
            )}
          </CustomTable>
        </CardComponent>
      </Row>
    </div>
  )
}

export default TokenList
