import Editor from "components/Common/QuillEditor"
import InnerLayer from "Layout/InnerLayer"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { Col, Label, Row } from "reactstrap"
import "./settings.scss"
const Settings = ({ edit, history }) => {
  const breadcrumbItems = []
  const [tabsControl, setTabsControl] = useState({})
  return (
    <InnerLayer
      isMultiple
      title={"Website Information"}
      breadcrumbItems={breadcrumbItems}
    >
      <div className="website-information-write-main-area">
        <Tabs defaultIndex={1} onSelect={index => console.log(index)}>
          <Row>
            <TabList className="col-md-3">
              <Tab>Terms and conditions</Tab>
              <Tab>Privacy Policy</Tab>
              <Tab>Refund Policy</Tab>
              <Tab>FAQ</Tab>
            </TabList>
            <Col md="9">
              <TabPanel>
                <div>
                  <h2 className="mb-4">Terms and conditions</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>Terms and conditions</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <h2 className="mb-4">Privacy Policy</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>Privecy Policy</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel>
                <div>
                  <h2 className="mb-4">Refund Policy</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>Refund Policy</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel>
                <div>
                  <h2 className="mb-4">FAQ</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>FAQ</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>
            </Col>
          </Row>
        </Tabs>
      </div>
    </InnerLayer>
  )
}

export default withRouter(Settings)
