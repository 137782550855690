import PackageCard from "components/Package/PackageCard"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row } from "reactstrap"

const service = [
  {
    serviceName: "Reading",
  },
  {
    serviceName: "Listening",
  },
  {
    serviceName: "Writing",
  },
  {
    serviceName: "Speaking",
  },
]

const ReviewYourAnswer = ({ location }) => {
  //   console.log(`🦄 ~ file: Index.js ~ line 11 ~ ListSet ~ param`, param)
  const dispatch = useDispatch()
  // const useSelector = useSelector();
  const { allAnswer, authToken, loading } = useSelector(state => ({
    allAnswer: state.review.allAnswer,
    authToken: state.Login.token,
    loading: state.review.loading,
  }))

  console.log("Review", allAnswer, loading)

  useEffect(() => {
    const type = location.pathname?.split("/")?.pop()

    // dispatch(getAllAnswer(authToken))
  }, [])
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Review Answers"
        mainTitle={"Dashboard"}
        subTitle={"Review Answers"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Row>
          <PackageCard userService={service} />
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(ReviewYourAnswer)
