import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { imageBaseUrl } from "helpers/api_helper"
import InnerLayer from "Layout/InnerLayer"
import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { getBlogPost } from "store/actions"
import logo from "../../assets/images/Icon/Frame.svg"
import "./teacher.scss"
const BlogList = ({ history }) => {
  const dispatch = useDispatch()
  const { blogList, loading, authToken } = useSelector(state => ({
    loading: state.BlogReducer.loading,
    blogList: state.BlogReducer.blogList,
    authToken: state.Login.token,
  }))
  useEffect(() => {
    dispatch(getBlogPost(authToken))
  }, [])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return logo
  }

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Blog List"
        mainTitle={"Dashboard"}
        subTitle={"Blog List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Blog List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Link to="/create-blog">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create Blog
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        {/* list */}

        <Row>
          {/* {JSON.stringify(teachers)} */}
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : blogList?.length > 0 ? (
            blogList?.map((data, idx) => (
              <Col md={4} xl={3} key={idx} style={{ cursor: "pointer" }}>
                <Link
                  to={`/blog-details/${data._id}`}
                  className="blog-details-single-card-item"
                >
                  <div className="blog-image">
                    <img
                      src={`${getPhotoUrl(data?.photo)}`}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="blog-card-bottom-content">
                    <h5>{data?.postTitle}</h5>
                    <p>{data?.postDescription[0].slice(0, 30)}</p>
                    <hr />
                    <div className="post-author-single-post-info">
                      <div className="post-author-part-left">
                        <img
                          src={`${getPhotoUrl(data?.postBy?.photo)}`}
                          alt=""
                          // style={{ height: "50px", width: "50px" }}
                        />
                        <span>{data?.postBy?.authorName}</span>
                      </div>
                      <div className="post-author-part-right">
                        <span>
                          {" "}
                          {moment(
                            data?.postBy?.updatedAt?.split("T")?.[0]
                          )?.format("DD MMM YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>{" "}
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h5>No Blog Available</h5>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(BlogList)
