import Loader from "components/Common/Loader"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap"
import { getPackages } from "store/package/actions"
import { removePackage } from "store/package/actions"
import CardComponent from "../../components/Common/CardComponent"

const PackageList = () => {
  const [keyword, setKeyword] = useState("")
  const dispatch = useDispatch()
  const [deleteItem, setDeleteItem] = useState({
    modal: false,
    data: {},
  })
  const history = useHistory()
  const { authtoken, message, values, loading, packageData } = useSelector(
    state => ({
      authtoken: state.Login.token,
      loading: state.packageReducer.loading,
      message: state.packageReducer.message,
      error: state.packageReducer.error,
      values: state.packageReducer.values,
      packageData: state.packageReducer.allPackage?.AllPackages,
    })
  )
  console.log(
    `🦄 ~ file: PackageList.js ~ line 30 ~ PackageList ~ package`,
    packageData
  )

  // useEffect(() => {
  //   if (message) {
  //     toast.success(message)
  //   }
  // }, [message])

  useEffect(() => {
    dispatch(getPackages(authtoken))
  }, [])

  const handleSearchChange = e => {
    e.preventDefault()
    setKeyword(e.target.value.toLowerCase())
  }

  const Searched = keyword => c => c.packageName.toLowerCase().includes(keyword)
  const handleDelete = data => {
    setDeleteItem({
      modal: true,
      data,
    })
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return '';
  };
  return (
    <React.Fragment>
      {deleteItem.modal ? (
        <SweetAlert
          warning
          showCancel
          showCloseButton
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          title="Are you sure?"
          onConfirm={() => {
            dispatch(removePackage(authtoken, deleteItem.data._id))
            setDeleteItem({
              modal: false,
              data: {},
            })
          }}
          onCancel={() =>
            setDeleteItem({
              modal: false,
              data: {},
            })
          }
        >
          You wont revert back
        </SweetAlert>
      ) : null}
      <InnerLayer
        isBreadCrumb={true}
        title="Package List"
        mainTitle={"Dashboard"}
        subTitle={"Package List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Package List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    value={keyword}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Link to="/create-package">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create Package
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        <Row>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            packageData?.map((data, idx) => (
              <Col
                mg={6}
                xl={4}
                key={idx}
                style={{ height: "100%" }}
                onClick={() => history.push(`/package/${data._id}`)}
              >
                <Card className="position-relative">
                  <span
                    className="position-absolute text-danger"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      padding: "5px 7px",
                      lineHeight: "5px",
                      top: "2%",
                      right: "2%",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      handleDelete(data)
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                  <CardImg
                    top
                    className="img-fluid"
                    src={getPhotoUrl(data?.photo)}
                   
                    alt="xamPro"
                  />
                  <CardBody>
                    <CardTitle className="mt-0">{data?.packageName}</CardTitle>
                    <CardText>{data?.description}</CardText>
                    <p>Duration - 7 Days</p>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        onClick={e => {
                          e.stopPropagation()
                          history.push(`/edit-package/${data._id}`)
                        }}
                        className="btn btn-primary"
                      >
                        Edit
                      </span>
                      <h5 className="mb-0">{data?.price} Taka</h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
          {/* {!values?.length && (
              <div className="d-flex justify-content-center text-center mb-4">
                <div>
                  <img src={noImage} style={{ maxWidth: "400px" }} />
                  <h4>You have no package</h4>
                </div>
              </div>
            )} */}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default PackageList
