import { getData, patchData, postData } from "helpers/backend_helper"
import { toast } from "react-toastify"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addFacilitatorReviewFail,
  addFacilitatorReviewSuccess,
  addFacilitatorSlotFail,
  addFacilitatorSlotSuccess,
  getFacilitatorListeningSetFail,
  getFacilitatorListeningSetSuccess,
  getFacilitatorReview,
  getFacilitatorReviewFail,
  getFacilitatorReviewSuccess,
  getFacilitatorSetFail,
  getFacilitatorSetSuccess,
  getFacilitatorSingleDay,
  getFacilitatorSingleDayFail,
  getFacilitatorSingleDaySuccess,
  getFacilitatorSinglePackageFail,
  getFacilitatorSinglePackageSuccess,
  getFacilitatorSlotFail,
  getFacilitatorSlotSuccess,
  getFacilitatorWritingSetFail,
  getFacilitatorWritingSetSuccess,
  getReviewAttendanceFail,
  getReviewAttendanceSuccess,
  getSingleFacilitatorSlotFail,
  getSingleFacilitatorSlotSuccess,
} from "./actions"

//for main
import {
  ADD_FACILITATOR_REVIEW,
  ADD_FACILITATOR_SLOT,
  GET_FACILITATOR_LISTENING_SET,
  GET_FACILITATOR_REVIEW,
  GET_FACILITATOR_SET,
  GET_FACILITATOR_SINGLE_DAY,
  GET_FACILITATOR_SINGLE_PACKAGE,
  GET_FACILITATOR_SLOT,
  GET_FACILITATOR_WRITING_SET,
  GET_REVIEW_ATTENDANCE,
  GET_SINGLE_FACILITATOR_SLOT,
} from "./actionTypes"

function* workerFacilitatorSlot({
  payload: {
    token,
    options: { teacherId, startDate, endDate, role },
  },
}) {
  try {
    const url =
      role === "admin"
        ? `/admin/get-facliitator-time-slot/${teacherId}?startDate=${startDate}&endDate=${endDate}`
        : `/facilitator/get-time-slot/?startDate=${startDate}&endDate=${endDate}`
    const response = yield call(getData, url, token)
    yield put(getFacilitatorSlotSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get all slice fail"
    toaster("error", message)
    yield put(getFacilitatorSlotFail(message))
  }
}

function* workerGetSingleFacilitatorSlot({
  payload: {
    token,
    options: { teacherId, date },
  },
}) {
  try {
    const url = `/admin/get-facilator-a-day-time-slot/${teacherId}/${date}`
    const response = yield call(getData, url, token)
    yield put(getSingleFacilitatorSlotSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getSingleFacilitatorSlotFail(message))
  }
}
function* workerAddFacilitatorSlot({
  payload: {
    values,
    options: { history, token, teacherId },
  },
}) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, values)
  try {
    const url = `/admin/create-facilitator-time-slot/${teacherId}`
    const response = yield call(postData, url, values, token)
    // toaster("success", `Slot Added Successfully`)
    yield put(addFacilitatorSlotSuccess(response?.message))
    toaster("success", `Slot Added Successfully`)

   
   
   
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(addFacilitatorSlotFail(message))
  }
}
function* onGetFacilitatorSingleDay({
  payload: {
    token,
    options: { teacherId, date: searchDate, role },
  },
}) {
  try {
    const url =
      role === "admin"
        ? `/admin/get-facilator-single-day/${teacherId}?searchDate=${searchDate}`
        : `/facilitator/get-single-day?searchDate=${searchDate}`
    const response = yield call(getData, url, token)
    yield put(getFacilitatorSingleDaySuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get all slice fail"
    toaster("error", message)
    yield put(getFacilitatorSingleDayFail(message))
  }
}

function* workerGetFacilitatorSinglePackage({ payload: { token, packageId } }) {
  try {
    const url = `/package/getsinglepackage/${packageId}`
    const response = yield call(getData, url, token)
    yield put(getFacilitatorSinglePackageSuccess(response))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getFacilitatorSinglePackageFail(message))
  }
}
function* workerGetFacilitatorSet({
  payload: { token, packageId, studentId },
}) {
  try {
    const url = `package/facilitator/get-reading-sets/${packageId}/${studentId}`
    const response = yield call(getData, url, token)
    yield put(getFacilitatorSetSuccess(response))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getFacilitatorSetFail(message))
  }
}
function* workerGetFacilitatorListeningSet({
  payload: { token, packageId, studentId },
}) {
  try {
    const url = `package/facilitator/get-listening-set-under-listening-service/${packageId}/${studentId}`
    const response = yield call(getData, url, token)
    console.log("response", response)
    yield put(getFacilitatorListeningSetSuccess(response))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getFacilitatorListeningSetFail(message))
  }
}
function* workerGetFacilitatorWritingSet({
  payload: { token, packageId, studentId },
}) {
  try {
    const url = `package/facilitator/get-writting-set-under-writting-service/${packageId}/${studentId}`
    const response = yield call(getData, url, token)
    console.log("response", response)
    yield put(getFacilitatorWritingSetSuccess(response))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getFacilitatorWritingSetFail(message))
  }
}
function* workerGetFacilitatorReview({
  payload: { token, setId, studentId, type },
}) {
  console.log('type54', type);
  try {
    const url =
      type === "reading"
        ? `get-facilitator-reading-review/${studentId}/${setId}`
        : type === "listening"
        ? `get-facilitator-listening-review/${studentId}/${setId}`
        : `get-facilitator-writing-review/${studentId}/${setId}`

    const response = yield call(getData, url, token)
    yield put(getFacilitatorReviewSuccess(response))
    // yield put(getFacilitatorReview(token, setId, studentId, type))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getFacilitatorReviewFail(message))
  }
}
function* workerAddFacilitatorReview({
  payload: {
    values,
    options: { history, token, type },
  },
}) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, values)
  try {
    const url =
      type === "reading"
        ? `/facilitator-reading-review-submit`
        : type === "listening"
        ? `facilitator-listening-review-submit`
        : `facilitator-writing-review-submit`

    const response = yield call(postData, url, values, token)
    yield put(addFacilitatorReviewSuccess(response?.message))
  
    toaster("success", `Review Added Successfully`)
    window.location.reload()

    // history.push(`/facilitator/${teacherId}`)
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(addFacilitatorReviewFail(message))
  }
}
function* workerGetReviewAttendance({
  payload: { token, value, examId, options },
}) {
  try {
    const url = `facilitator/update-attandance-student/${examId}`
    const response = yield call(patchData, url, value, token)
    console.log("response", response)
    yield put(getReviewAttendanceSuccess(response))
    toaster("success", `Attendance Given Successfully`)
    yield put(getFacilitatorSingleDay(token, options))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getReviewAttendanceFail(message))
  }
}
function* facilitatorSaga() {
  yield takeEvery(GET_FACILITATOR_SLOT, workerFacilitatorSlot)
  yield takeEvery(GET_SINGLE_FACILITATOR_SLOT, workerGetSingleFacilitatorSlot)
  yield takeEvery(ADD_FACILITATOR_SLOT, workerAddFacilitatorSlot)
  yield takeEvery(GET_FACILITATOR_SINGLE_DAY, onGetFacilitatorSingleDay)
  yield takeEvery(
    GET_FACILITATOR_SINGLE_PACKAGE,
    workerGetFacilitatorSinglePackage
  )
  yield takeEvery(GET_FACILITATOR_SET, workerGetFacilitatorSet)
  yield takeEvery(GET_FACILITATOR_REVIEW, workerGetFacilitatorReview)
  yield takeEvery(ADD_FACILITATOR_REVIEW, workerAddFacilitatorReview)
  yield takeEvery(
    GET_FACILITATOR_LISTENING_SET,
    workerGetFacilitatorListeningSet
  )
  yield takeEvery(GET_FACILITATOR_WRITING_SET, workerGetFacilitatorWritingSet)
  yield takeEvery(GET_REVIEW_ATTENDANCE, workerGetReviewAttendance)
}
export default facilitatorSaga
