import { getData, patchData, postData } from "helpers/backend_helper"
import { toast } from "react-toastify"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"

//for main
import { ADD_FAQ_INFO, ADD_FAQ_TYPE, GET_FAQ_INFO, GET_FAQ_TYPE, GET_SINGLE_FAQ_INFO, GET_SINGLE_FAQ_TYPE, UPDATE_FAQ, UPDATE_FAQ_TYPE } from "./actionTypes"
import {
  addFaqInfoFail,
  addFaqInfoSuccess,
  addFaqTypeFail,
  addFaqTypeSuccess,
  getFAQInfoFail,
  getFAQInfoSuccess,
  getFAQtypeFail,
  getFAQtypeSuccess,
  getSingleFAQInfoFail,
  getSingleFAQInfoSuccess,
  getSingleFAQTypeFail,
  getSingleFAQTypeSuccess,
  updateFaqFail,
  updateFaqSuccess,
  updateFaqTypeFail,
  updateFaqTypeSuccess,
} from "./actions"

function* getFaqTypeList({ payload: { token } }) {
  try {
    const url = "/faq/get-all-faq-type"
    const response = yield call(getData, url, token)
    yield put(getFAQtypeSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getFAQtypeFail(message))
  }
}
function* getFaqInfoList({ payload: { token } }) {
  try {
    const url = "/faq/get-all-faq"
    const response = yield call(getData, url, token)
    yield put(getFAQInfoSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getFAQInfoFail(message))
  }
}
function* onGetSingleFaq({ payload: { token, id} }) {
  try {
    const url = `/faq/admin/get-single-faq/${id}`
    const response = yield call(getData, url, token)
    yield put(getSingleFAQInfoSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getSingleFAQInfoFail(message))
  }
}

function* onGetSingleFaqType({ payload: { token, id} }) {
  try {
    const url = `/faq/get-single-faq-type/${id}`
    const response = yield call(getData, url, token)
    yield put(getSingleFAQTypeSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getSingleFAQTypeFail(message))
  }
}
function* workerAddFAQType({ payload: { values, history, token } }) {
  console.log(`🦄 ~ file: saga.js ~ line 65 ~ values`, values)
  try {
    const url = `/faq/create-faq-type`
    const response = yield call(postData, url, values, token)
    // toaster("success", `Slot Added Successfully`)
    yield put(addFaqTypeSuccess(response?.message))
    toaster("success", `FAQ type added Successfully`)
    history.push("/faqType")
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(addFaqTypeFail(message))
  }
}
function* workerAddFAQInfo({ payload: { values, history, token } }) {
 
  try {
    const url = `faq/create-faq`
    const response = yield call(postData, url, values, token)
    // toaster("success", `Slot Added Successfully`)
    yield put(addFaqInfoSuccess(response?.message))
    toaster("success", `FAQ added Successfully`)
    history.push("/faqList")
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(addFaqInfoFail(message))
  }
}
function* workerUpdateFaq({ payload: { values, history, token, id } }) {
 
  try {
    const url = `faq/update-faq/${id}`
    const response = yield call(patchData, url, values, token)
    yield put(updateFaqSuccess(response?.message))
    toaster("success", `FAQ Updated Successfully`)
    history.push("/faqList")
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(updateFaqFail(message))
  }
}

function* workerUpdateFaqType({ payload: { values, history, token, id } }) {
 
  try {
    const url = `faq/update-faq-type/${id}`
    const response = yield call(patchData, url, values, token)
    yield put(updateFaqTypeSuccess(response?.message))
    toaster("success", `FAQ type Updated Successfully`)
    
    history.push("/faqType")
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(updateFaqTypeFail(message))
  }
}
function* FaqSaga() {
  yield takeEvery(ADD_FAQ_TYPE, workerAddFAQType)
  yield takeEvery(GET_FAQ_TYPE, getFaqTypeList)
  yield takeEvery(ADD_FAQ_INFO, workerAddFAQInfo)
  yield takeEvery(GET_FAQ_INFO, getFaqInfoList)
  yield takeEvery(GET_SINGLE_FAQ_INFO, onGetSingleFaq)
  yield takeEvery(UPDATE_FAQ, workerUpdateFaq)
  yield takeEvery(UPDATE_FAQ_TYPE, workerUpdateFaqType)
  yield takeEvery(GET_SINGLE_FAQ_TYPE, onGetSingleFaqType)
}
export default FaqSaga
