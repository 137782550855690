import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import { mcqSchema } from "components/Schemas/QuestionSchemas"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSReadingQuesMCQ,
  getIELTSReadingQuesMCQ,
  getIELTSReadingQuesNum,
} from "store/actions"

const initialValues = {
  q: "",
  answer: "",
  opt: ["", ""],
  explanation: "",
}

const MCQ = ({ edit, history, location }) => {
  // console.log(
  //   `🦄 ~ file: MCQ.js ~ line 22 ~ MCQ ~ location`,
  //   location.pathname.split("/").pop()
  // )
  const { serviceId, setId, passageId, questionId, id } = useParams()
  const [isAddMore, setIsAddMore] = useState(false)
  // const [questionNumber, setQuestionNumber] = useState(1)
  console.log(`🦄 ~ file: MCQ.js ~ line 24 ~ MCQ ~ passageID`, passageId)
  const [listValue, setListValue] = useState(null)
  const dispatch = useDispatch()
  const { authToken, loading, adding, singleQuestion, questionNumber } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.QuesReducer.loading,
      adding: state.QuesReducer.adding,
      services: state.serviceReducer.services,
      singleQuestion: state.QuesReducer.singleQuestion,
      questionNumber: state.QuesReducer.questionNumber,
    }))
  // console.log(
  //   `🦄 ~ file: MCQ.js ~ line 33 ~ MCQ ~ singleQuestion`,
  //   singleQuestion,
  //   loading,
  //   questionNumber
  // )

  useEffect(() => {
    if (edit && !!singleQuestion) {
      const value = { ...listValue }
      value.q = singleQuestion?.q
      value.opt = singleQuestion?.opt
      value.answer = singleQuestion?.answer?.[0]
      value.explanation = singleQuestion?.explanation
      setListValue(value)
      // setQuestionNumber(singleQuestion?.questionNumber)
    }
  }, [singleQuestion])

  const onSubmit = (values, { resetForm }) => {
    const { answer, ...rest } = values
    const type = location.pathname.split("/").pop()
    const obj = {
      ...rest,
      answer: [answer],
      readingPessage: passageId,
      questionSerial: questionNumber,
      questype: type,
    }
    const options = {
      isMore: isAddMore,
      resetForm,
      setIsAddMore,
      questionId,
      type,
      passageId,
    }
    if (edit) {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, options))
    } else {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, options))
    }
    console.log(`🦄 ~ file: MCQ.js ~ line 85 ~ onSubmit ~ obj`, obj)
  }

  useEffect(() => {
    const type = location.pathname.split("/").pop()
    if (edit) {
      const data = {
        type,
        objectId: id,
        questionId,
      }
      dispatch(getIELTSReadingQuesMCQ(authToken, passageId, data))
    } else {
      dispatch(getIELTSReadingQuesNum(authToken, passageId, type))
    }
  }, [])

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={false}
        title={`${edit ? "Update" : "Create"} MCQ Question`}
      >
        <Row className="mt-3">
          <Col md="10" className="offset-md-1">
            <CardComponent className={"mb-0"}>
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-left-arrow-alt me-2"
                  onClick={() => history.goBack()}
                  style={{ fontSize: "18px" }}
                ></i>
                <h6 className="mb-0">
                  {edit ? "Update" : "Create"} single choose (MCQ)
                </h6>
              </div>
            </CardComponent>
            <CardComponent style={{ marginTop: "5px" }}>
              <Row>
                <Col md="1">
                  <div className="question_number d-flex justify-content-center align-items-start">
                    {questionNumber}
                  </div>
                </Col>
                <Col md="11">
                  <h5>Type 1no. question</h5>
                  <br />
                  {loading && edit ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <Formik
                      initialValues={listValue || initialValues}
                      validationSchema={mcqSchema}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                    >
                      {({ values }) => (
                        <Form>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                label="Question Title"
                                name="q"
                                type="text"
                                id={"question"}
                                component={CustomInput}
                                placeholder="Type paragraph title"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <FieldArray
                              name="opt"
                              render={arrayHelpers => (
                                <Row>
                                  {values?.opt?.map((value, index) => (
                                    <Col md="6" key={index}>
                                      <div className="mb-2 position-relative">
                                        <Field
                                          name={`opt.${index}`}
                                          type={"text"}
                                          id={index}
                                          label={`Option ${index + 1}`}
                                          component={CustomInput}
                                          placeholder="Type option"
                                        />
                                        {index > 0 ? (
                                          <div
                                            className="position-absolute"
                                            style={{ top: "0", right: "0" }}
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <Button
                                              className="btn-danger btn-sm "
                                              style={{
                                                background: "transparent",
                                                border: "none",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <i
                                                className="bx bx-trash"
                                                style={{ color: "#999" }}
                                              ></i>
                                            </Button>
                                          </div>
                                        ) : null}
                                        <ErrorMessage
                                          name={`opt.${index}`}
                                          component={TextError}
                                        />
                                      </div>
                                    </Col>
                                  ))}
                                  <Col md="12">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="d-inline-block"
                                      style={{
                                        background: "#E5F0FF",
                                        color: "#485ec4",
                                        border: "none",
                                        width: "initial",
                                      }}
                                      onClick={() => arrayHelpers.push("")}
                                    >
                                      Add more option
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            />
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="answer"
                                id={"answer"}
                                label="Right answer"
                                placeholder="Type right answer"
                                type="text"
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="explanation"
                                id={"Explanation"}
                                label="Explanation"
                                placeholder="Type explanation"
                                type="textarea"
                                rows={"5"}
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12" className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="me-2"
                                disabled={adding}
                                type="submit"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                              >
                                {adding
                                  ? "Submitting..."
                                  : edit
                                  ? "Update"
                                  : "Save"}
                              </Button>
                              {!edit ? (
                                <Button
                                  color="primary"
                                  disabled={isAddMore}
                                  // onClick={() => }
                                  type="submit"
                                >
                                  {isAddMore
                                    ? "Submitting..."
                                    : "Create more question"}
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Col>
              </Row>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(MCQ)
