import { AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import Editor from "components/Common/QuillEditor"
import TextError from "components/InputComponent/TextError"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import InnerLayer from "Layout/InnerLayer"
import Upload from "pages/Package/Upload"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"
import {
  addWritingSetQuestion,
  getSingleWritingSets,
  getSingleWritingSetsSuccess,
} from "store/actions"
const CreateAcademicQues = ({ edit, history }) => {
  const { serviceId, setId, questionId } = useParams()
  const [fileError, setFileError] = useState("")
  const [value, setValue] = useState("")
  const [selectedFiles, setSelectedFiles] = useState([])
  console.log(`selectedFiles`, selectedFiles)
  const dispatch = useDispatch()
  const { authToken, loading, writingSet, adding, number, question } =
    useSelector(state => ({
      authToken: state.Login.token,
      adding: state.listeningSet.adding,
      loading: state.writingSet.loading,
      writingSet: state.writingSet.writingSet,
      number: state.writingSet.questionNumber,
      adding: state.writingSet.adding,
      question: state.writingSet.writingSet?.writtingQuestion?.questions,
    }))
  const findQuestion = edit
    ? question?.find(data => data?._id === questionId)
    : {}
  // console.log(`writingSet`, writingSet, number, findQuestion)
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Writing Set",
      link: `/writing-set/${serviceId}`,
    },
    {
      title: "Create Question",
      link: "#",
    },
  ]

  useEffect(() => {
    dispatch(
      getSingleWritingSets(authToken, { serviceId, setId, isNumber: true })
    )
    return () => {
      dispatch(getSingleWritingSetsSuccess({}))
    }
  }, [])
  useEffect(() => {
    if (selectedFiles.length > 0) {
      setFileError("")
    }
  }, [selectedFiles])

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    // if (number < 2) {
    //   if (selectedFiles.length === 0 && !edit) {
    //     return setFileError("Please select a image")
    //   }
    // }
    let data = {
      ...values,
      q: value,
      writingSet: setId,
      questionNumber: number,
    }
    data.file = selectedFiles?.[0]
    console.log(`data`, data)
    const fd = new FormData()
    fd.append("q", value)
    fd.append("writtingSet", setId)
    fd.append("questionNumber", number)
    if (selectedFiles?.length && number === 1) {
      fd.append("photo", selectedFiles?.[0])
    }
    for (const obj of fd.entries()) {
      console.log(obj)
    }
    dispatch(
      addWritingSetQuestion(fd, {
        history,
        serviceId,
        setId,
        token: authToken,
        edit: false,
      })
    )
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return '';
  };
  return (
    <InnerLayer
      isMultiple
      title={"Writing Question"}
      breadcrumbItems={breadcrumbItems}
    >
      <div className="writing-create-ques-new-main-wrap">
        <div className="writing-ques-inner-content">
          <CardComponent>
            {edit && loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <AvForm onSubmit={handleSubmit}>
                <h3>Writing Task {number}</h3>
                <Row className="mb-3">
                  <Col md="12">
                    <label htmlFor="writing-ques" className="form-label">
                      Write Question
                    </label>
                    <div style={{ height: "170px" }}>
                      <Editor
                        placeholder={"Type"}
                        name="q"
                        handleChange={e => {
                          // setFieldValue("q", e)
                          setValue(e)
                        }}
                        value={value}
                        height={"130px"}
                      />
                    </div>
                    {/* <AvField
                      name="q"
                      type="textarea"
                      rows="5"
                      label="Write Question"
                      errorMessage="Type title"
                      placeholder="Type title"
                      value={findQuestion?.q || ""}
                      validate={{
                        required: { value: true },
                      }}
                    /> */}
                  </Col>
                </Row>
                {number !== 2 ? (
                  <Row className="mb-3">
                    <Col md="12">
                      <Upload
                        exceptFile="image/*"
                        uploadMessage={
                          selectedFiles.length > 0 || edit
                            ? "Drop files here or click to change."
                            : "Drop files here or click to upload."
                        }
                        setSelectedFiles={setSelectedFiles}
                      />
                      {selectedFiles?.length === 0 && fileError !== "" ? (
                        <TextError>{fileError}</TextError>
                      ) : null}
                      <Row>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles?.length ? (
                            selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          style={{ objectFit: "cover" }}
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })
                          ) : findQuestion?.image ? (
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={"photo"}
                                    src={getPhotoUrl(findQuestion?.image)}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {writingSet?.image}
                                  </Link>
                                </Col>
                              </Row>
                            </div>
                          ) : null}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                <Row>
                  <Col md="12">
                    <div className="writing-ques-action-button-area">
                      <button disabled={adding} type="submit">
                        {adding ? "Submitting..." : edit ? "Update" : "Save "}
                      </button>
                      {!edit ? (
                        <button disabled={adding} type="submit">
                          {adding
                            ? "Submitting..."
                            : edit
                            ? "Update Set"
                            : "Create more question"}{" "}
                        </button>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
          </CardComponent>
          {/* <form>
            <div className="mb-3">
              <label htmlFor="writing-ques" className="form-label">
                Write Question
              </label>
              <textarea
                className="form-control"
                id="writing-ques"
                rows="3"
                placeholder="Type title"
              ></textarea>
            </div>{" "}
            <div className="mb-3">
              <span htmlFor="" className="form-label">
                Upload Image
              </span>
              
              <div className="writing-ques-img-upload-main-wrap ">
                <label
                  htmlFor="writing-ques-img-upload"
                  className="form-label text-center"
                >
                  <img src={uploadIcon} alt="" />
                  <p>Drop files here or click to upload.</p>
                </label>
                <input
                  className="form-control"
                  id="writing-ques-img-upload"
                  type="file"
                />
              </div>
            </div>
            <div className="writing-ques-action-button-area">
              <button>Save</button>
              <button>Create more question</button>
            </div>
          </form> */}
        </div>
      </div>
    </InnerLayer>
  )
}

export default withRouter(CreateAcademicQues)
