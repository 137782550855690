import CardComponent from "components/Common/CardComponent"
import InnerLayer from "Layout/InnerLayer"
import React from "react"
import { Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import "./speaking.scss"
import SpeakingPage from "./SpeakingCreate/SpeakingPage"

const SpeakingTimeSlotCreate = () => {
  const { teacherId } = useParams()

  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Teacher List",
      link: `/teachers-list`,
    },
    {
      title: "List time slot",
      link: `/speaking/${teacherId}`,
    },
    {
      title: "Create time slot",
      link: `#`,
    },
  ]

  return (
    <div>
      <InnerLayer
        wrapperClass="py-3 users"
        title={"Date & Time"}
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Select date & Time</h4>
            </div>
          </CardComponent>{" "}
        </Row>
        <div className="empty-time-slot-main-wrap create-time-slot-wrap">
          <SpeakingPage teacherId={teacherId} />
        </div>
      </InnerLayer>
    </div>
  )
}

export default SpeakingTimeSlotCreate
