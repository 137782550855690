import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addCouponFail,
  addCouponSuccess,
  getCoupon,
  getCouponFail,
  getCouponSuccess,
  getSingleCouponFail,
  getSingleCouponSuccess,
  updateCouponFail,
  updateCouponSuccess,
} from "./actions"
// Crypto Redux States
import {
  ADD_COUPON,
  GET_COUPON,
  GET_SINGLE_COUPON,
  UPDATE_COUPON,
} from "./actionTypes"

function* onAddCoupon({ payload: { data, token, history, id } }) {
  try {
    const url = id ? `/update-coupon/${id}` : "/create-coupon"
    const response = yield call(id ? patchData : postData, url, data, token)
    if (response) {
      yield put(addCouponSuccess({}))
      toaster("success", `${id ? "Edit" : "Create"} coupon successful`)
      history.push("/coupon")
    }
  } catch (err) {
    const message =
      err?.response?.data?.message || `${id ? "Edit" : "Create"} coupon failed`
    toaster("error", message)
    yield put(addCouponFail(message))
  }
}

function* onUpdateCoupon({ payload: { token, status, id } }) {
  try {
    const url = status
      ? `coupon/visible-false/${id}`
      : `coupon/visibe-true/${id}`
    const response = yield call(patchData, url, {}, token)
    if (response) {
      yield put(updateCouponSuccess({}))
      toaster("success", `Update coupon visibility successful`)
      yield put(getCoupon(token))
    }
  } catch (err) {
    const message =
      err?.response?.data?.message || `Update coupon visibility failed`
    toaster("error", message)
    yield put(updateCouponFail(message))
  }
}

function* onGetCoupon({ payload: { token } }) {
  try {
    const url = "/get-all-coupon-for-admin"
    const response = yield call(getData, url, token)
    // console.log(`response`, response)
    yield put(getCouponSuccess(response?.getData))
  } catch (err) {
    const message = err?.response?.data?.message || "Get coupon failed"
    toaster("error", message)
    yield put(getCouponFail(message))
  }
}

function* onGetSingleCoupon({ payload: { token, id } }) {
  try {
    const url = `/get-coupon/${id}`
    const response = yield call(getData, url, token)
    yield put(getSingleCouponSuccess(response?.findCoupon))
  } catch (err) {
    const message = err?.response?.data?.message || "Get Coupon failed"
    toaster("error", message)
    yield put(getSingleCouponFail(message))
  }
}

function* couponSaga() {
  yield takeEvery(ADD_COUPON, onAddCoupon)
  yield takeEvery(GET_COUPON, onGetCoupon)
  yield takeEvery(GET_SINGLE_COUPON, onGetSingleCoupon)
  yield takeEvery(UPDATE_COUPON, onUpdateCoupon)
}

export default couponSaga
