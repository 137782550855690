import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import TableDropdown from "components/Common/TableDropdown"
import { Dropdown } from "react-bootstrap"
import { imageBaseUrl } from "helpers/api_helper"
import CustomTable from "Layout/CustomTable"
import InnerLayer from "Layout/InnerLayer"
import NoTableData from "Layout/NoTableData"
import moment from "moment"
import React, { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { getBlogPost } from "store/actions"
import Select from "react-select"
import { getAnalyticalWriting } from "store/GMAT/Analytical/actions"
import { removeHash } from "helpers/Custom/Hash"
const tableHead = ["#", "Question", "Type","Level Name","Date", ""]

const quesType = [
  {
    name: "analyze an argument",
    value: 1,
  },
  {
    name: "analyze an issue",
    value: 2,
  },
]

const AnalyticalQuestionList = ({ history }) => {
  const {section } = useParams()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [type, setType] = useState(1)
  const { analyticalWriting, analyticalWritingLoading, authToken } =
    useSelector(state => ({
      analyticalWritingLoading:
        state.analyticalReducer.analyticalWritingLoading,
      analyticalWriting: state.analyticalReducer.analyticalWriting,
      authToken: state.Login.token,
    }))
  console.log("analyticalWriting", analyticalWriting, analyticalWritingLoading)
  useEffect(() => {
    dispatch(getAnalyticalWriting(authToken, section ))
  }, [section])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return logo
  }
  const handleType = data => {
    if (data) {
      setType(data?.value)
    } else {
      setType("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Analytical Type"
        mainTitle={"Analytical Type"}
        subTitle={"Analytical Question"}
        wrapperClass="py-3 users"
        link={"/analytical-writing-type"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Question List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Link to={`/analytical-writing-ques/create/${section}`}>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create Question
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        {/* list */}
        <Row>
          <CardComponent className="position-relative">
            {/* <div
              style={{
                width: "200px",
                marginLeft: "auto",
                marginBottom: "10px",
              }}
            >
              <Select
                name="status"
                className="select-form-width"
                classNamePrefix="select2-selection"
                placeholder="Select type"
                cacheOptions
                getOptionLabel={e => e.name}
                getOptionValue={e => e.value}
                isClearable
                options={quesType}
                onChange={handleType}
              />
            </div> */}
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={analyticalWriting}
              pageNo={1}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              isPagination
              // setCurrentPage={setCurrentPage}
              // tbodyStyle={{ height: couponData?.length <= 3 ? "300px" : "" }}
            >
              {analyticalWritingLoading ? (
                <NoTableData
                  colSpan="8"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <Loader />
                </NoTableData>
              ) : analyticalWriting?.length>0 ? (
                analyticalWriting?.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row" style={{ paddingLeft: "25px" }}>
                      {(currentPage - 1) * 10 + (idx + 1)}
                    </th>
                    <td>{data?.questionTitle}</td>
                    <td>{section ==="1" ? "Analyze an argument": "Analyze an issue"}</td>
                    {/* <td>{removeHash(data?.q)?.slice(3,-4)}</td> */}
                    <td>{data?.difficultyLevel === 1 ? "easy" : data?.difficultyLevel === 2 ? "medium" : "hard"}</td>
                    <td>{data?.createdAt?.slice(0,10)}</td>
                    <td>
                      <TableDropdown>
                        <Dropdown.Item
                          className="d-flex align-items-center gap-2"
                          onClick={() => {
                        
                            history.push(
                              `/analytical-writing-ques/${data?._id}/edit/${section}`
                            )
                        
                        }}
                        >
                          <i className="bx bx-edit fs-5"></i> Edit
                        </Dropdown.Item>
                      </TableDropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <NoTableData
                  colSpan="8"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>No data Available</span>
                </NoTableData>
              )}
            </CustomTable>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(AnalyticalQuestionList)
