import Loader from "components/Common/Loader"
import ResultUnderProcess from "components/resultUnderProcess/ResultUnderProcess"
import { imageBaseUrl } from "helpers/api_helper"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { getSpeakingResult } from "store/SpeakingQuestion/actions"
import packageProfile from "../../assets/images/Icon/Frame.svg"
import packageImage from "./img/01-3.png"
import "./ResultPage.scss"

const time = {
  10: `10:00 - 10:30`,
  1030: `10:30 - 11:00`,
  11: `11:00 - 11:30`,
  1130: `11:30 - 12:00`,
  12: `12:00 - 12:30`,
  1230: `12:30 - 01:00`,
  1: `01:00 - 01:30`,
  130: `01:30 - 02:00`,
  2: `02:00 - 02:30`,
  230: `02:30 - 03:00`,
  3: `03:00 - 03:30`,
  330: `03:30 - 04:00`,
  4: `04:00 - 04:30`,
}

// const score = [
//   9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0,
// ];

const Result = ({ history }) => {
  const { id, packageId, type, slotId } = useParams()
  const dispatch = useDispatch()
  const { authToken, profile, result, loading } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.speakingQues.loading,
    profile: state.Profile?.data,
    result: state.speakingQues.result,
  }))

  console.log(`results, loading`, result)
  useEffect(() => {
    dispatch(getSpeakingResult(authToken, slotId))
  }, [])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return packageImage
  }
  const getPhotoUrl1 = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return packageProfile
  }

  return (
    <React.Fragment>
      <InnerLayer isBreadCrumb={false}>
        <div className="result-main-area mt-0">
          <div className="result-inner-area pt-0">
            <h3>
              <span onClick={() => history.goBack()}>
                <i className="bx bx-arrow-back cursor_pointers"></i>
              </span>
              Your Result
            </h3>
            {loading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : result?.complete > 1 &&
              (result?.attandance === 0 || result?.attandance === 1) ? (
              <div className="result-main-area-inner-box">
                <h4>Package</h4>
                <div className="course-result--profile-area first-result-box">
                  <Row>
                    <Col lg={3}>
                      <div
                        className="exem-course-result-package rounded-5 m-0"
                        style={{
                          boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.08)",
                          overflow: "hidden",
                        }}
                      >
                        <Card className="course-result--profile-box rounded-5 m-0">
                          <Card.Img
                            variant="top"
                            src={getPhotoUrl(result?.packageId?.photo)}
                          />
                          <Card.Body
                            style={{
                              borderRadius: "0 0 10px 10px",
                              overflow: "hidden",
                            }}
                          >
                            <div className="course-review-area-exam-page">
                              <span>
                                <i className="bx bxs-star"></i>
                              </span>
                              <span>
                                <i className="bx bxs-star"></i>
                              </span>
                              <span>
                                <i className="bx bxs-star"></i>
                              </span>
                              <span>
                                <i className="bx bxs-star"></i>
                              </span>
                              <span>
                                <i className="bx bxs-star"></i>
                              </span>
                              <span className="count-review-exam-page">
                                5.00 (3)
                              </span>
                            </div>
                            <Card.Title
                              className="course-review-area-exam-course-title"
                              style={{ fontSize: "18px" }}
                            >
                              {result?.packageId?.packageName}
                            </Card.Title>
                            {/* <Button variant="primary">Go somewhere</Button> */}
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col lg={9}>
                      <div className="exam-result-profile-feedback-view ps-3">
                        <div className="exam-result-profile---content">
                          <img
                            style={{
                              maxWidth: "100px",
                              minHeight: "100px",
                              objectFit: "cover",
                            }}
                            className="rounded-circle"
                            src={getPhotoUrl1(result?.studentId?.photo)}
                            alt=""
                          />
                          <span>{result?.studentId?.fullName}</span>
                        </div>
                        <div
                          className={
                            "d-flex justify-content-center text-center gap-5 border-bottom py-4"
                          }
                        >
                          <div className="exam-result-feedback---item">
                            <p>Your Band Score</p>
                            <h6>{result?.score || 0}</h6>
                          </div>
                          <div className="exam-result-feedback---item">
                            <p>Time Spent</p>
                            <h6>
                              {result?.timeSpent
                                ? `${result?.timeSpent} min`
                                : 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="exam-ques-your-position"></div>
                    </Col>
                  </Row>
                  <div className="pt-2 d-flex align-items-center">
                    <img
                      src={require(`./img/speaking.svg`).default}
                      alt="set"
                      width={"30px"}
                    />
                    Set : <strong>{time?.[result?.meetingTime]}</strong>
                  </div>
                </div>

                {result?.complete === 3 && result?.totalMark > -1 ? (
                  <>
                    <Row className="mx-4">
                      <Col md="8">
                        <h4 className="mb-4">Your answers keys</h4>
                        <div className="result-data-field-wrap mt-2">
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Fluency and Coherence</label>
                            </div>
                            <div className="result-field">
                              <input
                                readOnly
                                value={result?.grammar}
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Lexical Resource</label>
                            </div>
                            <div className="result-field">
                              <input
                                readOnly
                                value={result?.spelling}
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Pronunciation</label>
                            </div>
                            <div className="result-field">
                              <input
                                readOnly
                                value={result?.adminEvualation}
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Grammatical range and accuracy</label>
                            </div>
                            <div className="result-field">
                              <input
                                value={result?.totalMark}
                                readOnly
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Recorded Link</label>
                            </div>
                            <div className="result-field">
                              <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={result?.recodingLink}
                                className="btn btn-sm btn-primary"
                              >
                                View Record
                              </a>
                            </div>
                          </div>
                          <div className="single-result-data-wrap mb-3">
                            <div className="result-title">
                              <label>Remark</label>
                            </div>
                            <div className="result-field">
                              <textarea
                                rows="3"
                                type="number"
                                value={result?.remark}
                                readOnly
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="p-3">
                      <h4 id="target1" className="mt-3">
                        Share your score
                      </h4>
                      <div className="border d-flex justify-content-center p-4">
                        <div className="d-flex gap-2">
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#D2ECFA",
                              color: "#0073B1",
                              lineHeight: "60px",
                            }}
                            className="rounded-circle text-center"
                          >
                            <i className="bx bxl-linkedin fs-4"></i>
                          </div>
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#BECFF7",
                              color: "#3D6AD6",
                              lineHeight: "60px",
                            }}
                            className="rounded-circle text-center"
                          >
                            <i className="bx bxl-facebook fs-4"></i>
                          </div>
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#CCEEFC",
                              color: "#03A9F4",
                              lineHeight: "60px",
                            }}
                            className="rounded-circle text-center"
                          >
                            <i className="bx bxl-twitter fs-4"></i>
                          </div>
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "#BECFF7",
                              color: "#3D6AD6",
                              lineHeight: "60px",
                            }}
                            className="rounded-circle text-center"
                          >
                            <i className="bx bx-link fs-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="mx-3">
                    <h4>Result</h4>
                    <div className="speaking-package-listed-view-wrap ">
                      {result?.attandance === 0 ? (
                        <h5 className="result_title">Student is absent</h5>
                      ) : (
                        <h5 className="result_title">Result Under Process</h5>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <ResultUnderProcess />
            )}
          </div>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(Result)
