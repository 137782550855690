import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import CustomTable from "Layout/CustomTable"
import NoTableData from "Layout/NoTableData"
import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Row } from "reactstrap"
import { getSinglePurchase } from "store/actions"

const OrderList = () => {
  const { studentId } = useParams()
  const dispatch = useDispatch()
  const { authToken, singlePurchase, singlePurchaseLoading } = useSelector(
    state => ({
      authToken: state.Login.token,
      singlePurchaseLoading: state.studentReducer.singlePurchaseLoading,
      singlePurchase: state.studentReducer.singlePurchase,
    })
  )


//   useEffect(() => {
//     if (studentId) {
//       dispatch(getSinglePurchase(authToken, studentId))
//     }
//   }, [])
  return (
    <div>
      {singlePurchaseLoading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Row className="mt-3">
            <CardComponent className="position-relative">
              <h5 className="mb-3">
                Total Amount :{" "}
              
              </h5>
              <CustomTable
                paginationClass="paginationContainer text-right"
                tableHead={["Sr", "Package Name", "Amount", "Purchase Date"]}
              >
                {false ? (
                  <NoTableData
                    colSpan="4"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px", width: `100%` }}
                  >
                    <Loader />
                  </NoTableData>
                ) : true >
                  0 ? (
                  [0,1,2,3]?.map(
                    (data, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <td scope="row">{idx + 1}</td>
                          <td></td>
                          {
                            <td>
                             
                            </td>
                          }

                          <td></td>
                        </tr>
                      </React.Fragment>
                    )
                  )
                ) : (
                  <NoTableData
                    colSpan="4"
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px", width: `100%` }}
                  >
                    <span>No data here</span>
                  </NoTableData>
                )}
              </CustomTable>
            </CardComponent>
          </Row>
        </div>
      )}
    </div>
  )
}

export default OrderList
