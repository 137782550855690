import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { addQuantitativeQuesFail, addQuantitativeQuesSuccess, addVerbalPassageQuesFail, addVerbalPassageQuesSuccess, addVerbalQuesFail, addVerbalQuesSuccess, getAllQuantitativeQuesFail, getAllQuantitativeQuesSuccess, getAllVerbalQuesFail, getAllVerbalQuesSuccess, getSingleQuantitativeQuesFail, getSingleQuantitativeQuesSuccess, getSingleVerbalQues, getSingleVerbalQuesFail, getSingleVerbalQuesSuccess, getSingleVerbalReadingQues, getSingleVerbalReadingQuesFail, getSingleVerbalReadingQuesSuccess, getVerbalPassageDetails, getVerbalPassageDetailsFail, getVerbalPassageDetailsSuccess, updateQuantitativeQuesFail, updateQuantitativeQuesSuccess } from "./actions";
import { ADD_QUANTITATIVE_QUES, ADD_VERBAL_PASSAGE_QUES, ADD_VERBAL_QUES, GET_QUANTITATIVE_QUES, GET_SINGLE_VERBAL_QUES, GET_VERBAL_PASSAGE_DETAILS, GET_VERBAL_QUES, GET_VERBAL_READING_SINGLE_QUES, UPDATE_QUANTITATIVE_QUES } from "./actionTypes";


function* onAddVerbalQuestion({ payload: { obj, authToken, option } }) {
  try {
    console.log('banner',  option);

    const url = option?.id? `gmat/verbal/update-verbal/${option?.id}`: option?.passageId ? `gmat/verbal/update-verbal/${option?.passageId}` :`gmat/verbal/verbal-question-create`
    const response = yield call(option?.id ? patchData:  option?.passageId ? patchData : postData, url, obj, authToken)

    if (response) {
      toaster(
        "success",
        `Verbal question ${(option?.id || option?.passageId)? "updated" : "added"} successfully`
      );
      option?.setSubmitting(false);
      option?.resetForm();
      if (option?.isMore) {
        yield put(addVerbalQuesSuccess());
        option?.setIsAddMore(false);
      } else {
        if(option?.id){
          yield put(getSingleVerbalQues(authToken, option?.id ));
        }
        if(option?.passageId){
          yield put(getVerbalPassageDetails(authToken, option?.passageId ));
        }
        yield put(addVerbalQuesSuccess());
        // option?.history.push(`/verbal-ques-list`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Quantitative question creation failed`
    toaster("error", message)
    option?.setSubmitting(false);
    yield put(addVerbalQuesFail(message))
  }
}
function* onGetVerbalQuestion({ payload: { token, type, level } }) {
  try {
    const url = `gmat/verbal/get-verbal/${type}/${level}`;
    const response = yield call(getData, url, token);
    yield put(getAllVerbalQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getAllVerbalQuesFail(message));
    toaster("error", message);
  }
}
function* onGetSingleVerbalQuestion({ payload: { authToken, questionId } }) {
  try {
    const url = `gmat/verbal/get-single-verbal/${questionId}`;
    const response = yield call(getData, url, authToken);
    yield put(getSingleVerbalQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getSingleVerbalQuesFail(message));
    toaster("error", message);
  }
}

   
// function* onUpdateQuantitativeQuestion({ payload: { data, token, history, screenId } }) {
//   try {
//     console.log('banner',  screenId);
//     const url = screenId ? `gmat/create-gmat-writing-question` : `gmat/create-gmat-writing-question`
//     const response = yield call(screenId ? patchData: postData, url, data, token)
//     if (response) {
//       yield put(updateQuantitativeQuesSuccess({}))
//     //   yield put(getScreenList(token));
//       toaster("success", `Analytical writing created Successfully`)
      
//     }
//     // history.push("/author-list")
//   } catch (err) {
//     const message =
//       err?.response?.data?.message || `Analytical writing creation failed`
//     toaster("error", message)
//     yield put(updateQuantitativeQuesFail(message))
//   }
// }

function* onGetVerbalPassageDetails({ payload: { token, passageId } }) {
  try {
    const url = `gmat/verbal/get-verbal-reading-mcq/${passageId}`;
    const response = yield call(getData, url, token);
    yield put(getVerbalPassageDetailsSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getVerbalPassageDetailsFail(message));
    toaster("error", message);
  }
}

function* onAddVerbalPassageQues({ payload: { obj, authToken, option } }) {
  try {
    console.log('banner',  option);
    const url = option?.id? `gmat/verbal/update-verbal-reading-mcq/${option?.id}`: `gmat/verbal/create-verbal-reading-mcq/${option?.passageId}`
    const response = yield call(option?.id ? patchData : postData, url, obj, authToken)

    if (response) {
      toaster(
        "success",
        `Verbal question ${option?.id ? "updated" : "added"} successfully`
      );
      option?.setSubmitting(false);
      option?.resetForm();
      if (option?.isMore) {
        yield put(addVerbalPassageQuesSuccess());
        option?.setIsAddMore(false);
      } else {
        yield put(addVerbalPassageQuesSuccess());
        yield put(getSingleVerbalReadingQues(authToken, option?.id));
        
        // option?.history.push(`reading-comprehension/singlePassage/${option?.passageId}`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Quantitative question creation failed`
    toaster("error", message)
    option?.setSubmitting(false);
    yield put(addVerbalPassageQuesFail(message))
  }
}
function* onGetSingleVerbalReadingQuestion({ payload: { authToken, questionId } }) {
  try {
    const url = `/gmat/verbal/get-verbal-reading-single-mcq/${questionId}`;
    const response = yield call(getData, url, authToken);
    yield put(getSingleVerbalReadingQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getSingleVerbalReadingQuesFail(message));
    toaster("error", message);
  }
}
function* VerbalQuesSaga() {
  yield takeEvery(ADD_VERBAL_QUES, onAddVerbalQuestion)
  yield takeEvery(GET_VERBAL_QUES, onGetVerbalQuestion)
  
  yield takeEvery(GET_SINGLE_VERBAL_QUES, onGetSingleVerbalQuestion)
  yield takeEvery(GET_VERBAL_PASSAGE_DETAILS, onGetVerbalPassageDetails)
  yield takeEvery(ADD_VERBAL_PASSAGE_QUES, onAddVerbalPassageQues)
  yield takeEvery(GET_VERBAL_READING_SINGLE_QUES, onGetSingleVerbalReadingQuestion)



}

export default VerbalQuesSaga
