import NonAuthLayout from "components/NonAuthLayout"
import VerticalLayout from "components/VerticalLayout"
import NotFound from "pages/404/NotFound"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import AuthMiddleware from "routes/Middleware/AuthMiddleware"
import { authRoute, openRoute } from "routes/Routes"
// Import scss
import "./assets/scss/theme.scss"

const App = () => {
  return (
    <React.Fragment>
      <Switch>
        {openRoute.map((route, idx) => (
          <Route
            key={idx}
            exact
            path={route.path}
            component={route.component}
            layout={NonAuthLayout}
            isAuthProtected={false}
          />
        ))}
        {authRoute.map((route, idx) => (
          <AuthMiddleware
            key={idx}
            exact
            path={route.path}
            component={route.component}
            layout={VerticalLayout}
            isAuthProtected
            roles={route.roles}
          />
        ))}
        <Route exact path="*" component={NotFound} />
        <ToastContainer />
      </Switch>
    </React.Fragment>
  )
}

export default App
