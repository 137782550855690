// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import React from "react"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, withRouter } from "react-router-dom"
// action
import "react-toastify/dist/ReactToastify.css"
import { Col, Container, Row } from "reactstrap"
import { updatePassword } from "store/actions"
import "./authentication.scss"
import updatePassImage from "./img/updatePass.svg"
function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const TeacherVerify = ({ history }) => {
  const dispatch = useDispatch()
  let query = useQuery()

  const { loading, token } = useSelector(state => ({
    loading: state.updatePassword.loading,
    token: state.Login.token,
  }))
  const email = query.get("email")
  const accountActivateToken = query.get("token")
  // console.log("email data", accountActivateToken, email, history)

  function handleValidSubmit(event, values, errors) {
    // console.log("email data", accountActivateToken, values, email, errors)
    if (errors?.length === 0 && !email && !token) return
    const { confirmPassword, ...rest } = values
    const body = {
      ...rest,
      email,
      accountActivateToken,
    }
    console.log(`handleValidSubmit ~ body`, body)
    dispatch(updatePassword(body, { history, token }))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Verify Teacher</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="update-password-main-wrap">
        <Container>
          <div className="update-password-inner-wrap">
            <Row className="justify-content-center">
              <Col lg={6} className="my-auto">
                <div className="update-password-left-image">
                  <img src={updatePassImage} alt="" />
                </div>
              </Col>{" "}
              <Col lg={6} className="my-auto">
                <CardComponent className="overflow-hidden pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <h3>Welcome Back!</h3>
                      <p>
                        To create your account, please fill up the
                        information.
                      </p>
                      <br />
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="New Password"
                          className="form-control"
                          placeholder="Enter Your Password"
                          type="password"
                          validate={{
                            required: { value: true },
                            minLength: {
                              value: 8,
                              errorMessage: "Password must be 8 characters",
                            },
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="confirmPassword"
                          label="Confirm Password"
                          className="form-control"
                          placeholder="Please Confirm Your Password"
                          type="password"
                          validate={{
                            required: { value: true },
                            match: {
                              value: "password",
                              errorMessage: "Password not matched",
                            },
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="meetLink"
                          label="Enter Meet Link"
                          className="form-control"
                          placeholder="Please Enter Your Meet Link"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please Enter Your Meet Link",
                            },
                            pattern: {
                              value:
                                "^(https?://)?(www\\.)?meet\\.google\\.com/([a-zA-Z0-9\\-\\_]+)$",
                              errorMessage: "Please Enter Valid MeetLink",
                            },
                          }}
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? "Submitting..." : "Continue"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardComponent>
                {/* <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} . Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TeacherVerify)
