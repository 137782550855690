import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { getData, patchData, postData } from "../../../helpers/backend_helper"
import {
  addIELTSReadingPassageFail,
  addIELTSReadingPassageSuccess,
  addIELTSReadingSetFail,
  addIELTSReadingSetSuccess,
  getIELTSReadingPassageFail,
  getIELTSReadingPassageSuccess,
  getIELTSReadingSetFail,
  getIELTSReadingSetSuccess,
  getIELTSReadingSinglePassageFail,
  getIELTSReadingSinglePassageSuccess,
  getIELTSSingleReadingSetFail,
  getIELTSSingleReadingSetSuccess,
} from "./actions"
//for main
import {
  ADD_IELTS_READING_PASSAGE,
  ADD_IELTS_READING_SET,
  GET_IELTS_READING_PASSAGE,
  GET_IELTS_READING_SET,
  GET_IELTS_READING_SINGLE_PASSAGE,
  GET_IELTS_SINGLE_READING_SET,
} from "./actionTypes"

function* AddIeltsReadingSet({
  payload: { data, history, authtoken, serviceID, id },
}) {
  try {
    const url1 = `/readingset/update-reading-set/${id}`
    const url = `/readingset/create/${serviceID}`
    const response = yield call(
      id ? patchData : postData,
      id ? url1 : url,
      data,
      authtoken
    )
    console.log(response)
    yield put(addIELTSReadingSetSuccess(response))
    toaster("success", `${id ? "Update" : "Create"} set successful`)
    history.push(`/reading-set/${serviceID}`)
  } catch (error) {
    let message =
      error.response.data.message || `${id ? "Update" : "Create"} set failed`
    toaster("error", message)
    yield put(addIELTSReadingSetFail(message))
  }
}

function* AddIeltsReadingPassage({
  payload: { data, history, authtoken, readingSetID, setId, edit },
}) {
  console.log(`edit`, data, history, authtoken, readingSetID, setId, edit)
  try {
    console.log("render")
    const url = `/readingpassage/${readingSetID}/passagecreate`
    const url1 = `/readingpassage/${edit}/update-passage`
    const response = yield call(
      edit ? patchData : postData,
      edit ? url1 : url,
      data,
      authtoken
    )
    console.log(`58 ~ response`, response)
    yield put(addIELTSReadingPassageSuccess(response))
    if (response) {
      toaster(
        "success",
        edit ? "Update Passage Successful" : "Add Passage Successful"
      )
      history.push(`/service/${setId}/reading/${readingSetID}`)
    }
  } catch (error) {
    console.log("render2")
    const message =
      error?.response?.data?.message ||
      `${edit ? "Update" : "Add"} Passage Failed`
    yield put(addIELTSReadingPassageFail(message))
    toaster("error", message)
  }
}

function* fetchIeltsReadingSet({ payload: { authtoken, url } }) {
  try {
    const response = yield call(getData, url, authtoken)
    yield put(getIELTSReadingSetSuccess(response?.data))
  } catch (error) {
    const message = error?.response?.message?.data || "Load Set Failed"
    toaster("error", message)
    yield put(getIELTSReadingSetFail(message))
  }
}

function* fetchIeltsSingleReadingSet({ payload: { authtoken, readingSetID } }) {
  try {
    // console.log(authtoken)
    const url = `/readingset/get-single-set/${readingSetID}`
    const response = yield call(getData, url, authtoken)
    // console.log(response)
    yield put(getIELTSSingleReadingSetSuccess(response?.passage))
  } catch (error) {
    const message = error?.response?.message?.data || "Load Set Failed"
    toaster("error", message)
    yield put(getIELTSSingleReadingSetFail(message))
  }
}

function* fetchIeltsReadingPassage({ payload: { authtoken, readingSetID } }) {
  try {
    const url = `/readingpassage/${readingSetID}/allpassage`
    console.log(authtoken)
    const response = yield call(getData, url, authtoken)
    console.log("fdfasd", response)
    yield put(getIELTSReadingPassageSuccess(response))
  } catch (error) {
    const message = error?.response?.message?.data || "Load Passage Failed"
    toaster("error", message)
    yield put(getIELTSReadingPassageFail(message))
  }
}

function* fetchIeltsSingleReadingPassage({
  payload: { authtoken, passageID },
}) {
  try {
    const url = `readingpassage/get-single-passage/${passageID}`
    const response = yield call(getData, url, authtoken)
    yield put(getIELTSReadingSinglePassageSuccess(response?.passage))
  } catch (error) {
    const message = error?.response?.message?.data || "Load Passage Failed"
    toaster("error", message)
    yield put(getIELTSReadingSinglePassageFail(message))
  }
}

function* readingSaga() {
  yield takeEvery(ADD_IELTS_READING_SET, AddIeltsReadingSet)
  yield takeEvery(GET_IELTS_READING_SET, fetchIeltsReadingSet)
  yield takeEvery(GET_IELTS_SINGLE_READING_SET, fetchIeltsSingleReadingSet)
  yield takeEvery(ADD_IELTS_READING_PASSAGE, AddIeltsReadingPassage)
  yield takeEvery(GET_IELTS_READING_PASSAGE, fetchIeltsReadingPassage)
  yield takeEvery(
    GET_IELTS_READING_SINGLE_PASSAGE,
    fetchIeltsSingleReadingPassage
  )
}
export default readingSaga
