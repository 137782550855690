import Loader from "components/Common/Loader"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap"
import { getFAQInfo } from "store/actions"
import { removePackage } from "store/package/actions"
import CardComponent from "../../components/Common/CardComponent"

const ListFAQ = () => {
  const [keyword, setKeyword] = useState("")
  const dispatch = useDispatch()
  const [deleteItem, setDeleteItem] = useState({
    modal: false,
    data: {},
  })
  const history = useHistory()
  const { authtoken, message, values, loading, packageData, faqInfoList } =
    useSelector(state => ({
      authtoken: state.Login.token,
      loading: state.packageReducer.loading,
      message: state.packageReducer.message,
      error: state.packageReducer.error,
      values: state.packageReducer.values,
      faqInfoList: state.FAQReducer.faqInfoList,
    }))
  console.log(faqInfoList)

  useEffect(() => {
    dispatch(getFAQInfo(authtoken))
  }, [])

  return (
    <React.Fragment>
      {deleteItem.modal ? (
        <SweetAlert
          warning
          showCancel
          showCloseButton
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          title="Are you sure?"
          onConfirm={() => {
            dispatch(removePackage(authtoken, deleteItem.data._id))
            setDeleteItem({
              modal: false,
              data: {},
            })
          }}
          onCancel={() =>
            setDeleteItem({
              modal: false,
              data: {},
            })
          }
        >
          You wont revert back
        </SweetAlert>
      ) : null}
      <InnerLayer
        isBreadCrumb={true}
        title="FAQ List"
        mainTitle={"Dashboard"}
        subTitle={"FAQ List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>FAQ list</h4>
            <div className="d-flex">
              <div>
                <div className="position-relative">
                  <Link to="/createFaqList">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create FAQ
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        <Row>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            faqInfoList?.map((data, idx) => (
              <Col
                xl={12}
                key={idx}
                style={{ height: "100%" }}
              
              >
                <Card className="faq-single-part-area-faq">
                  <span
                    className="position-absolute  mt-2"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      padding: "5px 7px",
                      lineHeight: "5px",
                      top: "2%",
                      right: "2%",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      history.push(`/editFaq/${data?._id}`)
                    }

                  >
                    <i className="bx bx-edit-alt"></i>
                  </span>
                  <span
                    className="position-absolute text-danger mt-2"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      padding: "5px 7px",
                      lineHeight: "5px",
                      top: "2%",
                      right: "4%",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                 
                  >
                    <i className="fa fa-times"></i>
                  </span>

                  <CardBody>
                    <CardTitle className="mt-0">{data?.question} <span>({data?.faqType?.typeName})</span> </CardTitle>
                    <hr style={{ color: "rgb(182 182 182)" }} />
                    <CardText>{data?.answer}</CardText>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ListFAQ
