import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getData,
  patchData,
  postData,
} from "../../../../helpers/backend_helper"
import { getIELTSReadingSinglePassage } from "../actions"
import {
  addIELTSReadingQuesMCQFail,
  addIELTSReadingQuesMCQSuccess,
  getIELTSReadingQuesMCQFail,
  getIELTSReadingQuesMCQSuccess,
  getIELTSReadingQuesNum,
  getIELTSReadingQuesNumFail,
  getIELTSReadingQuesNumSuccess,
  getIELTSReadingQuesStatus,
  getIELTSReadingQuesStatusFail,
  getIELTSReadingQuesStatusSuccess,
  storeQuestion,
  updateIELTSReadingQuesStatusFail,
  updateIELTSReadingQuesStatusSuccess,
} from "./actions"
//for main
import {
  ADD_IELTS_READING_MCQ_QUES,
  GET_IELTS_READING_MCQ_QUES,
  GET_IELTS_READING_QUES_NUM,
  GET_IELTS_READING_QUES_STATUS,
  UPDATE_IELTS_READING_QUES_STATUS,
} from "./actionTypes"

function* AddIeltsReadingMcqQues({
  payload: {
    data,
    history,
    authtoken,
    options: { isMore, resetForm, setIsAddMore, questionId, passageId, type },
  },
}) {
  try {
    const url = questionId
      ? `/readingquestion/update-question/${passageId}/${questionId}`
      : "/readingquestion/questioncreate"
    const response = yield call(
      questionId ? patchData : postData,
      url,
      data,
      authtoken
    )
    yield put(addIELTSReadingQuesMCQSuccess(response))
    if (!isMore) {
      history.goBack()
    } else {
      resetForm()
      yield put(getIELTSReadingQuesNum(authtoken, passageId, type))
      setIsAddMore(false)
    }
    toaster(
      "success",
      `Question ${questionId ? "Update" : "added"} successfully!`
    )
    //    history.push("/IeltsSetList")
  } catch (error) {
    setIsAddMore(false)
    const message =
      error?.response?.data?.message ||
      `Question ${id ? "Update" : "added"} failed!`
    yield put(addIELTSReadingQuesMCQFail(message))
    // console.log("hi",message);
    toaster("error", message)
  }
}

function* onGetReadingMcqQues({ payload: { id, authtoken, data } }) {
  try {
    const url = `/readingquestion/${id}/allquestion`
    const response = yield call(getData, url, authtoken)
    console.log(response)
    yield put(getIELTSReadingQuesMCQSuccess(response?.allquestion))
    if (data) {
      const findData = response?.allquestion?.find(
        dt => dt._id === data.objectId
      )
      const findQues = findData?.Que?.question.find(
        dt => dt._id === data.questionId
      )
      console.log(`findQues`, findQues, findData)
      yield put(storeQuestion("singleQuestion", findQues))
      yield put(storeQuestion("questionNumber", findQues?.questionNumber))
    }
    // toaster("success", "Question  successfully!")
    //    history.push("/IeltsSetList")
  } catch (error) {
    const message = error?.response?.data?.message
    yield put(getIELTSReadingQuesMCQFail(message))
    // console.log("hi",message);
    toaster("error", message)
  }
}

function* onGetReadingQuesNum({ payload: { id, authtoken, type } }) {
  try {
    const url = `/readingquestion/get-question-serial/${id}/${type}`
    const response = yield call(getData, url, authtoken)
    yield put(getIELTSReadingQuesNumSuccess(response?.position))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Load Question Number failed"
    yield put(getIELTSReadingQuesNumFail(message))
    toaster("error", message)
  }
}

function* onGetReadingQuesStatus({ payload: { id, authtoken, type } }) {
  try {
    const url = `/readingquestion/question-type-status/${id}`
    const response = yield call(getData, url, authtoken)
    yield put(getIELTSReadingQuesStatusSuccess(response?.obj))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Load Question status failed"
    yield put(getIELTSReadingQuesStatusFail(message))
    toaster("error", message)
  }
}

function* onUpdateReadingQuesStatus({ payload: { id, authtoken, type } }) {
  try {
    const url = `/readingquestion/update-question-status/${id}/${type}`
    const response = yield call(patchData, url, authtoken)
    yield put(updateIELTSReadingQuesStatusSuccess(response))
    yield put(getIELTSReadingSinglePassage(authtoken, id))
    yield put(getIELTSReadingQuesStatus(authtoken, id))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Update Question status failed"
    yield put(updateIELTSReadingQuesStatusFail(message))
    toaster("error", message)
  }
}

function* readingQuesSaga() {
  yield takeEvery(ADD_IELTS_READING_MCQ_QUES, AddIeltsReadingMcqQues)
  yield takeEvery(GET_IELTS_READING_MCQ_QUES, onGetReadingMcqQues)
  yield takeEvery(GET_IELTS_READING_QUES_NUM, onGetReadingQuesNum)
  yield takeEvery(GET_IELTS_READING_QUES_STATUS, onGetReadingQuesStatus)
  yield takeEvery(UPDATE_IELTS_READING_QUES_STATUS, onUpdateReadingQuesStatus)
}
export default readingQuesSaga
