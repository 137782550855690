import { getData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { getSingleStudentSuccess } from "store/actions"
import { getSinglePurchaseFail, getSinglePurchaseSuccess, getSingleStudentFail, getStudentCountFail, getStudentCountSuccess, getStudentListFail, getStudentListSuccess, getStudentOrderFail, getStudentOrderSuccess, getStudentsSuccess } from "./actions"
import { GET_SINGLE_PURCHASE, GET_SINGLE_STUDENT, GET_STUDENTS, GET_STUDENT_COUNT, GET_STUDENT_LIST, GET_STUDENT_ORDER } from "./actionTypes"

//students list
function* workerGetStudents({ payload: { authtoken } }) {
  try {
    const url = "/admin/get-students"
    const response = yield call(getData, url, authtoken)
    // console.log("hello data", response)
    yield put(getStudentsSuccess(response.getData))
  } catch (error) {
    const message = error.response?.data?.message || "Get student failed"
    toaster("error", message)
    yield put(getSingleStudentFail(message))
    // console.log("hello error", error)
  }
}

//studnt
function* workerGetSingleStudnet({ payload: { authtoken, studentId } }) {
  try {
    const url = `/admin/get-student/${studentId}`
    const response = yield call(getData, url, authtoken)
    if (response.status == "success") {
      yield put(getSingleStudentSuccess(response?.student))
    }
  } catch (error) {
    const message = error.response?.data?.message || "Get student failed"
    toaster("error", message)
    yield put(getSingleStudentFail(message))
  }
}
function* workerGetSinglePurchase({ payload: { authtoken, studentId } }) {
  console.log('authtoken', authtoken);
  try {
    const url = `package/purchase-history/${studentId}`
    const response = yield call(getData, url, authtoken)
    if (response.status == "success") {
      yield put(getSinglePurchaseSuccess(response))
    }
  } catch (error) {
    const message = error.response?.data?.message || "Get student failed"
    toaster("error", message)
    yield put(getSinglePurchaseFail(message))
  }
}

function* getStudentOrder({ payload: { authtoken, startDate, endDate, packageName } }) {
  console.log('authtoken', authtoken);
  try {
    const url = `package/purchase-history/${studentId}`
    const response = yield call(getData, url, authtoken)
    if (response.status == "success") {
      yield put(getStudentOrderSuccess(response))
    }
  } catch (error) {
    const message = error.response?.data?.message || "Get student failed"
    toaster("error", message)
    yield put(getStudentOrderFail(message))
  }
}

function* getStudentList({payload: { authtoken, startDate, endDate, limit, skip, accountActivation } }) {
  console.log('authtoken', authtoken);
  try {
    const url = `get-students-with-range?startDate=${startDate}&endDate=${endDate}&limit=${limit}&skip=${skip}&accountActivation=${accountActivation}`
    const response = yield call(getData, url, authtoken)
    if (response.status == "success") {
      yield put(getStudentListSuccess(response))
    }
  } catch (error) {
    const message = error.response?.data?.message || "Get student failed"
    toaster("error", message)
    yield put(getStudentListFail(message))
  }
}

function* getStudentCount({payload: { authtoken, startDate, endDate, accountActivation } }) {
  console.log('authtoken', authtoken);
  try {
    const url = `total-student-count?startDate=${startDate}&endDate=${endDate}&accountActivation=${accountActivation}`
    const response = yield call(getData, url, authtoken)
    if (response.status == "success") {
      yield put(getStudentCountSuccess(response))
    }
  } catch (error) {
    const message = error.response?.data?.message || "Get student failed"
    toaster("error", message)
    yield put(getStudentCountFail(message))
  }
}
function* studentSaga() {
  // yield takeEvery(REGISTER_STUDENT, workerStudentRegister)
  yield takeEvery(GET_STUDENTS, workerGetStudents)
  yield takeEvery(GET_SINGLE_STUDENT, workerGetSingleStudnet)
  yield takeEvery(GET_SINGLE_PURCHASE, workerGetSinglePurchase)
  yield takeEvery(GET_STUDENT_ORDER, getStudentOrder)
  yield takeEvery(GET_STUDENT_LIST, getStudentList)
  yield takeEvery(GET_STUDENT_COUNT, getStudentCount)
}

export default studentSaga
