import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import UserProfile from "components/Common/UserProfile"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import InnerLayer from "Layout/InnerLayer"
import React, { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import { editProfile } from "store/auth/profile/actions"
import logo from "../../assets/images/Icon/Frame.svg"

const Profile = () => {
  const [profileImage, setProfileImage] = useState(null)
  const dispatch = useDispatch()
  const { login, profile } = useSelector(state => ({
    login: state.Login,
    profile: state.Profile,
  }))
  console.log('profile', profile);
  const { loading, data } = profile
  console.log(`login`, profile)

  // useEffect(() => {
  //   dispatch(getProfile(login?.token));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function handleSubmit(event, errors, values) {
    event.preventDefault()
    const fd = new FormData()
    for (let obj in values) {
      fd.append(obj, values[obj])
    }
    if (profileImage) {
      fd.append("photo", profileImage)
    } else {
      !!data?.photo && fd.append("photo", data.photo)
    }
    for (const obj of fd.entries()) {
      console.log(obj)
    }
    if (errors.length === 0) dispatch(editProfile(fd, login?.token))
  }
  const onChangeImage = e => {
    const file = e.target?.files?.[0]
    setProfileImage(file)
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return logo;
  };

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="User Profile"
        mainTitle={"Dashboard"}
        subTitle={"User Profile"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Row>
          <Col md="6" className="offset-md-3">
            <CardComponent>
              <div className="profile-picture-area">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                ) : (
                  <Fragment>
                    <UserProfile
                      title={"Profile"}
                      data={{
                        name: profile?.data?.getData?.getProfile?.fullName || "N/A",
                        email: profile?.data?.getData?.getProfile?.email || "",
                        designation: toCapitalize(profile?.data?.getData?.getProfile?.role),
                        phone: profile?.data?.getData?.getProfile?.phoneNumber || "",
                        bio: profile?.data?.getData?.getProfile?.bio || "",
                      }}
                      isBio
                      submitting={false}
                      image={getPhotoUrl(data?.photo)}
                      profileImage={profileImage}
                      view
                    />
                  </Fragment>
                )}
              </div>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default Profile
