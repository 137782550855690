import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import UserProfile from "components/Common/UserProfile"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getSingleTeacher } from "store/teacher/actions"
import logo from "../../assets/images/Icon/Frame.svg"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Teacher List",
    link: "/teachers-list",
  },
  {
    title: "Profile",
    link: "#",
  },
]

const ViewTeacher = () => {
  const [profileImage, setProfileImage] = useState(null)
  const dispatch = useDispatch()
  const { id } = useParams()
  const { authToken, teacher, loading } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.teacherReducer.loading,
    teacher: state.teacherReducer.teacher,
  }))
  console.log(`loading`, teacher, loading)
  useEffect(() => {
    dispatch(getSingleTeacher(id, authToken))
  }, [])
  const onChangeImage = e => {
    const file = e.target?.files?.[0]
    setProfileImage(file)
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return logo;
  };
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Register"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ maxWidth: "500px" }} className="mx-auto">
          <CardComponent>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <UserProfile
                title={"View Teacher"}
                data={{
                  name: teacher?.fullName || "N/A",
                  email: teacher?.email || "",
                  designation: toCapitalize(teacher?.role || "Teacher"),
                  phone: teacher?.phoneNumber || "",
                  bio: teacher?.bio || "",
                }}
                isBio
                submitting={false}
                image={getPhotoUrl(teacher?.photo)}
                profileImage={profileImage}
                onChangeImage={onChangeImage}
                view
              />
            )}
          </CardComponent>
        </div>
        {/* <CardComponent>
            <UserProfile
              title={"View Teacher"}
              data={{
                name: "Mofasser",
                email: "",
                designation: "Teacher",
                phone: "",
                bio: "",
              }}
              isBio={true}
              submitting={false}
              image={""}
              profileImage={false}
              view
            />
            <div className="profile-view-inner-wrap">
              <div className="profile-header-top">
                <h3>Your Profile</h3>
              </div>
              <div className="profile-image-content-wrap">
                <img src={profileImage} alt="profile" />
                <input type="file" name="" id="" />
              </div>
              <div className="profile-personal-content-update">
                <Form>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Full name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Henry Qualki"
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Designation
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Henry Qualki"
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      placeholder="info@gmail.com"
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id=""
                      placeholder="+0881234567891"
                    />
                  </div>{" "}
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Bio
                    </label>
                    <textarea
                      className="form-control"
                      id=""
                      rows="3"
                      placeholder="Type"
                    ></textarea>
                  </div>{" "}
                  <div className="mb-3">
                    <Button
                      style={{
                        width: "100%",
                        display: "block",
                        background: "#0052CC",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </CardComponent> */}
        <div style={{ maxWidth: "500px" }} className="mx-auto">
          <CardComponent>
            <div className="profile-deactive-area-wrap">
              <h5
                htmlFor=""
                className="form-label mb-3"
                style={{ fontSize: "16px", fontWidth: "600", color: "#000" }}
              >
                Want to deactivate teacher portfolio?
              </h5>
              <div className="">
                <Button
                  style={{
                    width: "100%",
                    display: "block",
                    background: "#000",
                  }}
                >
                  Deactive
                </Button>
              </div>
            </div>
          </CardComponent>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ViewTeacher
