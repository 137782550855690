import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addCourseTypeFail,
  addCourseTypeSuccess,
  getCourseTypeFail,
  getCourseTypeSuccess,
  getSingleCourseTypeFail,
  getSingleCourseTypeSuccess,
} from "./actions"
// Crypto Redux States
import {
  ADD_COURSE_TYPE,
  GET_COURSE_TYPE,
  GET_SINGLE_COURSE_TYPE,
} from "./actionTypes"

function* onAddCourseType({ payload: { data, token, history, id } }) {
  try {
    const url1 = `/coursetype/update-course-type/${id}`
    const url = "/coursetype/create-course-type"
    const response = yield call(
      id ? patchData : postData,
      id ? url1 : url,
      data,
      token
    )
    if (response) {
      toaster("success", `Course Type ${id ? "Update" : "added"} successful`)
      history.push("/course-type")
    }
    yield put(addCourseTypeSuccess())
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Course Type ${id ? "Update" : "added"} failed`
    toaster("error", message)
    yield put(addCourseTypeFail(message))
  }
}

function* onGetCourseType({ payload: { token } }) {
  try {
    const url = "/coursetype/get-course-type"
    const response = yield call(getData, url, token)
    yield put(getCourseTypeSuccess(response?.getData || []))
  } catch (error) {
    const message = error?.response?.data?.message
    toaster("error", message)
    yield put(getCourseTypeFail(message))
  }
}

function* onGetSingleCourseType({ payload: { token, id } }) {
  try {
    const url = `/coursetype/get-single-course-type/${id}`
    const response = yield call(getData, url, token)
    yield put(getSingleCourseTypeSuccess(response?.getData || {}))
  } catch (error) {
    const message = error?.response?.data?.message || "Get Type Failed"
    toaster("error", message)
    yield put(getSingleCourseTypeFail(message))
  }
}

function* courseTypeSaga() {
  yield takeEvery(ADD_COURSE_TYPE, onAddCourseType)
  yield takeEvery(GET_COURSE_TYPE, onGetCourseType)
  yield takeEvery(GET_SINGLE_COURSE_TYPE, onGetSingleCourseType)
}

export default courseTypeSaga
