import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { calculateTimeCount, findTime } from "helpers/Custom/timeHelper"
import InnerLayer from "Layout/InnerLayer"
import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Form, Modal, Row } from "react-bootstrap"
import Timer from "react-compound-timer/build"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { getSingleSpeakingSetsSingleDay } from "store/Speaking/actions"
import {
  storeSlot,
  teacherStartExam,
  updateAttendaceData,
} from "store/SpeakingQuestion/actions"
import googleMeetIcon from "./img/Google_Meet 1.png"
import "./teacher-review.scss"

const TeacherExamReview = ({ history }) => {
  const { date, slotId } = useParams()
  const [attedance, setAttendance] = useState({
    open: false,
    value: 0,
  })
  const [isAttend, setIsAttend] = useState(true)
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Teacher Exam",
      link: `/teacher-exam`,
    },
    {
      title: "View time slot",
      link: `/teacher-exam/view-class/${date}`,
    },
    {
      title: "Review",
      link: `#`,
    },
  ]

  const dispatch = useDispatch()
  const { authToken, loading, singleDay, adding, role } = useSelector(
    state => ({
      authToken: state.Login.token,
      role: state.Login.userrole,
      loading: state.speakingSet.loading,
      singleDay: state.speakingSet.singleDay,
      adding: state.speakingQues.adding,
    })
  )
  console.log(`loading`, adding)

  const findSlot = singleDay?.find(item => item._id === slotId) || {}
  console.log(`TeacherExamReview ~ findSlot`, findSlot)

  useEffect(() => {
    if (findSlot?.attandance > -1) {
      setIsAttend(false)
    }
  }, [findSlot])

  useEffect(() => {
    dispatch(storeSlot("adding", false))
    dispatch(getSingleSpeakingSetsSingleDay(authToken, { date, role }))
  }, [])

  const handleGotoMeet = () => {
    dispatch(
      teacherStartExam(
        authToken,
        slotId,
        findSlot?.teacherId?.meetLink,
        date,
        role
      )
    )
  }

  const updateAttend = data => {
    const value = { attandance: data }
    dispatch(
      updateAttendaceData({
        token: authToken,
        slotId,
        value,
        date,
        role,
        callBack: toggle,
      })
    )
  }

  const findTimeAvailable = useMemo(
    () => findTime(findSlot?.meetingTime, findSlot, date),
    [findSlot]
  )
  // console.log(`findTimeAvailable`, findTimeAvailable)

  const toggle = () => setAttendance({ open: !attedance?.open, value: 0 })

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Time Slot"
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Modal show={attedance?.open} onHide={toggle} size="md" centered>
          {/* <Modal.Header closeButton /> */}
          <Modal.Body>
            <div className="">
              <h4>Are you sure ?</h4>
              <div className="d-flex gap-2 justify-content-end">
                <button
                  disabled={adding}
                  className="btn btn-warning"
                  onClick={toggle}
                >
                  Cancel
                </button>
                <button
                  disabled={adding}
                  className="btn btn-success"
                  onClick={() => updateAttend(attedance?.value)}
                >
                  {adding ? "Submitting..." : "Yes"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Time Slot</h4>
            </div>
          </CardComponent>
        </Row>
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="teacher-speaking-exam-review-wrap">
            <div className="speaking-exam-main-wrap mt-3 mb-5">
              {findSlot?.attandance === 0 ||
              findSlot?.attandance === 1 ? null : (
                <div className="speaking-exam-date-bottom-wrap">
                  <div className="speaking-package-listed-view-wrap">
                    <h3>Your exam starts In</h3>
                    <h4>
                      <Timer
                        initialTime={
                          !!findSlot ? calculateTimeCount(findSlot) : 0
                        }
                        direction="backward"
                      >
                        <span>
                          <Timer.Days />D :
                        </span>
                        <span>
                          <Timer.Hours />H :
                        </span>
                        <span>
                          <Timer.Minutes />M :
                        </span>
                        <span>
                          <Timer.Seconds />S
                        </span>
                      </Timer>
                    </h4>
                    <div className="exam-google-meet-link-button">
                      {findSlot?.complete === 2 ? (
                        <a
                          className="meet-button"
                          href={findSlot?.teacherId?.meetLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            <img src={googleMeetIcon} alt="google-meet" />
                          </span>
                          <span>Start exam</span>
                        </a>
                      ) : (
                        <button
                          disabled={
                            // !findTimeAvailable ||
                            findSlot?.complete > 2 ||
                            adding
                          }
                          className="meet-button"
                          onClick={handleGotoMeet}
                        >
                          <span>
                            <img src={googleMeetIcon} alt="google-meet" />
                          </span>
                          <span>Start exam</span>
                        </button>
                      )}
                    </div>
                    <Form.Text>Google meet</Form.Text>
                  </div>
                </div>
              )}
              <div className="speaking-exam-date-bottom-wrap mt-4">
                {findSlot?.attandance === 0 ? (
                  <div className="speaking-package-listed-view-wrap enable-button">
                    <h4>Student is absent</h4>
                    <div>
                      <h6>Do you want to update the attendance status?</h6>
                      <h4>
                        <button
                          disabled={
                            findSlot?.attandance === 1 ||
                            findSlot?.complete === 0 ||
                            findSlot?.complete === 1 ||
                            adding
                          }
                          className="btn"
                          onClick={() =>
                            setAttendance({ open: true, value: 1 })
                          }
                        >
                          Yes
                        </button>
                      </h4>
                    </div>
                  </div>
                ) : (
                  <div className="speaking-package-listed-view-wrap enable-button">
                    {findSlot?.attandance === 1 ? (
                      <h4>Student has attended the exam</h4>
                    ) : (
                      <React.Fragment>
                        <h3>Did student attend the exam?</h3>
                        <h4>
                          <button
                            disabled={
                              findSlot?.attandance === 1 ||
                              findSlot?.complete === 0 ||
                              findSlot?.complete === 1 ||
                              adding
                            }
                            className="btn"
                            onClick={() =>
                              setAttendance({ open: true, value: 0 })
                            }
                          >
                            No
                          </button>
                          <button
                            disabled={
                              findSlot?.attandance === 1 ||
                              findSlot?.complete === 0 ||
                              findSlot?.complete === 1 ||
                              adding
                            }
                            className="btn"
                            onClick={() =>
                              setAttendance({ open: true, value: 1 })
                            }
                          >
                            Yes
                          </button>
                        </h4>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
              {findSlot?.attandance === 1 && (
                <div className="speaking-exam-date-bottom-wrap mt-4 ">
                  <div className="speaking-package-listed-view-wrap enable-button">
                    <h3>Set Result</h3>
                    <h4>
                      {findSlot?.totalMark > -1 && findSlot?.complete === 3 ? (
                        <Fragment>
                          <Link
                            to={`/teacher-exam/result/${slotId}`}
                            className="btn"
                          >
                            See Result
                          </Link>
                        </Fragment>
                      ) : (
                        <button
                          className="btn btn-sm"
                          disabled={findSlot?.attandance !== 1}
                          onClick={() =>
                            history.push(
                              `/teacher-exam/view-class/${date}/single-slot/${slotId}/mark`
                            )
                          }
                        >
                          Set Now
                        </button>
                      )}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TeacherExamReview)
