import Breadcrumb from "components/Common/Breadcrumb"
import BreadcrumbMultiple from "components/Common/Breadcrumb2"
import PropTypes from "prop-types"
import React from "react"
import { withTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"
const InnerLayer = ({
  children,
  wrapperClass,
  title,
  isBreadCrumb,
  link,
  mainTitle,
  subTitle,
  isMultiple,
  breadcrumbItems,
  extraClass,
}) => {
  return (
    <React.Fragment>
      <div className={"page-content " + extraClass}>
        <Container fluid>
          <MetaTags>
            <title>{title} | XamPro</title>
          </MetaTags>
          {isMultiple ? (
            <BreadcrumbMultiple
              title={title}
              breadcrumbItems={breadcrumbItems}
            />
          ) : (
            <Breadcrumb
              breadcrumbItem={title}
              isBreadCrumb={isBreadCrumb}
              title={mainTitle}
              subTitle={subTitle}
              backLink={link}
            />
          )}
          <div className={wrapperClass}>{children}</div>
        </Container>
      </div>
    </React.Fragment>
  )
}

InnerLayer.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  children: PropTypes.any,
  isBreadCrumb: PropTypes.bool,
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  link: PropTypes.string,
  breadcrumbItems: PropTypes.array,
  isMultiple: PropTypes.bool,
  extraClass: PropTypes.string,
}

export default withTranslation()(InnerLayer)
