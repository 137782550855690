import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Col, Input, Row } from "reactstrap"
import {
  getIELTSReadingQuesMCQ,
  getIELTSReadingQuesNum,
  getIELTSReadingQuesStatus,
  getIELTSReadingSinglePassage,
  getIELTSSingleReadingSet,
} from "store/actions"
import { listType } from "../Question2/TableType"

const PreviewQuestion = ({ history }) => {
  const { serviceId, setId, passageId, type } = useParams()
  // console.log(`🦄 ~ file: MCQ.js ~ line 24 ~ MCQ ~ passageID`, passageId, type)
  const dispatch = useDispatch()
  const {
    authToken,
    loading,
    ques,
    questionStatus,
    questionNumber,
    singlePassage,
  } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.QuesReducer.loading,
    adding: state.QuesReducer.adding,
    services: state.serviceReducer.services,
    ques: state.QuesReducer.ques,
    questionStatus: state.QuesReducer.questionStatus,
    questionNumber: state.QuesReducer.questionNumber,
    singlePassage: state.readingReducer.singleReadingPassage,
  }))
  // console.log("singlePassage", singlePassage, questionNumber)
  // console.log(` ques`, ques, questionStatus)
  const newSetId = localStorage.getItem("setId")
  useEffect(() => {
    if (setId) {
      localStorage.setItem("setId", setId)
    }
    dispatch(getIELTSSingleReadingSet(authToken, !!setId ? setId : newSetId))
    dispatch(getIELTSReadingSinglePassage(authToken, passageId))
    dispatch(getIELTSReadingQuesStatus(authToken, passageId))
    dispatch(getIELTSReadingQuesNum(authToken, passageId, type))
  }, [])

  const filterData = useMemo(
    () => ques?.filter(data => data?.Que?.questype === type) || {},
    [ques]
  )
  // const findQuestions = useMemo(
  //   () => {
  //     const data = []
  //     filterData.forEach(dt => {
  //       data.push(...data?.Que.)
  //     })
  //   },
  //   [filterData]
  // )

  const findData = useMemo(
    () => ques?.find(data => data?.Que?.questype === type) || {},
    [ques]
  )
  // console.log(`PreviewQuestion ~ findData`, findData, filterData)

  useEffect(() => {
    dispatch(getIELTSReadingQuesMCQ(authToken, passageId))
    dispatch(getIELTSReadingQuesStatus(authToken, passageId))
  }, [])
  const isDone = useMemo(() => {
    const question = singlePassage?.totalQuestion
    const submitedQuestion = questionNumber - 1
    // console.log("question data here", question, submitedQuestion)
    if (question <= submitedQuestion) {
      return true
    }
    return false
  }, [questionNumber])

  return (
    <React.Fragment>
      <InnerLayer isBreadCrumb={false} title="Preview Questions">
        <Row className="mt-3">
          <Col md="10" className="offset-md-1">
            <CardComponent className={"mb-0"}>
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-left-arrow-alt me-2"
                  onClick={() => history.goBack()}
                  style={{ fontSize: "18px" }}
                ></i>
                <h6 className="mb-0">Preview Questions</h6>
              </div>
            </CardComponent>
            <CardComponent style={{ marginTop: "5px" }}>
              {loading ? (
                <div className="text-center pt-5">
                  <Loader />
                </div>
              ) : Object.keys(findData).length > 0 ? (
                <React.Fragment>
                  {type.match(/table/gi) ||
                  type.match(/form/gi) ||
                  type.match(/note/gi) ? (
                    <Row>
                      <div className="d-flex justify-content-between">
                        <h6>Image</h6>
                        <div>
                          <i className="bx bx-edit"></i>
                        </div>
                      </div>
                      <Col md="10" className="mx-auto text-center p-2">
                        <img src={findData?.Que?.questionDetails} alt="image" />
                      </Col>
                      <Row className="mb-3 mt-2">
                        <Col md="3">Select Condition</Col>
                        <Col md="9">
                          <Input
                            name="answerType"
                            placeholder="Select answer type"
                            type="select"
                            value={findData?.Que?.answerType}
                          >
                            {listType &&
                              listType.map((player, idx) => {
                                return (
                                  <option key={idx} value={player}>
                                    {player}
                                  </option>
                                )
                              })}
                          </Input>
                        </Col>
                      </Row>
                    </Row>
                  ) : null}
                  <ul className="list-group mt-3">
                    {(findData?.Que?.question || []).map((data, key) => (
                      <div key={key}>
                        <li className="list-group-item border-0">
                          <div className="d-flex">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-light question_number">
                                <p className="text-dark p-2 mt-3">
                                  {data?.questionNumber}
                                </p>
                              </span>
                            </div>
                            <div className="flex-grow-1 position-relative">
                              <div
                                className="position-absolute"
                                style={{ top: "0", right: "0" }}
                              >
                                <i
                                  className="bx bxs-edit fs-5"
                                  onClick={() =>
                                    history.push(
                                      `/listening-exam/${passageId}/${type}/${data._id}/${findData._id}`
                                    )
                                  }
                                ></i>
                                <i className="bx bx-trash text-danger fs-5"></i>
                              </div>
                              <h5 className="font-size-14 d-flex">
                                <span className="fw-bold">Question : </span>{" "}
                                {type?.match(/table/gi) ||
                                type?.match(/form/gi) ||
                                type?.match(/note/gi) ? (
                                  `${key + 1} Field`
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: data?.q,
                                    }}
                                  ></span>
                                )}
                              </h5>
                              <p className="text-muted">
                                <span className="fw-bold">Answer : </span>
                                {data.answer?.map((data, idx) => (
                                  <span key={idx}>{data}</span>
                                ))}
                              </p>
                              <p className="text-muted">
                                <span className="fw-bold">Explanation:</span>
                                {data.explanation}
                              </p>
                            </div>
                          </div>
                        </li>
                        <hr />
                      </div>
                    ))}
                  </ul>
                </React.Fragment>
              ) : null}
              {/* {loading ? (
                <div className="text-center pt-5">
                  <Loader />
                </div>
              ) : Object.keys(findData).length > 0 ? (
                <ul className="list-group">
                  {(findData?.Que?.question || []).map((data, key) => (
                    <div key={key}>
                      <li className="list-group-item border-0">
                        <div className="d-flex">
                          <div className="avatar-xs me-3">
                            <span className="avatar-title rounded-circle bg-light question_number">
                              <p className="text-dark p-2 mt-3">
                                {data?.questionNumber}
                              </p>
                            </span>
                          </div>
                          <div className="flex-grow-1 position-relative">
                            <div
                              className="position-absolute"
                              style={{ top: "0", right: "0" }}
                            >
                              <i
                                className="bx bxs-edit fs-5"
                                onClick={() =>
                                  history.push(
                                    `/reading-exam/${passageId}/${type}/${data._id}/${findData._id}`
                                  )
                                }
                              ></i>
                              <i className="bx bx-trash text-danger fs-5"></i>
                            </div>
                            <h5 className="font-size-14">
                              <span className="fw-bold">Question : </span>
                              {type === fillGapsOne ||
                              type === fillGapsTwo ||
                              type === fillGapsThree ||
                              type === fillGapsWithoutWords ||
                              type  === sortAnswerOne ||   type  === sortAnswerTwo ||   type  === sortAnswerThree
                              ? (
                                <span
                                  dangerouslySetInnerHTML={{ __html: data?.q }}
                                ></span>
                              ) : (
                                data.q
                              )}
                            </h5>
                            <p className="text-muted">
                              <span className="fw-bold">Answer : </span>
                              {data.answer?.map((data, idx) => (
                                <span key={idx}>{data}</span>
                              ))}
                            </p>
                            <p className="text-muted">
                              <span className="fw-bold">Explanation:</span>
                              {data.explanation}
                            </p>
                          </div>
                        </div>
                      </li>
                      <hr />
                    </div>
                  ))}
                </ul>
              ) : null} */}
              {!isDone && questionStatus[type]?.status !== 2 && !loading ? (
                <Link to={`/reading-exam/${passageId}/${type}`}>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary w-sm">
                      Add More Question
                    </button>
                  </div>
                </Link>
              ) : null}
              {/* <Row>
                <Col md="1">
                  <div className="question_number d-flex justify-content-center align-items-start">
                    1
                  </div>
                </Col>
                <Col md="11"></Col>
              </Row> */}
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(PreviewQuestion)
