import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getSingleTeacherFail,
  getSingleTeacherSuccess,
  getTeachersFail,
  getTeachersSuccess,
  registerTeacherFail,
  registerTeacherSuccess,
} from "./actions"
import {
  GET_SINGLE_TEACHER,
  GET_TEACHERS,
  REGISTER_TEACHER,
} from "./actionTypes"

function* workerTeacherRegister({
  payload: {
    values,
    options: { history, authToken, id },
  },
}) {
  try {
    const url = id ? `/teacher/update/${id}` : `/teacher/account-register`
    const response = yield call(
      id ? patchData : postData,
      url,
      values,
      authToken
    )
    if (response.status == "success") {
      yield put(registerTeacherSuccess(response.message))
      toaster("success", response?.message)
      history.push("/teachers-list")
    }
  } catch (error) {
    const message = error.response?.data?.message || "Get teacher failed"
    toaster("error", message)
    yield put(registerTeacherFail(message))
  }
}

//teachers list
function* workerGetTeachers({ payload: { authtoken } }) {
  try {
    const url = "/admin/get-teachers"
    const response = yield call(getData, url, authtoken)
    yield put(getTeachersSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "Get teacher failed"
    toaster("error", message)
    yield put(getTeachersFail(message))
  }
}

function* workerGetSingleTeachers({ payload: { authtoken, teacherId } }) {
  try {
    const url = `/admin/view-single-teacher/${teacherId}`
    const response = yield call(getData, url, authtoken)
    yield put(getSingleTeacherSuccess(response.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single teacher failed"
    toaster("error", message)
    yield put(getSingleTeacherFail(message))
  }
}

function* teacherSaga() {
  yield takeEvery(REGISTER_TEACHER, workerTeacherRegister)
  yield takeEvery(GET_TEACHERS, workerGetTeachers)
  yield takeEvery(GET_SINGLE_TEACHER, workerGetSingleTeachers)
}

export default teacherSaga
