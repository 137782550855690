import { getData, patchData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
//Include Both Helper File with needed methods
import {
  getDashboardProfileFail,
  getDashboardProfileSuccess,
  getProfile,
  getProfileFail,
  getProfileSuccess,
  profileError,
  profileSuccess,
} from "./actions"
// Login Redux States
import { EDIT_PROFILE, GET_DASHBOARD_PROFILE, GET_PROFILE } from "./actionTypes"

function* editProfile({ payload: { user, token } }) {
  try {
    const url = "/update-profile"
    const response = yield call(patchData, url, user, token)
    toaster("success", "Profile update successful")
    yield put(profileSuccess(response))
    yield put(getProfile(token))
  } catch (err) {
    const message = err?.response?.data?.message
    toaster("error", message)
    yield put(profileError(message))
  }
}

function* onGetProfile({ payload: { token } }) {
  try {
    const url = "/get-me"
    const response = yield call(getData, url, token)
    yield put(getProfileSuccess(response))
    // toaster('success')
  } catch (err) {
    const message = err?.response?.data?.message
    toaster("error", message)
    yield put(getProfileFail(message))
  }
}

function* onGetDashboardProfile({ payload: { token } }) {
  try {
    const url = "/order/get-user-own-package"
    const response = yield call(getData, url, token)
    console.log(`response`, response)
    yield put(getDashboardProfileSuccess(response?.getData))
    // toaster('success')
  } catch (err) {
    const message = err?.response?.data?.message
    toaster("error", message)
    yield put(getDashboardProfileFail(message))
  }
}

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, onGetProfile)
  yield takeEvery(GET_DASHBOARD_PROFILE, onGetDashboardProfile)
}

export default ProfileSaga
