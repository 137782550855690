import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import CheckBox from "components/InputComponent/CheckBox"
import SelectField from "components/InputComponent/CustomSelect"
import TextError from "components/InputComponent/TextError"
import { createBlogSchema } from "components/Schemas/createBlogSchema"
import { createPackageSchema } from "components/Schemas/createPackageSchema"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import { image, imageBaseUrl, imageUrl } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import Upload from "pages/Package/Upload"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Button, Card, Col, Row } from "reactstrap"
import {
    addBlog,
  addPackage,
  getAuthorList,
  getService,
  getSinglePackage,
  getSinglePackageSuccess,
} from "store/actions"
import { getCourseType } from "store/CourseType/actions"

const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Package List",
    link: "/packages",
  },
  {
    title: "Create Package",
    link: "#",
  },
]

const breadcrumbItems2 = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Package List",
    link: "/packages",
  },
  {
    title: "Edit Package",
    link: "#",
  },
]

const initialValues = {
  postTitle: "",
  author: "",

  postDescription: [""],
}

const CreateBlog = ({ history, edit }) => {
  const [listValue, setListValue] = useState(null)
  const [fileError, setFileError] = useState("")
  const dispatch = useDispatch()

  const [selectedFiles, setSelectedFiles] = useState([])
  const [imageUp, setImageUp] = useState("")
  
 
  const {
    authToken,

    authorList,

    adding,
    authorLoading,
  } = useSelector(state => ({
    authToken: state.Login.token,
    authorList: state.BlogReducer.authorList,
    authorLoading: state.BlogReducer.authorLoading,
    adding: state.BlogReducer.adding,
  }))

  useEffect(() => {
    dispatch(getAuthorList(authToken))
  }, [])

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setFileError("")
    }
  }, [selectedFiles])

  const onSubmit = values => {
    if (selectedFiles.length === 0) {
        return setFileError("Please select a image")
      }
    
    if(imageUp.length>0){
        values.photo = imageUp
    }
    console.log("values", values)
    dispatch(addBlog(values, authToken, history))
  }

  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return ""
  }
  const handlePhoto = async file => {
    console.log("file", file)
    try {
      const url = `/image-upload`
      const formData = new FormData()
      formData.append("photo", file)
      const response = await image(url, formData, authToken)
      console.log("response", response)
      setImageUp(response?.photo)
    } catch (err) {
      toaster("error", "Upload Image failed")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Edit Blog post" : "Create Blog Post"}
        breadcrumbItems={edit ? breadcrumbItems2 : breadcrumbItems}
      >
        <Row className="mt-3">
          <CardComponent>
            <h5>{edit ? "Edit" : "Create"} Package</h5>

            <Formik
              initialValues={listValue || initialValues}
              validationSchema={createBlogSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ values, errors, setFieldValue }) => (
                <Form className="mt-2">
                  {console.log("values.", values)}
                  <Row className="mb-3">
                    <Col md="3">Post Title</Col>
                    <Col md="9">
                      <Field
                        name="postTitle"
                        type="text"
                        component={CustomInput}
                        placeholder="Type  title"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md="3">Author name</Col>
                    <Col md="9">
                      {authorLoading ? (
                        <Loader />
                      ) : (
                        <Field
                          name="author"
                          type="select"
                          component={CustomInput}
                          placeholder="Select author name"
                          options={authorLoading ? authorList : []}
                          defaultText="Select author name"
                          disabled={edit}
                        >
                          <option value="">Select author name</option>
                          {authorList?.length > 0
                            ? authorList?.map((option, idx) => (
                                <option key={idx} value={option?._id}>
                                  {option?.authorName}
                                </option>
                              ))
                            : null}
                        </Field>
                      )}
                    </Col>
                  </Row>

                 
                  <Row className="mb-3">
                    <Col md="3">Description</Col>
                    <Col md="9">
                      <FieldArray
                        name="postDescription"
                        render={arrayHelpers => (
                          <div>
                            {values?.postDescription?.map((value, index) => (
                              <div
                                key={index}
                                className="mb-2 position-relative"
                              >
                                <Field
                                  name={`postDescription.${index}`}
                                  type={"textarea"}
                                  component={CustomInput}
                                  placeholder="Type postDescription"
                                />
                                {index > 0 ? (
                                  <div
                                    className="position-absolute h-100"
                                    style={{
                                      top: "50%",
                                      right: "0",
                                      transform: "translateY(-45%)",
                                    }}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Button
                                      className="btn-sm removeShadow"
                                      style={{
                                        background: "transparent",
                                        border: "none",
                                        fontSize: "18px",
                                      }}
                                    >
                                      <i
                                        className="bx bx-trash"
                                        style={{ color: "#999" }}
                                      ></i>
                                    </Button>
                                  </div>
                                ) : null}
                                <ErrorMessage
                                  name={`postDescription.${index}`}
                                  component={TextError}
                                />
                              </div>
                            ))}
                            <Button
                              type="button"
                              color="primary"
                              style={{
                                background: "transparent",
                                color: "#485ec4",
                                border: "1px solid #485ec4",
                              }}
                              onClick={() => arrayHelpers.push("")}
                            >
                              + Add more
                            </Button>
                          </div>
                        )}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md="3">Cover Image</Col>
                    <Col md="9">
                      <Upload
                        uploadMessage={
                          selectedFiles.length > 0 || edit
                            ? "Drop files here or click to change."
                            : "Drop files here or click to upload."
                        }
                        setSelectedFiles={setSelectedFiles}
                        onDropCallBack={(files, values) => {
                            console.log(files, "zndncj mncsanc jsnc");
                          // console.log(`UploadContent ~ file`, files);
                          if (files.length > 0) {
                            handlePhoto(files?.[0])
                          }
                        }}
                      />
                      {selectedFiles?.length === 0 && fileError !== "" ? (
                        <TextError>{fileError}</TextError>
                      ) : null}
                      <Row>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles?.length
                            ? selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })
                            : null}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <Button color="primary" disabled={adding} type="submit">
                        {adding
                          ? "Submitting..."
                          : edit
                          ? "Update Blog"
                          : "Add Blog"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CreateBlog)
