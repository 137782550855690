import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import { trueFalseSchema } from "components/Schemas/QuestionSchemas"
import { Field, Form, Formik } from "formik"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSReadingQuesMCQ,
  getIELTSReadingQuesMCQ,
  getIELTSReadingQuesNum,
} from "store/actions"

const initialValues = {
  q: "",
  answer: "",
  explanation: "",
}

const data = ["True", "False", "NotGiven"]

const TrueFalse = ({ history, edit }) => {
  const { serviceId, setId, passageId, questionId, id } = useParams()
  console.log(`🦄 ~ file: MCQ.js ~ line 24 ~ MCQ ~ passageID`, passageId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const dispatch = useDispatch()
  const { authToken, loading, adding, questionNumber, singleQuestion } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.QuesReducer.loading,
      adding: state.QuesReducer.adding,
      services: state.serviceReducer.services,
      questionNumber: state.QuesReducer.questionNumber,
      singleQuestion: state.QuesReducer.singleQuestion,
    }))
  // console.log(
  //   `🦄 ~ file: Multiple.js ~ line 34 ~ Multiple ~ singleQuestion`,
  //   singleQuestion,
  //   questionNumber
  // )

  useEffect(() => {
    if (edit && !!singleQuestion) {
      const value = { ...listValue }
      value.q = singleQuestion?.q
      value.opt = singleQuestion?.opt
      value.answer = singleQuestion?.answer?.[0]
      value.explanation = singleQuestion?.explanation
      setListValue(value)
      // setQuestionNumber(singleQuestion?.questionNumber)
    }
  }, [singleQuestion])

  useEffect(() => {
    const type = location.pathname.split("/").pop()
    dispatch(getIELTSReadingQuesNum(authToken, passageId, type))
    if (edit) {
      const data = {
        type,
        objectId: id,
        questionId,
      }
      dispatch(getIELTSReadingQuesMCQ(authToken, passageId, data))
    }
  }, [])

  const onSubmit = (values, { resetForm }) => {
    const { answer, ...rest } = values
    const type = location.pathname.split("/").pop()
    const obj = {
      ...rest,
      answer: [answer],
      readingPessage: passageId,
      questionSerial: questionNumber,
      questype: type,
    }
    const options = {
      isMore: isAddMore,
      resetForm,
      setIsAddMore,
      questionId,
      type,
      passageId,
    }
    if (edit) {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, options))
    } else {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, options))
    }
    console.log(`🦄 ~ file: MCQ.js ~ line 44 ~ MCQ ~ values`, obj, options)
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={false}
        title="Create True, False & Not given Question"
      >
        <Row className="mt-3">
          <Col md="10" className="offset-md-1">
            <CardComponent className={"mb-0"}>
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-left-arrow-alt me-2"
                  onClick={() => history.goBack()}
                  style={{ fontSize: "18px" }}
                ></i>
                <h6 className="mb-0">Create True, False & Not given</h6>
              </div>
            </CardComponent>
            <CardComponent style={{ marginTop: "5px" }}>
              <Row>
                <Col md="1">
                  <div className="question_number d-flex justify-content-center align-items-start">
                    {questionNumber}
                  </div>
                </Col>
                <Col md="11">
                  <h5>Type 1no. question</h5>
                  <br />
                  {loading && edit ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <Formik
                      initialValues={listValue || initialValues}
                      validationSchema={trueFalseSchema}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                    >
                      {({ values, errors }) => (
                        <Form>
                          {console.log(errors)}
                          <Row className="mb-3">
                            <Col md="6">
                              <Field
                                label="Question Title"
                                name="q"
                                type="text"
                                id={"question"}
                                component={CustomInput}
                                placeholder="Type paragraph title"
                              />
                            </Col>
                            <Col md="6">
                              <Field
                                name="answer"
                                id={"answer"}
                                label="Select Right answer"
                                type="select"
                                component={CustomInput}
                              >
                                <option value="">Select Right answer</option>
                                {data?.map((option, idx) => (
                                  <option key={idx} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </Field>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="explanation"
                                id={"Explanation"}
                                label="Explanation"
                                placeholder="Type explanation"
                                type="textarea"
                                rows={"5"}
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12" className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="me-2"
                                disabled={adding}
                                type="submit"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                              >
                                {adding
                                  ? "Submitting..."
                                  : edit
                                  ? "Update"
                                  : "Save"}
                              </Button>
                              {!edit ? (
                                <Button
                                  color="primary"
                                  disabled={adding}
                                  type="submit"
                                >
                                  {adding
                                    ? "Submitting..."
                                    : "Create more question"}
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Col>
              </Row>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TrueFalse)
