import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addReviewFail,
  addReviewSuccess,
  getAllAnswerFail,
  getAllAnswerSuccess,
  getParticularSetFail,
  getParticularSetSuccess,
  getReviewExamFail,
  getReviewExamSuccess,
  getReviewExam,
  removeReviewExamSuccess,
  removeReviewExamFail,
  getParticularSet,
  adminCheckGrammarFail,
  adminCheckGrammarSuccess,
} from "./actions"
//for main
import {
  ADD_REVIEW,
  CHECK_GRAMMAR,
  GET_ALL_ANSWER,
  GET_PARTICULAR_SET,
  GET_REVIEW_EXAM,
  REMOVE_REVIEW_EXAM,
} from "./actionTypes"

function* getAllAnswer({ payload: { token, type } }) {
  try {
    const url = `/package/writting/get-submitted-answer-by-admin`
    const response = yield call(getData, url, token)
    yield put(getAllAnswerSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Load answer failed"
    yield put(getAllAnswerFail(message))
    toaster("error", message)
  }
}

function* onGetParticularSet({
  payload: {
    token,
    options: { studentId, setId },
  },
}) {
  try {
    const url = `/package/writting/get-answer-of/${studentId}/in/${setId}`
    const response = yield call(postData, url, {}, token)
    console.log(` response`, response)
    yield put(getParticularSetSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Load particular set failed"
    yield put(getParticularSetFail(message))
    toaster("error", message)
  }
}

function* onAddReview({
  payload: {
    values,
    options: { authToken, studentId, setId, questionId, history },
  },
}) {
  try {
    const url = `/package/writting/admin/submit-mark-of/${studentId}/in/${setId}/${questionId}`
    getParticularSet
    const response = yield call(patchData, url, values, authToken)
    yield put(addReviewSuccess(response))
    yield put(getParticularSet(authToken, { studentId, setId }))
    toaster("success", `Review added successfully!`)
    // history.goBack()
  } catch (error) {
    const message = error?.response?.data?.message || `Add review failed!`
    yield put(addReviewFail(message))
    toaster("error", message)
  }
}

function* onGetReviewExam({
  payload: {
    token,
    options: { userEmail, examName },
  },
}) {
  try {
    const url = `/package/admin-view-user-exam-data/${userEmail}/${examName}`
    const response = yield call(getData, url, token)
    response?.findUser?.forEach(element => {
      element.view = false
    })
    yield put(getReviewExamSuccess(response?.findUser))
  } catch (error) {
    const message = error?.response?.data?.message || `Get Exam failed!`
    yield put(getReviewExamFail(message))
    toaster("error", message)
  }
}

function* onRemoveReview({
  payload: {
    token,
    options: { answerId, examName, email },
  },
}) {
  try {
    const url = `/package/admin-delete-exam-data/${answerId}/${examName}`
    const response = yield call(patchData, url, {}, token)
    yield put(removeReviewExamSuccess(response))
    toaster("success", `Answer Removed successfully!`)
    yield put(
      getReviewExam(token, {
        userEmail: email,
        examName: examName,
      })
    )
  } catch (error) {
    const message = error?.response?.data?.message || `Answer Removed failed!`
    // console.log(`🦄 ~ file: saga.js ~ line 115 ~ message`, message)
    toaster("error", message)
    yield put(removeReviewExamFail(message))
  }
}
function* onAddGrammar({
  payload: { authToken, body, setId, studentId, serial, history },
}) {
  try {
    const url = `package/writing/admin/check-grammar-spelling/${studentId}/${setId}/${serial}`
    getParticularSet
    const response = yield call(patchData, url, body, authToken)
    yield put(adminCheckGrammarSuccess(response))
    yield put(getParticularSet(authToken, { studentId, setId }))
    toaster("success", `Grammar reviewed successfully!`)
    // history.goBack()
  } catch (error) {
    const message = error?.response?.data?.message || `Grammar review failed!`
    yield put(adminCheckGrammarFail(message))
    toaster("error", message)
  }
}

function* ReviewAnswerSaga() {
  yield takeEvery(ADD_REVIEW, onAddReview)
  yield takeEvery(GET_ALL_ANSWER, getAllAnswer)
  yield takeEvery(GET_PARTICULAR_SET, onGetParticularSet)
  yield takeEvery(GET_REVIEW_EXAM, onGetReviewExam)
  yield takeEvery(REMOVE_REVIEW_EXAM, onRemoveReview)
  yield takeEvery(CHECK_GRAMMAR, onAddGrammar)
}
export default ReviewAnswerSaga
