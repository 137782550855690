import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { imageBaseUrl, writingImageUrl } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect } from "react"
import { Accordion, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { addReview, adminCheckGrammar, getParticularSet } from "store/ReviewAnswer/actions"
import quesImage from "./img/130.png"
import "./review-answer.scss"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Review Answer",
    link: "/review-answer",
  },
  {
    title: `Writing Review`,
    link: "/review-answer/writing",
  },
  {
    title: `Result`,
    link: "#",
  },
]

const ReviewAnswerList = ({ history }) => {
  const { studentId, setId } = useParams()
  const dispatch = useDispatch()
  // const useSelector = useSelector();
  const { authToken, loading, particularSet, adding } = useSelector(state => ({
    allAnswer: state.review.allAnswer,
    authToken: state.Login.token,
    particularSet: state.review.particularSet,
    loading: state.review.loading,
    adding: state.review.adding,
  }))

  console.log("Review", particularSet)

  useEffect(() => {
    dispatch(getParticularSet(authToken, { studentId, setId }))
  }, [])
  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      return
    }
    if(values.grammar<0){
      return toaster(
        "warning",
        "Grammar value can not be negative."
      );
    }
    if(values.spelling<0){
      return toaster(
        "warning",
        "Spelling value can not be negative."
      );
    }
    if(values.spelling<0){
      return toaster(
        "warning",
        "Spelling value can not be negative."
      );
    }
    if(values.adminMark<0){
      return toaster(
        "warning",
        "Admin evaluation value can not be negative."
      );
    }
    if(values.taskAchievement<0){
      return toaster(
        "warning",
        "Task achievement value can not be negative."
      );
    }
    if(values.coherence<0){
      return toaster(
        "warning",
        "Coherence value can not be negative."
      );
    }
    const id = particularSet?.questions?.[0]?._id
    // console.log(`53 ~ handleSubmit ~ id`, id, values)
    const body = {
      answer: particularSet?.answer?.[0]?.answer,
      photo: particularSet?.answer?.[0]?.photo,
      adminMark: values.adminMark,
      taskAchievement: values.taskAchievement,
      coherence: values.coherence,
      serial: 1,
      underlength: particularSet?.answer?.[0]?.answer ? (values.underlength *1) : 0,
      grammar: values.grammar,
      spelling: values.spelling,
      check: true
    }
    console.log("body", body)
    dispatch(
      addReview(body, {
        authToken,
        setId,
        studentId,
        questionId: id,
        history,
      })
    )
  }

  const handleSubmit1 = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      return
    }
    if(values.grammar<0){
      return toaster(
        "warning",
        "Grammar value can not be negative."
      );
    }
    if(values.spelling<0){
      return toaster(
        "warning",
        "Spelling value can not be negative."
      );
    }
    if(values.spelling<0){
      return toaster(
        "warning",
        "Spelling value can not be negative."
      );
    }
    if(values.adminMark<0){
      return toaster(
        "warning",
        "Admin evaluation value can not be negative."
      );
    }
    if(values.taskAchievement<0){
      return toaster(
        "warning",
        "Task achievement value can not be negative."
      );
    }
    if(values.coherence<0){
      return toaster(
        "warning",
        "Coherence value can not be negative."
      );
    }
    const id = particularSet?.questions?.[1]?._id
    // console.log(`57 ~ handleSubmit1 ~ id`, id, values)
    const body = {
      answer: particularSet?.answer?.[1]?.answer,
      photo: particularSet?.answer?.[1]?.photo,
      adminMark: values.adminMark,
      taskAchievement: values.taskAchievement,
      coherence: values.coherence,
      serial: 2,
      underlength: particularSet?.answer?.[1]?.answer ? (values.underlength *1) : 0,
      grammar: values.grammar,
      spelling: values.spelling,
      check: true
    }

    dispatch(
      addReview(body, {
        authToken,
        setId,
        studentId,
        questionId: id,
        history,
      })
    )
  }

  function countWords(str) {
    console.log('str', str);
    if (str?.length === 0) {
      return 0
    }
    return str?.trim()?.split(/\s+/).length
  }
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return quesImage
  }
  const getPhotoUrl1 = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return ""
  }
  const task1GrammarCheck = () => {
    let body = {
      answer: particularSet?.answer?.[0]?.answer,
    }
    console.log("hello")
    dispatch(adminCheckGrammar(authToken, body, setId, studentId, 1, history))
  }
  const task2GrammarCheck = () => {
    let body = {
      answer: particularSet?.answer?.[1]?.answer || '',
    }
    console.log("hello")
    dispatch(adminCheckGrammar(authToken, body, setId, studentId, 2, history))
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Review Answer"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <div className="back-arrow-icon-content-wrap">
                <h4>
                  <span
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bx bx-arrow-back"></i>
                  </span>{" "}
                  Result
                </h4>
              </div>
            </div>
          </CardComponent>
        </Row>
        <div className="ques-answer-list-review-main-wrap">
          <div className="ques-answer-list-review-inner-wrap">
            {loading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="single-ques-answer-list">
                <Accordion
                  defaultActiveKey={["0", "1", "2", "3", "4"]}
                  alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="ques-review-header">
                        <h5>Writing Task 1 : Question</h5>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="ques-review-details">
                        {/* {particularSet?.questions?.[0]?.q} */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: particularSet?.questions?.[0]?.q,
                          }}
                        ></p>
                        {particularSet?.questions?.[0]?.image ? (
                          <div className=" my-5 text-center">
                            <img
                              src={getPhotoUrl(
                                particularSet?.questions?.[0]?.image
                              )}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        ) : null}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="ques-review-header">
                        <h5>Writing Task 1 : Answer</h5>
                      </div>
                      <div className="ms-3">
                        <button
                          className="btn btn-primary"
                          onClick={task1GrammarCheck}
                          disabled={adding || particularSet?.answer?.[0]?.check ===true}
                        >
                          {adding ? "Submitting" : "Check Grammar"}
                        </button>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="ques-review-details">
                        {particularSet?.answer?.[0]?.answer ? (
                          <p>{particularSet?.answer?.[0]?.answer}</p>
                        ) : (
                          <div>
                            <img
                              src={getPhotoUrl1(
                                particularSet?.answer?.[0]?.photo
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="ques-review-header">
                        <h5>Writing Task 1 : Result</h5>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="ques-review-details">
                        <AvForm onSubmit={handleSubmit}>
                          <div className="ques-review-answer-result">
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">Grammar</div>
                              <div className="result-answer-dec">
                                {/* <input
                                type="number"
                                value={particularSet?.answer?.[0]?.grammar}
                                readOnly
                                placeholder="8"
                              /> */}
                                <AvField
                                  name="grammar"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 1 ||
                                    particularSet?.status === 2
                                  }
                                  // readOnly={!particularSet?.answer?.[0]?.photo}
                                  value={
                                    particularSet?.answer?.[0]?.grammar || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                  
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Spelling
                              </div>
                              <div className="result-answer-dec">
                                {/* <input
                                type="number"
                                value={particularSet?.answer?.[0]?.spelling}
                                readOnly
                                placeholder="8.5"
                              /> */}
                                <AvField
                                  name="spelling"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 1 ||
                                    particularSet?.status === 2
                                  }
                                  // readOnly={!particularSet?.answer?.[0]?.photo}
                                  value={
                                    particularSet?.answer?.[0]?.spelling || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Admin Evaluation
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="adminMark"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 1 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[0]?.adminMark || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Task Achievement
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="taskAchievement"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 1 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[0]
                                      ?.taskAchievement || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Coherence
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="coherence"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 1 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[0]?.coherence || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Under Length
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="underlength"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly
                                  value={
                                    
                                    // parseInt(countWords(
                                    //   particularSet?.answer?.[0]?.answer
                                    // )) >= 150
                                    //   ? 0
                                    //   : 1
                                    particularSet?.answer?.[0]?.underlength || "0"
                                  }
                                 
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            {/* <div className="ques-review-answer-single-result">
                            <div className="result-answer-title">
                              Total Mark
                            </div>
                            <div className="result-answer-dec">
                              <input
                                type="number"
                                readOnly
                                placeholder="Type Mark"
                              />
                            </div>
                          </div> */}
                            <div className="ques-review-answer-single-result">
                              {particularSet?.status === 1 ||
                              particularSet?.status === 2 ? null : (
                                <button
                                  disabled={
                                    particularSet?.status === 1 ||
                                    particularSet?.status === 2 ||
                                    adding
                                  }
                                  type="submit"
                                  className="teacher-mark-review-and-submit"
                                >
                                  {adding ? "Submitting..." : "Submit"}
                                </button>
                              )}
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <hr className="my-5" />
                <Accordion
                  defaultActiveKey={["0", "1", "2", "3", "4"]}
                  alwaysOpen
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="ques-review-header">
                        <h5>Writing Task 2 : Question</h5>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="ques-review-details">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: particularSet?.questions?.[1]?.q,
                          }}
                        ></p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="ques-review-header">
                        <h5>Writing Task 2 : Answer</h5>
                      </div>
                      <div className="ms-3">
                        <button
                          className="btn btn-primary "
                          onClick={task2GrammarCheck}
                          disabled={adding|| particularSet?.answer?.[1]?.check ===true }
                        >
                          {adding ? "Submitting" : "Check Grammar"}
                        </button>
                      </div>

                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="ques-review-details">
                        {particularSet?.answer?.[1]?.answer ? (
                          <p>{particularSet?.answer?.[1]?.answer}</p>
                        ) : (
                          <div>
                            <img
                              src={getPhotoUrl1(
                                particularSet?.answer?.[1]?.photo
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="ques-review-header">
                        <h5>Writing Task 2 : Result</h5>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="ques-review-details">
                        <AvForm onSubmit={handleSubmit1}>
                          <div className="ques-review-answer-result">
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">Grammar</div>
                              <div className="result-answer-dec">
                                {/* <input
                                type="number"
                                value={particularSet?.answer?.[1]?.grammar}
                                readOnly
                                placeholder="8"
                              /> */}
                                <AvField
                                  name="grammar"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                   
                                    particularSet?.status === 0 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[1]?.grammar || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Spelling
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="spelling"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    
                                    particularSet?.status === 0 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[1]?.spelling || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                                {/* <input
                                type="number"
                                value={particularSet?.answer?.[1]?.spelling}
                                readOnly
                                placeholder="8.5"
                              /> */}
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Admin Evaluation
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="adminMark"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 0 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[1]?.adminMark || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Task Achievement
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="taskAchievement"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 0 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[1]
                                      ?.taskAchievement || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Coherence
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="coherence"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly={
                                    particularSet?.status === 0 ||
                                    particularSet?.status === 2
                                  }
                                  value={
                                    particularSet?.answer?.[1]?.coherence || ""
                                  }
                                  validate={{
                                    required: { value: true },
                                    min: {
                                      value: 0,
                                      errorMessage: "Minimum value is 0",
                                    },
                                    max: {
                                      value: 9,
                                      errorMessage: "Maximum value is 9",
                                    },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            <div className="ques-review-answer-single-result">
                              <div className="result-answer-title">
                                Under Length
                              </div>
                              <div className="result-answer-dec">
                                <AvField
                                  name="underlength"
                                  type="number"
                                  errorMessage="Type Mark"
                                  placeholder="Type Mark"
                                  readOnly
                                  value={
                                    particularSet?.answer?.[1]?.underlength || "0"
                                    // countWords(
                                    //   particularSet?.answer?.[1]?.answer
                                    // ) >= 150
                                    //   ? 0
                                    //   : 1
                                    // particularSet?.answer?.[1]?.underlength || "0"
                                  }
                                  // value={
                                  //   particularSet?.answer?.[1]?.underlength || ""
                                  // }
                                  validate={{
                                    required: { value: true },
                                  }}
                                />
                              </div>
                            </div>{" "}
                            {/* <div className="ques-review-answer-single-result">
                            <div className="result-answer-title">
                              Total Mark
                            </div>
                            <div className="result-answer-dec">
                              <input
                                type="number"
                                readOnly
                                placeholder="Type Mark"
                              />
                            </div>
                          </div> */}
                            <div className="ques-review-answer-single-result">
                              {particularSet?.status === 0 ||
                              particularSet?.status === 2 ? null : (
                                <button
                                  disabled={
                                    particularSet?.status === 0 ||
                                    particularSet?.status === 2 ||
                                    adding
                                  }
                                  className="teacher-mark-review-and-submit"
                                  // onClick={() =>
                                  //   handleSubmit(
                                  //     particularSet?.questions?.[1]?._id
                                  //   )
                                  // }
                                  type="submit"
                                >
                                  {adding ? "Submitting..." : "Submit"}
                                </button>
                              )}
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
          </div>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(ReviewAnswerList)
