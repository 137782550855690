import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addWritingSetFail,
  addWritingSetSuccess,
  getSingleWritingSetsFail,
  getSingleWritingSetsNumberSuccess,
  getSingleWritingSetsSuccess,
  getWritingSetsFail,
  getWritingSetsSuccess,
} from "./actions"
//for main
import {
  ADD_WRITTING_SET,
  ADD_WRITTING_SETS_QUESTION,
  GET_WRITTING_SETS,
  GET_WRITTING_SET_SINGLE,
} from "./actionTypes"

function* workerGetWritingSets({ payload: { token, serviceid } }) {
  try {
    const url = `/writtingset/${serviceid}`
    const response = yield call(getData, url, token)
    if (response.length > 0) {
      yield put(getWritingSetsSuccess(response.data))
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Set fail"
    toaster("error", message)
    yield put(getWritingSetsFail(message))
  }
}

function* workerGetSingleWritingSets({
  payload: {
    token,
    options: { serviceId, setId, isNumber },
  },
}) {
  try {
    const url = `/writtingset/${serviceId}/get-single-writting-set/${setId}`
    const response = yield call(getData, url, token)
    if (isNumber) {
      const ques = `/writting-question/${setId}/get-serial`
      const res = yield call(getData, ques, token)
      yield put(getSingleWritingSetsNumberSuccess(res?.length))
    }
    console.log(` response`, response)
    yield put(getSingleWritingSetsSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get single set fail"
    toaster("error", message)
    yield put(getSingleWritingSetsFail(message))
  }
}

function* workerAddWritingSet({
  payload: {
    values,
    options: { history, token, serviceId, setId },
  },
}) {
  try {
    const url = setId
      ? `/writtingset/${serviceId}/update-single-writting-set/${setId}`
      : `/writtingset/${serviceId}/create-writting-set`
    const response = yield call(
      setId ? patchData : postData,
      url,
      values,
      token
    )
    yield put(addWritingSetSuccess(response.message))
    toaster(
      "success",
      response.message || `${setId ? "Update" : "Add"} Successful`
    )
    history.push(`/writing-set/${serviceId}`)
  } catch (error) {
    const message =
      error?.response?.data?.message || `${setId ? "Update" : "Add"} Set fail`
    toaster("error", message)
    yield put(addWritingSetFail(message))
  }
}

function* workerAddWritingSetQuestion({
  payload: {
    values,
    options: { history, token, serviceId, setId, edit },
  },
}) {
  try {
    const url = edit
      ? `/writtingset/${serviceId}/update-single-writting-set/${setId}`
      : `/writting-question/question-create`
    const response = yield call(edit ? patchData : postData, url, values, token)
    yield put(addWritingSetSuccess(response.message))
    toaster(
      "success",
      response.message || `${edit ? "Update" : "Add"} question Successful`
    )
    history.push(`/service/${serviceId}/writing/${setId}`)
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${edit ? "Update" : "Add"} question fail`
    toaster("error", message)
    yield put(addWritingSetFail(message))
  }
}

function* writingSaga() {
  yield takeEvery(ADD_WRITTING_SET, workerAddWritingSet)
  yield takeEvery(ADD_WRITTING_SETS_QUESTION, workerAddWritingSetQuestion)
  yield takeEvery(GET_WRITTING_SETS, workerGetWritingSets)
  yield takeEvery(GET_WRITTING_SET_SINGLE, workerGetSingleWritingSets)
}
export default writingSaga
