import { toaster } from 'helpers/Custom/Toaster';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getData, postData } from '../../helpers/backend_helper';

import { getResultFail, getResultSuccess } from './actions';
import { GET_RESULT } from './actionTypes';

function* fetchResults({ payload: { authtoken, url, type } }) {
  try {
    console.log('resultType', type);
    console.log('url', url);
    const response = type ==="writing" ? yield call(postData, url, {}, authtoken) : yield call(getData, url, authtoken);

    yield put(getResultSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || 'Load result failed';
    yield put(getResultFail(message));
    toaster('error', message);
  }
}

function* ResultSaga() {
  yield takeEvery(GET_RESULT, fetchResults);
}

export default ResultSaga;
