import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { findTime } from "helpers/Custom/timeHelper"
import InnerLayer from "Layout/InnerLayer"
import moment from "moment"
import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { getFacilitatorSingleDay } from "store/Facilitator/actions"
import { getSingleSpeakingSetsSingleDay } from "store/Speaking/actions"
import { getSingleTeacher } from "store/teacher/actions"
import clockIcon from "./img/clock.svg"
import profileIcon from "./img/Group.png"
import "./speaking.scss"
const time = {
  10: `10:00 - 10:59`,
  11: `11:00 - 11:59`,
  12: `12:00 - 12:59`,
  1: `01:00 - 01:59`,
  2: `02:00 - 02:59`,
  3: `03:00 - 03:59`,
  4: `04:00 - 04:59`,
  5: `05:00 - 05:59`,
  6: `06:00 - 06:59`,
  7: `07:00 - 07:59`,
};

const SingleFacilitatorSlot = ({ history }) => {
  const { teacherId, date } = useParams()
  console.log(`teacherId, date`, teacherId, date)
  const dispatch = useDispatch()
  const { authToken, teacher, FacilitatorSingleDay, role, loading } = useSelector(
    state => ({
      authToken: state.Login.token,
      role: state.Login.userrole,
      teacher: state.teacherReducer.teacher,
      loading: state.FacilitatorReducer.loading,
      FacilitatorSingleDay: state.FacilitatorReducer.FacilitatorSingleDay,
    })
  )
  console.log(`loading`, teacher, FacilitatorSingleDay)

 
  useEffect(() => {
    dispatch(getFacilitatorSingleDay(authToken, {teacherId, date, role }))
  }, [])
  // const months = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ]
  // const years = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ]
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Teacher List",
      link: `/teachers-list`,
    },
    {
      title: "List time slot",
      link: `/speaking/${teacherId}`,
    },
    {
      title: "View time slot",
      link: `#`,
    },
  ]

  return (
    <div>
      <InnerLayer
        isBreadCrumb={true}
        title=""
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Time Slot</h4>
            </div>
          </CardComponent>
        </Row>
        {/* <Row>
          <CardComponent></CardComponent>
        </Row> */}
        <Row>
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : FacilitatorSingleDay?.length > 0 ? (
            FacilitatorSingleDay.map((item, idx) => (
              <Col lg={4} className="mb-3" key={idx}>
                <div className="single-student-time-main-wrap">
                  <div className="single-exam-time-schedule">
                    <div className="single-exam-time-left-part">
                      <div className="single-exam-schedule-left-icon">
                        <img src={clockIcon} alt="" />
                      </div>
                      <div className="single-exam-schedule-left-content">
                        <h5>{time[item?.meetingTime]}</h5>
                        <p>
                          {moment(item?.date?.split("T")?.[0])?.format(
                            "DD MMM YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="single-exam-time-right-part">
                      <button
                        className="btn btn-sm"
                        onClick={() =>
                          window.location.replace(item?.teacherId?.meetLink)
                        }
                        disabled={
                          !item?.studentId ||
                          !findTime(item?.meetingTime, item, date)
                        }
                      >
                        Join
                      </button>
                    </div>{" "}
                  </div>{" "}
                  {item?.studentId ? (
                    <div
                      className="single-exam-time-schedule"
                      onClick={() =>
                        history.push(`/teacher-exam/result/${item?._id}`)
                      }
                    >
                      <div className="single-exam-time-left-part">
                        <div className="single-exam-schedule-left-icon">
                          <img src={profileIcon} alt="" />
                        </div>
                        <div className="single-exam-schedule-left-content">
                          <h5>{item?.studentId?.fullName}</h5>
                          <p>Details</p>
                        </div>
                      </div>
                      <div className="single-exam-time-right-part-student">
                        <a>
                          <i className="bx bx-chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="single-exam-time-schedule text-center">
                      <span className="text-center">No student admitted</span>
                    </div>
                  )}
                </div>
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h3>No data found</h3>
            </div>
          )}
        </Row>
      </InnerLayer>
    </div>
  )
}

export default withRouter(SingleFacilitatorSlot)
