import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import {
  tableSchema,
  tableSchema2,
} from "components/Schemas/listeningQuestionsSchema"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import { postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import InnerLayer from "Layout/InnerLayer"
import Upload from "pages/Package/Upload"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSListeningQuesMCQ,
  getIELTSListeningQuesMCQ,
  getIELTSListeningQuesNum,
} from "store/actions"

const initialValues = {
  answer: [""],
  explanation: "",
  questionDetails: "",
  answerType: "",
}

export const listType = [
  "No More Than Two Words",
  "No More Than Two Words And/Or A Number",
  "No More Than Three Words And/Or A Number",
  "One word and or two number",
]

const TableType = ({ edit, history }) => {
  const { passageId, questionId, id, type } = useParams()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const dispatch = useDispatch()
  const { authToken, loading, adding, questionNumber, singleQuestion, ques } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.listeningQuestion.loading,
      adding: state.listeningQuestion.adding,
      services: state.serviceReducer.services,
      questionNumber: state.listeningQuestion.questionNumber,
      singleQuestion: state.listeningQuestion.singleQuestion,
      ques: state.listeningQuestion.ques,
    }))
  const findData = ques?.find(dt => dt?.Que?.questype === type) || {}
  // console.log(`findData`, findData, ques)
  useEffect(() => {
    if (edit && !!singleQuestion) {
      const value = { ...listValue }
      value.q = singleQuestion?.q
      value.opt = singleQuestion?.opt
      value.answer = singleQuestion?.answer
      value.explanation = singleQuestion?.explanation
      setListValue(value)
    }
  }, [singleQuestion])

  useEffect(() => {
    dispatch(getIELTSListeningQuesNum(authToken, passageId, type))
    if (edit) {
      const data = {
        type,
        objectId: id,
        questionId,
      }
      dispatch(getIELTSListeningQuesMCQ(authToken, passageId, data))
    }
  }, [])

  const onSubmit = (values, { resetForm }) => {
    const { questionDetails, answerType, ...rest } = values
    const obj = {
      ...values,
      listeningAudio: passageId,
      questionSerial: questionNumber,
      questype: type,
    }
    const options = {
      isMore: isAddMore,
      resetForm,
      setIsAddMore,
      questionId,
      type,
      passageId,
    }
    if (edit) {
      dispatch(addIELTSListeningQuesMCQ(obj, history, authToken, options))
    } else {
      dispatch(addIELTSListeningQuesMCQ(obj, history, authToken, options))
    }
    // console.log(`🦄 ~ file: MCQ.js ~ line 44 ~ MCQ ~ values`, obj)
  }
  const handleUploadImage = async (file, setFieldValue) => {
    setSubmitting(true)
    const formData = new FormData()
    file?.length ? formData.append("photo", file?.[0]) : null
    try {
      const data = await postData("/image-upload", formData, authToken)
      // console.log(`postData`, data)
      if (data) {
        toaster("success", data?.message)
      }
      setSubmitting(false)
      setFieldValue("questionDetails", data?.photo)
    } catch (err) {
      setSubmitting(false)
      toaster("error", err?.response?.data?.message || `Upload image failed`)
    }
  }
  return (
    <React.Fragment>
      <InnerLayer isBreadCrumb={false} title="Create question">
        {loading && edit ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          <Formik
            initialValues={listValue || initialValues}
            validationSchema={findData?.Que ? tableSchema2 : tableSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <Row className="mt-3">
                  <Col md="10" className="offset-md-1">
                    <CardComponent className={"mb-0"}>
                      <div className="d-flex align-items-center">
                        <i
                          className="bx bx-left-arrow-alt me-2"
                          onClick={() => history.goBack()}
                          style={{ fontSize: "18px" }}
                        ></i>
                        <h6 className="mb-0">Create {toCapitalize(type)}</h6>
                      </div>
                    </CardComponent>
                    <CardComponent style={{ marginTop: "5px" }}>
                      {findData?.Que ? null : (
                        <React.Fragment>
                          <Row className="mb-3">
                            <Col md="12">
                              <Upload
                                setSelectedFiles={setSelectedFiles}
                                isMultiple={false}
                                disabled={submitting}
                                onDropCallBack={file => {
                                  if (file?.length > 0) {
                                    handleUploadImage(file, setFieldValue)
                                  }
                                }}
                              >
                                <div>
                                  <i
                                    className={
                                      submitting
                                        ? "display-5 text-muted bx bx-loader bx-spin"
                                        : "display-5 text-muted bx bx-image"
                                    }
                                  ></i>
                                </div>
                                <h6>
                                  {submitting
                                    ? "Uploading..."
                                    : "Drop files here or click to upload."}
                                </h6>
                              </Upload>
                              <ErrorMessage
                                name="questionDetails"
                                component={TextError}
                              />
                              {values?.questionDetails ? (
                                <img
                                  className="my-2"
                                  src={values?.questionDetails}
                                  alt="img"
                                  width={"100px"}
                                />
                              ) : null}
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="3">Select Condition</Col>
                            <Col md="9">
                              <Field
                                name="answerType"
                                placeholder="Select answer type"
                                type="select"
                                component={CustomInput}
                              >
                                <option selected value="">
                                  Select answer type
                                </option>
                                {listType &&
                                  listType.map((player, idx) => {
                                    return (
                                      <option key={idx} value={player}>
                                        {player}
                                      </option>
                                    )
                                  })}
                              </Field>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      <Row className={findData?.Que ? "mt-3" : "mt-5"}>
                        <Col md="1">
                          <div className="question_number d-flex justify-content-center align-items-start">
                            {questionNumber || 1}
                          </div>
                        </Col>
                        <Col md="11">
                          <h5>Type 1no. question</h5>
                          <br />
                          {console.log(errors, values)}
                          <Row className="mb-3">
                            <Col md="12">
                              <FieldArray
                                name="answer"
                                render={arrayHelpers => (
                                  <Row>
                                    {values?.answer?.map((value, index) => (
                                      <Col md="6" key={index}>
                                        <div className="mb-2 position-relative">
                                          <Field
                                            name={`answer.${index}`}
                                            type={"text"}
                                            id={index}
                                            label={`Right answer ${
                                              index > 0 ? index + 1 : ""
                                            }`}
                                            component={CustomInput}
                                            placeholder="Type Right answer"
                                          />
                                          {index > 0 ? (
                                            <div
                                              className="position-absolute"
                                              style={{ top: "0", right: "0" }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <Button
                                                className="btn-danger btn-sm "
                                                style={{
                                                  background: "transparent",
                                                  border: "none",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                <i
                                                  className="bx bx-trash"
                                                  style={{ color: "#999" }}
                                                ></i>
                                              </Button>
                                            </div>
                                          ) : null}
                                          <ErrorMessage
                                            name={`answer.${index}`}
                                            component={TextError}
                                          />
                                        </div>
                                      </Col>
                                    ))}
                                    <Col md="12">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="d-inline-block"
                                        style={{
                                          background: "#E5F0FF",
                                          color: "#485ec4",
                                          border: "none",
                                          width: "initial",
                                        }}
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        Add more answer
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="explanation"
                                id={"Explanation"}
                                label="Explanation"
                                placeholder="Type explanation"
                                type="textarea"
                                rows={"5"}
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12" className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="me-2"
                                disabled={adding}
                                type="submit"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                              >
                                {adding
                                  ? "Submitting..."
                                  : edit
                                  ? "Update"
                                  : "Save"}
                              </Button>
                              {!edit ? (
                                <Button
                                  color="primary"
                                  disabled={isAddMore}
                                  type="submit"
                                >
                                  {isAddMore
                                    ? "Submitting..."
                                    : "Create more question"}
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardComponent>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </InnerLayer>
    </React.Fragment>
  )
}

export default TableType
