import { getData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  getDashboardDataFail,
  getDashboardDataSuccess,
  getMonthBasedSellingDataSuccess,
  getNewStudentsSuccess,
  getPopularPackagesSuccess,
  getStudentEnrollmentDataSuccess,
} from "./actions"
// Crypto Redux States
import {
  GET_DASHBOARD_DATA,
  GET_MONTH_BASED_SELLING_DATA,
  GET_NEW_STUDENTS,
  GET_POPULAR_PACKAGE,
  GET_STUDENT_ENROLLMENTS_DATA,
} from "./actionTypes"

function* getDashboardData({ payload: { token, startDate, endDate } }) {
  try {
    const url = `/package/show-dashboard-data?firstDate=${startDate}&lastDate=${endDate}`
    const response = yield call(getData, url, token)
    yield put(getDashboardDataSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Failed to get dashboard data"
    toaster("error", message)
    yield put(getDashboardDataFail(message))
  }
}

function* workerGetStudentEnrollmentData({ payload: { token, year } }) {
  try {
    // console.log("call from frontend")
    const url = `/package/show-student-enrollment-of-each-data/${year}`
    const response = yield call(getData, url, token)
    yield put(getStudentEnrollmentDataSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Failed to get dashboard data"
    toaster("error", message)
    // yield put(getDashboardDataFail(message))
  }
}

function* workerGetMonthBasedSellingData({ payload: { token } }) {
  try {
    // console.log("here data 46")
    const url = `/package/show-month-based-selling-data`
    console.log("selling data")
    const response = yield call(getData, url, token)
    yield put(getMonthBasedSellingDataSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Failed to get dashboard data"
    toaster("error", message)
  }
}
function* workerGetNewStudents({ payload: { token } }) {
  try {
    const url = `/package/get-new-students-list`
    const response = yield call(getData, url, token)
    // console.log("get data", response?.getData)
    yield put(getNewStudentsSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Failed to get dashboard data"
    toaster("error", message)
  }
}

function* workerGetPopularPackages({ payload: { token } }) {
  try {
    const url = `/package/top-selling-package`
    const response = yield call(getData, url, token)
    yield put(getPopularPackagesSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Failed to get dashboard data"
    toaster("error", message)
  }
}
function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, getDashboardData)
  yield takeEvery(GET_STUDENT_ENROLLMENTS_DATA, workerGetStudentEnrollmentData)
  yield takeEvery(GET_MONTH_BASED_SELLING_DATA, workerGetMonthBasedSellingData)
  yield takeEvery(GET_NEW_STUDENTS, workerGetNewStudents)
  yield takeEvery(GET_POPULAR_PACKAGE, workerGetPopularPackages)
}

export default dashboardSaga
