import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import QuestionButtonHandler from "components/Common/QuestionButtonHandler"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Row, Table } from "reactstrap"
import {
  getIELTSReadingQuesStatus,
  getIELTSReadingSinglePassage,
  getIELTSSingleReadingSet,
  updateIELTSReadingQuesStatus
} from "store/actions"
export const mcqType = "mcq"
export const trueFalseQues = "trueFalse"
export const comprehensionQues = "comprehension"
export const YesNoQues = "YesNoQues"
export const HeadingMatching = "headingMatching"
export const multipleChoice = "multipleSelection"
export const fillGapsWords = "fillGapsWords"
export const fillGapsWithoutWords = "fillGaps"
export const fillGapsOne = "fillGapsOne"
export const fillGapsTwo = "fillGapsTwo"
export const fillGapsThree = "fillGapsThree"
export const sortAnswerOne = "sortAnswerOne"
export const sortAnswerTwo = "sortAnswerTwo"
export const sortAnswerThree = "sortAnswerThree"

const tableOne = "tableOne"
const tableTwo = "tableTwo"
const tableThree = "tableThree"
const formOne = "formOne"
const formTwo = "formTwo"
const formThree = "formThree"
const noteOne = "noteOne"
const noteTwo = "noteTwo"
const noteThree = "noteThree"

const ViewPassage = () => {
  const { serviceId, setId, passageId } = useParams()
  const dispatch = useDispatch()
  const {
    authToken,
    singleReadingSet,
    singlePassage,
    loading,
    questionStatus,
    adding,
  } = useSelector(state => ({
    authToken: state.Login.token,
    readingPassage: state.readingReducer.IeltsReadingPassage.data,
    passageLoading: state.readingReducer.IeltsReadingPassageLoading,
    singleReadingSet: state.readingReducer.singleReadingSet,
    singlePassage: state.readingReducer.singleReadingPassage,
    loading: state.readingReducer.loading,
    questionStatus: state.QuesReducer.questionStatus,
    adding: state.QuesReducer.adding,
  }))

  console.log(`singleReadingSet`, singleReadingSet, questionStatus)

  const newSetId = localStorage.getItem("setId")
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Reading Set",
      link: `/reading-set/${serviceId}`,
    },
    {
      title: `${singleReadingSet?.setName || ""}`,
      link: `/service/${serviceId}/reading/${!!setId ? setId : newSetId}`,
    },
    {
      title: `Create Question`,
      link: "",
    },
  ]

  // console.log(readingPassage)

  useEffect(() => {
    if (setId) {
      localStorage.setItem("setId", setId)
    }
    dispatch(getIELTSSingleReadingSet(authToken, !!setId ? setId : newSetId))
    dispatch(getIELTSReadingSinglePassage(authToken, passageId))
    dispatch(getIELTSReadingQuesStatus(authToken, passageId))
  }, [])

  const findData = useMemo(() => {
    return singlePassage.questions?.map(data => data?.Que?.questype)
  }, [singlePassage])
  // console.log(
  //   `🦄 ~ file: ViewPassage.js ~ line 69 ~ findData ~ findData`,
  //   findData
  // )

  function printToLetter(number) {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let result = ""
    let charIndex = number % alphabet.length
    let quotient = number / alphabet.length
    if (charIndex - 1 == -1) {
      charIndex = alphabet.length
      quotient--
    }
    result = alphabet.charAt(charIndex - 1) + result
    if (quotient >= 1) {
      printToLetter(parseInt(quotient))
    }
    return result
  }
  // console.log(printToLetter(1))
  const findEditAble = name => {
    // console.log('questionStatus[name]', questionStatus[name]);
    if (questionStatus[name]?.status === 1) {
      return true
    } else {
      let value = true
      for (let obj in questionStatus) {
        if (questionStatus[obj].status === 1) {
          value = false
        }
      }
      return value
    }
  }
  // console.log(`findEditAble ~ findEditAble`, findEditAble(trueFalseQues))

  const handleDone = type => {
    dispatch(updateIELTSReadingQuesStatus(authToken, passageId, type))
  }
  const isDone = useMemo(() => {
    const question = singlePassage?.totalQuestion
    let addedQues = Object.values(questionStatus).reduce(
      (total, item) => total + item?.qLength,
      0
    )

    if (question <= addedQues) {
      return true
    }
  }, [singlePassage, questionStatus])
  console.log("questionStatus", questionStatus)
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Reading"}
        breadcrumbItems={breadcrumbItems}
      >
        <CardComponent>
          <Row>
            <Col md="10" className="offset-md-1">
              <h4 className="mb-3">{singlePassage?.readingPessageTopicName}</h4>

              {loading ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                <React.Fragment>
                  {singlePassage?.readingPessageDescription?.map(
                    (data, idx) => (
                      <Row key={idx}>
                        <Col md="1">
                          <h3>{printToLetter(idx + 1)}</h3>
                        </Col>
                        <Col md="11">
                          <h6
                            style={{
                              textAlign: "justify",
                            }}
                          >
                            {data?.passageInfo}
                          </h6>
                        </Col>
                      </Row>
                    )
                  )}
                  <div className="question-list-area mt-5 border">
                    <div className="question-list-single-items">
                      <div className="question-list-table-wrap">
                        <Table>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Question Type</th>
                              <th>Question Limit</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>MCQ (Single)</td>
                              <td>{questionStatus["mcq"]?.qLength}</td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={mcqType}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${mcqType}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${mcqType}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>MCQ (Multi)</td>
                              <td>
                                {questionStatus["multipleSelection"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={multipleChoice}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${multipleChoice}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${multipleChoice}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>True or False</td>
                              <td>{questionStatus["trueFalse"]?.qLength}</td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={trueFalseQues}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${trueFalseQues}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${trueFalseQues}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>4</td>
                              <td>Heading/Matching</td>
                              <td>
                                {questionStatus["headingMatching"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={HeadingMatching}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${HeadingMatching}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${HeadingMatching}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Yes/NO</td>
                              <td>{questionStatus["YesNoQues"]?.qLength}</td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={YesNoQues}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${YesNoQues}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${YesNoQues}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Comprehension</td>
                              <td>
                                {questionStatus["comprehension"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={comprehensionQues}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${comprehensionQues}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${comprehensionQues}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>Fill in the Gap (without words)</td>
                              <td>{questionStatus["fillGaps"]?.qLength}</td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={fillGapsWithoutWords}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${fillGapsWithoutWords}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${fillGapsWithoutWords}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Fill in the Gap One (without words)</td>
                              <td>{questionStatus["fillGapsOne"]?.qLength}</td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={fillGapsOne}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${fillGapsOne}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${fillGapsOne}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>Fill in the Gap Two (without words)</td>
                              <td>{questionStatus["fillGapsTwo"]?.qLength}</td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={fillGapsTwo}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${fillGapsTwo}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${fillGapsTwo}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>Fill in the Gap Three (without words)</td>
                              <td>
                                {questionStatus["fillGapsThree"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={fillGapsThree}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${fillGapsThree}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${fillGapsThree}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>11</td>
                              <td>Fill in the Gap (with words)</td>
                              <td>
                                {questionStatus["fillGapsWords"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={fillGapsWords}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${fillGapsWords}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${fillGapsWords}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            {/* short answer */}
                            <tr>
                              <td>12</td>
                              <td>Short answer One</td>
                              <td>
                                {questionStatus["sortAnswerOne"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={sortAnswerOne}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${sortAnswerOne}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${sortAnswerOne}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>13</td>
                              <td>Short answer two</td>
                              <td>
                                {questionStatus["sortAnswerTwo"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={sortAnswerTwo}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${sortAnswerTwo}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${sortAnswerTwo}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>14</td>
                              <td>Short answer three</td>
                              <td>
                                {questionStatus["sortAnswerThree"]?.qLength}
                              </td>
                              <td>
                                <QuestionButtonHandler
                                  questionStatus={questionStatus}
                                  reading
                                  type={sortAnswerThree}
                                  handleDone={handleDone}
                                  createLink={`/reading-exam/${passageId}/${sortAnswerThree}`}
                                  previewLink={`/reading-exam/${passageId}/preview/${sortAnswerThree}`}
                                  adding={adding}
                                  findEditAble={findEditAble}
                                  isDone={isDone}
                                />
                              </td>
                            </tr>
                            {/* New Item */}
                            <tr>
                            <td>15</td>
                            <td>Table one</td>
                            <td> {questionStatus["tableOne"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={tableOne}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${tableOne}`}
                                previewLink={`/reading-exam/${passageId}/preview/${tableOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td>Table two</td>
                            <td>{questionStatus["tableTwo"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={tableTwo}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${tableTwo}`}
                                previewLink={`/reading-exam/${passageId}/preview/${tableTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td>Table three</td>
                            <td>{questionStatus["tableThree"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={tableThree}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${tableThree}`}
                                previewLink={`/reading-exam/${passageId}/preview/${tableThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          {/* form */}
                          <tr>
                            <td>18</td>
                            <td>Form one</td>
                            <td>{questionStatus["formOne"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={formOne}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${formOne}`}
                                previewLink={`/reading-exam/${passageId}/preview/${formOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>Form two</td>
                            <td>{questionStatus["formTwo"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={formTwo}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${formTwo}`}
                                previewLink={`/reading-exam/${passageId}/preview/${formTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Form three</td>
                            <td>{questionStatus["formThree"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={formThree}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${formThree}`}
                                previewLink={`/reading-exam/${passageId}/preview/${formThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          {/* note */}
                          <tr>
                            <td>21</td>
                            <td>Note one</td>
                            <td>{questionStatus["noteOne"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={noteOne}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${noteOne}`}
                                previewLink={`/reading-exam/${passageId}/preview/${noteOne}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>22</td>
                            <td>Note two</td>
                            <td>{questionStatus["noteTwo"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={noteTwo}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${noteTwo}`}
                                previewLink={`/reading-exam/${passageId}/preview/${noteTwo}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>23</td>
                            <td>Note three</td>
                            <td>{questionStatus["noteThree"]?.qLength}</td>
                            <td>
                              <QuestionButtonHandler
                                questionStatus={questionStatus}
                                type={noteThree}
                                handleDone={handleDone}
                                createLink={`/reading-exam/${passageId}/${noteThree}`}
                                previewLink={`/reading-exam/${passageId}/preview/${noteThree}`}
                                adding={adding}
                                findEditAble={findEditAble}
                                isDone={isDone}
                                reading
                              />
                            </td>
                          </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </CardComponent>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ViewPassage
