import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import { image, imageBaseUrl } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import CustomTable from "Layout/CustomTable"
import InnerLayer from "Layout/InnerLayer"
import NoTableData from "Layout/NoTableData"

import React, { useState } from "react"
import { useEffect } from "react"
import { Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, Card, Col } from "reactstrap"
import { createUniversity, getUniversityList } from "store/actions"

import "../BannerArea/exam-data-review.scss"
import uploadIcon from "../BannerArea/img/send-square.svg"

const UniversityList = () => {
  const history = useHistory()
  const [show, setShow] = useState(false)

  const [bannerId, setBannerId] = useState("")
  const dispatch = useDispatch()

  const { authToken, bannerLoading, bannerList, editBannerData, universityList, universityLoading } = useSelector(
    state => ({
      authToken: state.Login.token,
      universityLoading: state.AdminDashboardReducer.universityLoading,
      universityList: state.AdminDashboardReducer.universityList,
    })
  )
 console.log('universityList', universityList);

 useEffect(()=>{
  dispatch(getUniversityList(authToken))
 }, [])

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    console.log("values", values)
    if(values?.universityName){
      let body = {
        universityName: values?.universityName,
      }
       dispatch(createUniversity(body, authToken, history, setShow))
    }
   
    console.log("hello")
  }

  const getPhotoUrl = photo => {
    console.log("photo", photo)
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return ""
  }

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="University"
        mainTitle={"Dashboard"}
        subTitle={"University"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton />

          <Modal.Body>
            <div className="">
              <AvForm onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                        name="universityName"
                        label="University Name"
                        type="text"
                        placeholder="Type University Name"
                        errorMessage="Type University Name"
                        // value={editBannerData?.link}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <React.Fragment>
                    <div className="flex-wrap gap-2 col-md-12">
                      <Button type="submit" color="primary" className="w-100">
                        Submit
                      </Button>
                    </div>
                  </React.Fragment>
                </Row>
              </AvForm>
            </div>
          </Modal.Body>
        </Modal>
        <CardComponent className="position-relative">
          <div className="banner-add-modal-button-top">
            <Button
              type="button"
              color="primary"
              className="btn-circle me-2"
              onClick={handleShow}
            >
              <img src={uploadIcon} alt="" />
              Add University
            </Button>
          </div>
        </CardComponent>
        <Row className="mt-3">
          <CardComponent className="position-relative">
            <CustomTable
              paginationClass="paginationContainer text-right"
              tableHead={["Sr", "Name", "Action"]}
            >
              {universityLoading ? (
                <NoTableData
                  colSpan="4"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px", width: `100%` }}
                >
                  <Loader />
                </NoTableData>
              ) : universityList ?.length > 0 ? (
                universityList ?.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <td scope="row">{idx + 1}</td>
                      <td>{data?.universityName}</td>
                     
                      <td></td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="4"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px", width: `100%` }}
                >
                  <span>No data here</span>
                </NoTableData>
              )}
            </CustomTable>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default UniversityList
