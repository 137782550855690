import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import { multipleQuesSchema } from "components/Schemas/QuestionSchemas"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSListeningQuesMCQ,
  getIELTSListeningQuesMCQ,
  getIELTSListeningQuesNum,
} from "store/actions"

const initialValues = {
  q: "",
  answer: [""],
  opt: ["", ""],
  explanation: "",
}

const Multiple = ({ history, edit, location }) => {
  const { serviceId, setId, passageId, questionId, id } = useParams()
  console.log(`🦄 ~ file: MCQ.js ~ line 24 ~ MCQ ~ passageID`, passageId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const dispatch = useDispatch()
  const { authToken, loading, adding, questionNumber, singleQuestion } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.listeningQuestion.loading,
      adding: state.listeningQuestion.adding,
      services: state.serviceReducer.services,
      questionNumber: state.listeningQuestion.questionNumber,
      singleQuestion: state.listeningQuestion.singleQuestion,
    }))
  // console.log(
  //   `🦄 ~ file: Multiple.js ~ line 34 ~ Multiple ~ singleQuestion`,
  //   singleQuestion,
  //   questionNumber
  // )

  useEffect(() => {
    if (edit && !!singleQuestion) {
      const value = { ...listValue }
      value.q = singleQuestion?.q
      value.opt = singleQuestion?.opt
      value.answer = singleQuestion?.answer
      value.explanation = singleQuestion?.explanation
      setListValue(value)
      // setQuestionNumber(singleQuestion?.questionNumber)
    }
  }, [singleQuestion])

  useEffect(() => {
    const type = location.pathname.split("/").pop()
    dispatch(getIELTSListeningQuesNum(authToken, passageId, type))
    if (edit) {
      const data = {
        type,
        objectId: id,
        questionId,
      }
      dispatch(getIELTSListeningQuesMCQ(authToken, passageId, data))
    }
  }, [])

  const onSubmit = (values, { resetForm }) => {
    const type = location.pathname.split("/").pop()
    const obj = {
      ...values,
      listeningAudio: passageId,
      questionSerial: questionNumber,
      questype: type,
    }
    const options = {
      isMore: isAddMore,
      resetForm,
      setIsAddMore,
      questionId,
      type,
      passageId,
    }
    if (edit) {
      dispatch(addIELTSListeningQuesMCQ(obj, history, authToken, options))
    } else {
      dispatch(addIELTSListeningQuesMCQ(obj, history, authToken, options))
    }
    console.log(`🦄 ~ file: MCQ.js ~ line 44 ~ MCQ ~ values`, obj)
  }
  return (
    <React.Fragment>
      <InnerLayer isBreadCrumb={false} title="Create multi choose question">
        <Row className="mt-3">
          <Col md="10" className="offset-md-1">
            <CardComponent className={"mb-0"}>
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-left-arrow-alt me-2"
                  onClick={() => history.goBack()}
                  style={{ fontSize: "18px" }}
                ></i>
                <h6 className="mb-0">Create multi choose (MCQ)</h6>
              </div>
            </CardComponent>
            <CardComponent style={{ marginTop: "5px" }}>
              <Row>
                <Col md="1">
                  <div className="question_number d-flex justify-content-center align-items-start">
                    {questionNumber || 1}
                  </div>
                </Col>
                <Col md="11">
                  <h5>Type 1no. question</h5>
                  <br />
                  {loading && edit ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <Formik
                      initialValues={listValue || initialValues}
                      validationSchema={multipleQuesSchema}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                    >
                      {({ values, errors }) => (
                        <Form>
                          {console.log(errors)}
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                label="Question Title"
                                name="q"
                                type="text"
                                id={"question"}
                                component={CustomInput}
                                placeholder="Type paragraph title"
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <FieldArray
                              name="opt"
                              render={arrayHelpers => (
                                <Row>
                                  {values?.opt?.map((value, index) => (
                                    <Col md="6" key={index}>
                                      <div className="mb-2 position-relative">
                                        <Field
                                          name={`opt.${index}`}
                                          type={"text"}
                                          id={index}
                                          label={`Option ${index + 1}`}
                                          component={CustomInput}
                                          placeholder="Type option"
                                        />
                                        {index > 0 ? (
                                          <div
                                            className="position-absolute"
                                            style={{ top: "0", right: "0" }}
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <Button
                                              className="btn-danger btn-sm "
                                              style={{
                                                background: "transparent",
                                                border: "none",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <i
                                                className="bx bx-trash"
                                                style={{ color: "#999" }}
                                              ></i>
                                            </Button>
                                          </div>
                                        ) : null}
                                        <ErrorMessage
                                          name={`opt.${index}`}
                                          component={TextError}
                                        />
                                      </div>
                                    </Col>
                                  ))}
                                  <Col md="12">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="d-inline-block"
                                      style={{
                                        background: "#E5F0FF",
                                        color: "#485ec4",
                                        border: "none",
                                        width: "initial",
                                      }}
                                      onClick={() => arrayHelpers.push("")}
                                    >
                                      Add more option
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            />
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <FieldArray
                                name="answer"
                                render={arrayHelpers => (
                                  <Row>
                                    {values?.answer?.map((value, index) => (
                                      <Col md="6" key={index}>
                                        <div className="mb-2 position-relative">
                                          <Field
                                            name={`answer.${index}`}
                                            type={"text"}
                                            id={index}
                                            label={`Right answer ${
                                              index > 0 ? index + 1 : ""
                                            }`}
                                            component={CustomInput}
                                            placeholder="Type Right answer"
                                          />
                                          {index > 0 ? (
                                            <div
                                              className="position-absolute"
                                              style={{ top: "0", right: "0" }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <Button
                                                className="btn-danger btn-sm "
                                                style={{
                                                  background: "transparent",
                                                  border: "none",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                <i
                                                  className="bx bx-trash"
                                                  style={{ color: "#999" }}
                                                ></i>
                                              </Button>
                                            </div>
                                          ) : null}
                                          <ErrorMessage
                                            name={`answer.${index}`}
                                            component={TextError}
                                          />
                                        </div>
                                      </Col>
                                    ))}
                                    <Col md="12">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="d-inline-block"
                                        style={{
                                          background: "#E5F0FF",
                                          color: "#485ec4",
                                          border: "none",
                                          width: "initial",
                                        }}
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        Add more answer
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="explanation"
                                id={"Explanation"}
                                label="Explanation"
                                placeholder="Type explanation"
                                type="textarea"
                                rows={"5"}
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12" className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="me-2"
                                disabled={adding}
                                type="submit"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                              >
                                {adding
                                  ? "Submitting..."
                                  : edit
                                  ? "Update"
                                  : "Save"}
                              </Button>
                              {!edit ? (
                                <Button
                                  color="primary"
                                  disabled={isAddMore}
                                  type="submit"
                                >
                                  {isAddMore
                                    ? "Submitting..."
                                    : "Create more question"}
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Col>
              </Row>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(Multiple)
