import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { activeBannerFail, activeBannerSuccess, addAuthorFail, addAuthorSuccess, addBannerFail, addBannerSuccess, addBlogFail, addBlogSuccess, getAuthorListFail, getAuthorListSuccess, getBannerList, getBannerListFail, getBannerListSuccess, getBlogPostFail, getBlogPostSuccess } from "./action"
import { ACTIVE_BANNER, ADD_AUTHOR, ADD_BANNER, ADD_BANNER_SUCCESS, ADD_BLOG, GET_AUTHOR, GET_BANNER, GET_BLOG_POST } from "./actionTypes"


function* onAddBanner({ payload: { data, token, history, bannerId } }) {
  try {
    console.log('banner',  bannerId);
    const url = bannerId ? `banner-update/${bannerId}` :`/banner-create`
    const response = yield call(bannerId ? patchData: postData, url, data, token)
    if (response) {
      yield put(addBannerSuccess({}))
      yield put(getBannerList(token));
      toaster("success", `Banner created Successfully`)
      
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Author creation failed`
    toaster("error", message)
    yield put(addBannerFail(message))
  }
}
function* onGetBannerList({ payload: { token } }) {
  try {
    const url = "/banners-get-by-admin";
    const response = yield call(getData, url, token);
    yield put(getBannerListSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Banner failed";
    yield put(getBannerListFail(message));
    toaster("error", message);
  }
}

function* onActiveBanner({ payload: { data, token, history, id } }) {
  try {
    const url = `banner-visiblity/${id}`
    const response = yield call(patchData, url, data, token)
    if (response) {
      yield put(activeBannerSuccess({}))
      yield put(getBannerList(token));
      toaster("success", `Banner updated Successfully`)
      
    }
    
    // history.push("/blog-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Banner Activation failed`
    toaster("error", message)
    yield put(activeBannerFail(message))
  }
}



function* BannerSaga() {
  yield takeEvery(ADD_BANNER, onAddBanner)
  yield takeEvery(GET_BANNER, onGetBannerList)
  yield takeEvery(ACTIVE_BANNER, onActiveBanner)
 


}

export default BannerSaga
