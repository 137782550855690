import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { addAuthorFail, addAuthorSuccess, addBlogFail, addBlogSuccess, getAuthorListFail, getAuthorListSuccess, getBlogPostFail, getBlogPostSuccess } from "./action"
import { ADD_AUTHOR, ADD_BLOG, GET_AUTHOR, GET_BLOG_POST } from "./actionTypes"


function* onAddAuthor({ payload: { data, token, history } }) {
  try {
    const url = `author/create-author`
    const response = yield call(postData, url, data, token)
    if (response) {
      yield put(addAuthorSuccess({}))
      toaster("success", `Author Added Successfully`)
      
    }
    history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Author creation failed`
    toaster("error", message)
    yield put(addAuthorFail(message))
  }
}
function* onGetAuthorList({ payload: { authtoken } }) {
  try {
    const url = "/author/get-authors";
    const response = yield call(getData, url, authtoken);
    yield put(getAuthorListSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get BlogList failed";
    yield put(getAuthorListFail(message));
    toaster("error", message);
  }
}

function* onAddBlog({ payload: { data, token, history } }) {
  try {
    const url = `author/create-post`
    const response = yield call(postData, url, data, token)
    if (response) {
      yield put(addBlogSuccess({}))
      toaster("success", `Blog Created Added Successfully`)
      
    }
    toaster("success", `Blog Created Successfully`)
    history.push("/blog-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Blog creation failed`
    toaster("error", message)
    yield put(addBlogFail(message))
  }
}


function* onGetBlogList({ payload: { authtoken } }) {
  try {
    const url = "/author/user/get-top-three-post";
    const response = yield call(getData, url, authtoken);
    yield put(getBlogPostSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get BlogList failed";
    yield put(getBlogPostFail(message));
    toaster("error", message);
  }
}
function* blogSaga() {
  yield takeEvery(ADD_AUTHOR, onAddAuthor)
  yield takeEvery(GET_AUTHOR, onGetAuthorList)
  yield takeEvery(ADD_BLOG, onAddBlog)
  yield takeEvery(GET_BLOG_POST, onGetBlogList)


}

export default blogSaga
