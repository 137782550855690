import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  activeScreenFail,
  activeScreenSuccess,
  addAnalyticalWritingFail,
  addAnalyticalWritingSuccess,
  addScreenFail,
  addScreenSuccess,
  getAnalyticalAnsDataFail,
  getAnalyticalAnsDataSuccess,
  getAnalyticalWritingFail,
  getAnalyticalWritingSuccess,
  getNumberOfAnalyticalAnsFail,
  getNumberOfAnalyticalAnsSuccess,
  getScreenList,
  getScreenListFail,
  getScreenListSuccess,
  getSingleAnalyticalQues,
  getSingleAnalyticalQuesFail,
  getSingleAnalyticalQuesSuccess,
  submitAnalyticalMarkFail,
  submitAnalyticalMarkSuccess,
} from "./actions"
import {
  ACTIVE_SCREEN,
  ADD_ANALYTICAL_WRITING,
  ADD_SCREEN,
  GET_ANALYTICAL_ANS_DATA,
  GET_ANALYTICAL_WRITING,
  GET_NUMBER_OF_ANALYTICAL_EXAM,
  GET_SCREEN,
  GET_SINGLE_ANALYTICAL_QUES,
  SUBMIT_ANALYTICAL_MARK,
} from "./actionTypes"

function* onAddAnalyticalWriting({ payload: { data, authToken, options } }) {
  try {
    console.log("data", options)
    const url = options?.id
      ? `gmat/update-single-gmat-writing-question/${options?.id}`
      : `gmat/create-gmat-writing-question`
    const response = yield call(
      options?.id ? patchData : postData,
      url,
      data,
      authToken
    )

    if (response) {
      toaster(
        "success",
        `Analytical writing ${options?.id ? "updated" : "added"} successfully`
      )
      yield put(addAnalyticalWritingSuccess({}))
      if (options?.id) {
        yield put(getSingleAnalyticalQues(authToken, options?.id))
      }

      options?.setSubmitting(false)
      options?.resetForm()
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Analytical writing creation failed`
    toaster("error", message)
    options?.setSubmitting(false)
    yield put(addAnalyticalWritingFail(message))
  }
}

function* onSubmitAnalytical({ payload: { data, authToken, options } }) {
  try {
    console.log("data", options)
    const url = `package/awa-marking/${options?.examId}`
    const response = yield call( patchData,url,data,authToken )

    if (response) {
      toaster(
        "success",
        `Mark submitted successfully`
      )
      yield put(submitAnalyticalMarkSuccess({}))
      options?.history?.push('/aw-exam/user')
     
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Analytical writing creation failed`
    toaster("error", message)
   
    yield put(submitAnalyticalMarkFail(message))
  }
}
function* onGetAnalyticalWriting({ payload: { token, type } }) {
  try {
    const url = `gmat/get-gmat-writing-questions?questionType=${type}`
    const response = yield call(getData, url, token)
    yield put(getAnalyticalWritingSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getAnalyticalWritingFail(message))
    toaster("error", message)
  }
}

function* onGetSingleQuantitativeQuestion({
  payload: { authToken, questionId },
}) {
  console.log("saga", questionId)
  try {
    const url = `gmat/get-single-gmat-writing-question/${questionId}`
    const response = yield call(getData, url, authToken)
    yield put(getSingleAnalyticalQuesSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getSingleAnalyticalQuesFail(message))
    toaster("error", message)
  }
}

function* onGetAnalyticalNum({
  payload: { authToken, examStatus },
}) {
  try {
    const url = `package/analytical-exam-count?examStatus=${examStatus}`
    const response = yield call(getData, url, authToken)
    yield put(getNumberOfAnalyticalAnsSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getNumberOfAnalyticalAnsFail(message))
    toaster("error", message)
  }
}
function* onGetAnalyticalAns({
  payload: { authToken, skip, limit },
}) {
  try {
    const url = `package/analytical-exam-data?skip=${skip}&limit=${limit}`
    const response = yield call(getData, url, authToken)
    yield put(getAnalyticalAnsDataSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getAnalyticalAnsDataFail(message))
    toaster("error", message)
  }
}
function* AnalyticalExamSaga() {
  yield takeEvery(ADD_ANALYTICAL_WRITING, onAddAnalyticalWriting)
  yield takeEvery(GET_ANALYTICAL_WRITING, onGetAnalyticalWriting)
  yield takeEvery(GET_SINGLE_ANALYTICAL_QUES, onGetSingleQuantitativeQuestion)
  yield takeEvery(GET_NUMBER_OF_ANALYTICAL_EXAM, onGetAnalyticalNum)
  yield takeEvery(GET_ANALYTICAL_ANS_DATA, onGetAnalyticalAns)
  yield takeEvery(SUBMIT_ANALYTICAL_MARK, onSubmitAnalytical)
}

export default AnalyticalExamSaga
