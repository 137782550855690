import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Alert, Col, Row } from "reactstrap"
import { addCourse, getSingleCourse } from "store/Course/actions"
import { getCourseTypeSuccess } from "store/CourseType/actions"

const CreateCourse = ({ history, edit }) => {
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Course List",
      link: "/courses",
    },
    {
      title: `${edit ? "Update" : "Create"} Course`,
      link: "#",
    },
  ]
  const { id } = useParams()
  const dispatch = useDispatch()
  const { authToken, loading, error, adding, singleCourse } = useSelector(
    state => ({
      authToken: state.Login.token,
      loading: state.course.loading,
      adding: state.course.adding,
      singleCourse: state.course.singleCourse,
      error: state.course.error,
    })
  )
  // console.log(
  //   `🦄 ~ file: CreateCourse.js ~ line 30 ~ CreateCourse ~ singleCourse`,
  //   singleCourse
  // )

  useEffect(() => {
    if (edit) {
      dispatch(getSingleCourse(authToken, id))
    }
    return () => {
      dispatch(getCourseTypeSuccess({}))
    }
  }, [])

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      toaster("warning", "Please enter course name")
      return
    }
    edit
      ? dispatch(addCourse(values, authToken, history, id))
      : dispatch(addCourse(values, authToken, history))
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Update Course" : "Create Course"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-3">
          <Col md="6" className="offset-md-3">
            <CardComponent>
              <h5>{edit ? "Update" : "Create"} Course</h5>
              {error ? <Alert color="danger">{error}</Alert> : null}
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                <AvForm className="mt-4" onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <p>Course Name</p>
                    <AvField
                      name="courseName"
                      type="text"
                      errorMessage="Type package name"
                      placeholder="Type package name"
                      value={singleCourse?.courseName}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Row>
                  <div>
                    <button
                      disabled={adding}
                      type="submit"
                      className="btn btn-primary w-md"
                    >
                      {adding
                        ? "Submitting..."
                        : edit
                        ? "Update Course"
                        : "Create Course"}
                    </button>
                  </div>
                </AvForm>
              )}
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CreateCourse)
