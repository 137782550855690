import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import InnerLayer from "Layout/InnerLayer"
import Upload from "pages/Package/Upload"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"
import {
  getIELTSReadingSinglePassageSuccess,
  getListeningAudio,
  uploadAudioFile,
} from "store/actions"

const UploadAudio = ({ edit, history }) => {
  const [fileError, setFileError] = useState("")
  const [selectedFiles, setSelectedFiles] = useState([])
  console.log(
    `🦄 ~ file: UploadAudio.js ~ line 20 ~ UploadAudio ~ selectedFiles`,
    selectedFiles
  )
  const { serviceId, setId, id } = useParams()
  const dispatch = useDispatch()
  const { authToken, listeningSet, loading, adding, listeningAudio } =
    useSelector(state => ({
      listeningAudio: state.listeningSet.listAudio,
      authToken: state.Login.token,
      loading: state.listeningSet.loading,
      adding: state.listeningSet.adding,
      listeningSet: state.listeningSet.listeningSet,
    }))
  console.log(
    `🦄 ~ file: UploadAudio.js ~ line 27 ~ UploadAudio ~ listeningAudio`,
    listeningAudio
  )
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Listening",
      link: `/listening/${serviceId}`,
    },
    {
      title: `${listeningSet?.setName || "Listening Set"}`,
      link: `/service/${serviceId}/listening/${setId}`,
    },
    {
      title: edit ? `Update Audio` : `Upload Audio`,
      link: "#",
    },
  ]

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setFileError("")
    }
  }, [selectedFiles])
  useEffect(() => {
    dispatch(getListeningAudio(authToken, setId))

    return () => {
      dispatch(getIELTSReadingSinglePassageSuccess({}))
    }
  }, [])
  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (selectedFiles.length === 0 && !edit) {
      return setFileError("Please select a image")
    }
    let data = {
      ...values,
    }
    if (selectedFiles?.length) {
      data.file = selectedFiles?.[0]
    }
    const option = {
      setId,
      token: authToken,
      history,
      audioId: edit ? id : "",
    }
    dispatch(uploadAudioFile(data, option))
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Edit Set" : "Create Set"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <h5>Upload Audio</h5>
            {edit && loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <AvForm className="mt-4" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md="3">Audio Title</Col>
                  <Col md="9">
                    <AvField
                      name="audioTopicName"
                      type="text"
                      errorMessage="Type paragraph title"
                      placeholder="Type paragraph title"
                      value={listeningAudio?.audioTopicName || ""}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="3">Audio File</Col>
                  <Col md="9">
                    <Upload
                      exceptFile="audio/*"
                      uploadMessage={
                        selectedFiles.length > 0 || edit
                          ? "Drop files here or click to change."
                          : "Drop files here or click to upload."
                      }
                      setSelectedFiles={setSelectedFiles}
                    />
                    {selectedFiles?.length === 0 && fileError !== "" ? (
                      <TextError>{fileError}</TextError>
                    ) : null}
                    <Row>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles?.length ? (
                          selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      {/* <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          style={{ objectFit: "cover" }}
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        /> */}
                                      <div>
                                        <i className="bx bx-music fs-2"></i>
                                      </div>
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        ) : listeningAudio?.audioTopicName ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <div>
                                  <i className="bx bx-music fs-2"></i>
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {listeningAudio?.audiourl}
                                </Link>
                                {/* <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p> */}
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md="3"></Col>
                  <Col md="9">
                    <div>
                      <button
                        disabled={adding}
                        type="submit"
                        className="btn btn-primary w-md"
                      >
                        {adding
                          ? "Submitting..."
                          : edit
                          ? "Update Set"
                          : "Save Audio"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            )}
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(UploadAudio)
