import { getData, patchData, postData } from "helpers/backend_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import { addGraphicalQuesFail, addGraphicalQuesSuccess, addMultiSourceFail, addMultiSourceSuccess, addTableAnalysisQuesFail, addTableAnalysisQuesSuccess, addTwoPartQuesFail, addTwoPartQuesSuccess, getAllIntegratedQuesFail, getAllIntegratedQuesSuccess, getSingleTableAnalysisQuesFail, getSingleTableAnalysisQuesSuccess } from "./actions";
import { ADD_GRAPHICAL_QUES, ADD_MULTI_SOURCE, ADD_TABLE_ANALYSIS_QUES, ADD_TWO_PART_QUES, GET_ALL_INTEGRATED } from "./actionTypes";
import { GET_SINGLE_TABLE_ANALYSIS_QUES } from "./actionTypes";


function* onAddGraphicalQuestion({ payload: { obj, authToken, option } }) {
  try {
    console.log('banner',  option);
    const url = option?.id? `gmat/integrated/update-integrated-reasoning-question/${option?.id}`: `gmat/integrated/create-integrated-questions`
    const response = yield call(option?.id ? patchData : postData, url, obj, authToken)
    

    if (response) {
      toaster(
        "success",
        `Graphical question ${option?.id ? "updated" : "added"} successfully`
      );
      option?.setSubmitting(false);
      option?.resetForm();
      if (option?.isMore) {
        yield put(addGraphicalQuesSuccess());
        option?.setIsAddMore(false);
      } else {
        yield put(addGraphicalQuesSuccess());
        // yield put(getSingleQuantitativeQues(authToken, option?.id));
        // history.push(`/Quantative-ques-list`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Graphical question creation failed`
    toaster("error", message)
    option?.setSubmitting(false);
    yield put(addGraphicalQuesFail(message))
  }
}

function* onAddMultiSourceQuestion({ payload: { obj, authToken, option } }) {
  try {
    console.log('banner',  option);
    const url = option?.id? `gmat/integrated/update-integrated-reasoning-question/${option?.id}`: `gmat/integrated/create-integrated-questions`
    const response = yield call(option?.id ? patchData : postData, url, obj, authToken)
    

    if (response) {
      toaster(
        "success",
        `Multi Source question ${option?.id ? "updated" : "added"} successfully`
      );
      option?.setSubmitting(false);
      option?.resetForm();
      if (option?.isMore) {
        yield put(addMultiSourceSuccess());
        option?.setIsAddMore(false);
      } else {
        yield put(addMultiSourceSuccess());
        // yield put(getSingleQuantitativeQues(authToken, option?.id));
        // history.push(`/Quantative-ques-list`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Graphical question creation failed`
    toaster("error", message)
    option?.setSubmitting(false);
    yield put(addMultiSourceFail(message))
  }
}
function* onAddTableAnalysisQuestion({ payload: { obj, authToken, option } }) {
  try {
    console.log('banner',  option);
    const url = option?.id? `gmat/integrated/update-integrated-reasoning-question/${option?.id}`: `gmat/integrated/create-integrated-questions`
    const response = yield call(option?.id ? patchData : postData, url, obj, authToken)
    

    if (response) {
      toaster(
        "success",
        `Table analysis question ${option?.id ? "updated" : "added"} successfully`
      );
      option?.setSubmitting(false);
      option?.resetForm();
      if (option?.isMore) {
        yield put(addTableAnalysisQuesSuccess());
        option?.setIsAddMore(false);
      } else {
        yield put(addTableAnalysisQuesSuccess());
        // yield put(getSingleQuantitativeQues(authToken, option?.id));
        // history.push(`/Quantative-ques-list`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Graphical question creation failed`
    toaster("error", message)
    option?.setSubmitting(false);
    yield put(addTableAnalysisQuesFail(message))
  }
}

function* onAddTwoPartAnalysisQuestion({ payload: { obj, authToken, option } }) {
  try {
    console.log('banner',  option);
    const url = option?.id? `gmat/integrated/update-integrated-reasoning-question/${option?.id}`: `gmat/integrated/create-integrated-questions`
    const response = yield call(option?.id ? patchData : postData, url, obj, authToken)
    

    if (response) {
      toaster(
        "success",
        `Graphical question ${option?.id ? "updated" : "added"} successfully`
      );
      option?.setSubmitting(false);
      option?.resetForm();
      if (option?.isMore) {
        yield put(addTwoPartQuesSuccess());
        option?.setIsAddMore(false);
      } else {
        yield put(addTwoPartQuesSuccess());
        // yield put(getSingleQuantitativeQues(authToken, option?.id));
        // history.push(`/Quantative-ques-list`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Graphical question creation failed`
    toaster("error", message)
    option?.setSubmitting(false);
    yield put(addTwoPartQuesFail(message))
  }
}


function* onGetAllIntegratedQuestion({ payload: { token, section, type } }) {
  try {
    const url = `/gmat/integrated/get-integrated-reasonings/${section}/${type}`;
    const response = yield call(getData, url, token);
    yield put(getAllIntegratedQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getAllIntegratedQuesFail(message));
    toaster("error", message);
  }
}
function* onGetSingleIntegratedQuestion({ payload: { authToken, questionId } }) {
  console.log('saga', questionId);
  try {
    const url = `gmat/integrated/get-single-integrated-question/${questionId}`;
    const response = yield call(getData, url, authToken);
    yield put(getSingleTableAnalysisQuesSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getSingleTableAnalysisQuesFail(message));
    toaster("error", message);
  }
}


   


function* IRSaga() {
  yield takeEvery(ADD_GRAPHICAL_QUES, onAddGraphicalQuestion)
  
  yield takeEvery(ADD_TABLE_ANALYSIS_QUES, onAddTableAnalysisQuestion)
  yield takeEvery(ADD_TWO_PART_QUES, onAddTwoPartAnalysisQuestion)

  
  yield takeEvery(GET_ALL_INTEGRATED, onGetAllIntegratedQuestion)
  yield takeEvery(ADD_MULTI_SOURCE, onAddMultiSourceQuestion)
  yield takeEvery(GET_SINGLE_TABLE_ANALYSIS_QUES, onGetSingleIntegratedQuestion)

}

export default IRSaga
