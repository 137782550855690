import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import TableDropdown from "components/Common/TableDropdown"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import NoTableData from "Layout/NoTableData"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Row } from "reactstrap"
import { getCoupon, getFAQtype, updateCoupon } from "store/actions"
import CardComponent from "../../components/Common/CardComponent"

const tableHead = [
  "#",
  "Faq Type",
  "Date",
  "Action"
 
]

const TypeListFaq = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const [deleteItem, setDeleteItem] = useState({
    modal: false,
    data: {},
  })
  const { authtoken, loading, faqTypeList, faqTypeListLoading } = useSelector(state => ({
    authtoken: state.Login.token,
    loading: state.coupon.loading,
    message: state.coupon.message,
    error: state.coupon.error,
    faqTypeList: state.FAQReducer.faqTypeList,
    faqTypeListLoading: state.FAQReducer.faqTypeListLoading,
  }))

console.log('faqTypeList', faqTypeList);



  useEffect(() => {
    dispatch(getFAQtype(authtoken))
  }, [])


  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="FAQ type"
        mainTitle={"Dashboard"}
        subTitle={"FAQ type"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>FAQ type</h4>
            <div className="d-flex">
              <div className="search-box me-2">
              </div>
              <div>
                <div className="position-relative">
                  <Link to="/createFaqType">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create FAQ type
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        <Row>
          <CustomTable
            paginationClass="paginationContainer text-right"
            data={faqTypeList}
          
            tableHead={tableHead}
            setCurrentPage={setCurrentPage}
          
            // setCurrentPage={setCurrentPage}
            // tbodyStyle={{ height: couponData?.length <= 3 ? "300px" : "" }}
          >
            {faqTypeListLoading ? (
              <NoTableData
                colSpan="8"
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px", width: `100%` }}
              >
                <Loader />
              </NoTableData>
            ) : faqTypeList?.length > 0 ? (
              faqTypeList?.map((data, idx) => (
                <tr key={idx}>
                  <th scope="row" style={{ paddingLeft: "25px" }}>
                    {idx + 1}
                  </th>
                  <td>
                    <strong>{data?.typeName}</strong>
                  </td>
                  <td>
                    <strong>{data?.createdAt?.slice(0,10)}</strong>
                  </td>
                  <td>
                  <i className="bx bx-edit-alt"   onClick={() =>
                      history.push(`/updateFaqType/${data?._id}`)
                    }></i>
                  </td>
                 
                </tr>
              ))
            ) : (
              <NoTableData
                colSpan="8"
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px", width: `100%` }}
              >
                <span>No data Available</span>
              </NoTableData>
            )}
          </CustomTable>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TypeListFaq)
