import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import TableDropdown from "components/Common/TableDropdown"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import NoTableData from "Layout/NoTableData"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Row } from "reactstrap"
import { getCoupon, updateCoupon } from "store/actions"
import CardComponent from "../../components/Common/CardComponent"

const tableHead = [
  "#",
  "Coupon Code",
  "Coupon Percentage",
  "Start Date",
  "Ending Date",
  "Status",
  "Action",
]

const CouponList = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const [deleteItem, setDeleteItem] = useState({
    modal: false,
    data: {},
  })
  const { authtoken, loading, couponData } = useSelector(state => ({
    authtoken: state.Login.token,
    loading: state.coupon.loading,
    message: state.coupon.message,
    error: state.coupon.error,
    couponData: state.coupon.data,
  }))

  //   const totalPageNumber = Math.ceil((couponData?.count || 0) / 10)
  console.log(`Coupon`, couponData)

  // useEffect(() => {
  //   if (message) {
  //     toast.success(message)
  //   }
  // }, [message])

  useEffect(() => {
    dispatch(getCoupon(authtoken))
  }, [])

  const handleVisible = (status, id) => {
    dispatch(updateCoupon(authtoken, status, id))
  }

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Coupon List"
        mainTitle={"Dashboard"}
        subTitle={"Coupon List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Coupon List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                {/* <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    value={keyword}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div> */}
              </div>
              <div>
                <div className="position-relative">
                  <Link to="/coupon/create-coupon">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create Coupon
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        <Row>
          <CustomTable
            paginationClass="paginationContainer text-right"
            // data={couponData}
            // pageNo={totalPageNumber}
            tableHead={tableHead}
            // setCurrentPage={setCurrentPage}
            tbodyStyle={{ height: couponData?.length <= 3 ? "300px" : "" }}
          >
            {loading ? (
              <NoTableData
                colSpan="8"
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px", width: `100%` }}
              >
                <Loader />
              </NoTableData>
            ) : couponData?.length > 0 ? (
              couponData?.map((data, idx) => (
                <tr key={idx}>
                  <th scope="row" style={{ paddingLeft: "25px" }}>
                    {idx + 1}
                  </th>
                  <td>
                    <strong>{data?.couponCode}</strong>
                  </td>
                  <td>{data?.couponPercentage}%</td>
                  <td>
                    {moment(data?.couponStartingDate).format("YYYY-MM-DD") ||
                      "N/A"}
                    {/* HH:MM A" */}
                  </td>
                  <td>
                    {moment(data?.couponEndingDate).format("YYYY-MM-DD") ||
                      "N/A"}
                    {/* HH:MM A" */}
                  </td>
                  <td>{data?.visible ? "Active" : "Inactive"}</td>
                  <td>
                    <TableDropdown>
                      <Dropdown.Item
                        className="d-flex align-items-center gap-2"
                        onClick={() =>
                          history.push(`/coupon/edit-coupon/${data?._id}`)
                        }
                      >
                        <i className="bx bx-edit fs-4"></i>
                        <span> Edit</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-flex align-items-center gap-2"
                        onClick={() => {
                          if (data?.visible) {
                            handleVisible(true, data?._id)
                          } else {
                            if (
                              couponData?.every(data => data?.visible === false)
                            ) {
                              handleVisible(false, data?._id)
                            } else {
                              toaster("warning", "Please inactive other Coupon")
                            }
                          }
                        }}
                      >
                        <i
                          className={
                            data?.visible
                              ? "far fa-eye-slash fs-5"
                              : "far fa-eye fs-5"
                          }
                        ></i>
                        <span>{data?.visible ? "Inactive" : "Active"}</span>
                      </Dropdown.Item>
                    </TableDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <NoTableData
                colSpan="8"
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px", width: `100%` }}
              >
                <span>No data Available</span>
              </NoTableData>
            )}
          </CustomTable>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CouponList)
