import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import NoData from "components/Common/NoData"
import Toggle from "components/Common/Switch"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Button, CardText, CardTitle, Col, Row } from "reactstrap"
import { getIELTSReadingPassage, getIELTSSingleReadingSet } from "store/actions"
import { getAllVerbalQues } from "store/GMAT/Verbal/actions"
import Select from "react-select"

const levelType = [
  {
    name: "Easy",
    value: 1,
  },
  {
    name: "Medium",
    value: 2,
  },
  {
    name: "Difficult",
    value: 3,
  },
]
const ReadingPassage = ({ history }) => {
  const { serviceId, setId } = useParams()
  const [level, setLevel] = useState(1)
  const [state, setState] = useState({
    checked: false,
    size: "default",
    disabled: false,
  })
  const param = useParams()
  console.log(param)
  const dispatch = useDispatch()
  const { readingPassage, verbalQuestionLoading, authToken, singleReadingSet, verbalQuestion } =
    useSelector(state => ({
      authToken: state.Login.token,
      verbalQuestionLoading: state.verbalReducer.verbalQuestionLoading,
      verbalQuestion: state.verbalReducer.verbalQuestion,
      singleReadingSet: state.readingReducer.singleReadingSet,
    }))
  console.log('verbalQuestion', verbalQuestion  )
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Reading Set",
      link: `/reading-set/${serviceId}`,
    },
    {
      title: `${singleReadingSet?.setName || ""}`,
      link: "#",
    },
  ]

  const handleChange = e => {
    setState({ ...state, checked: e.target.checked })
  }

  console.log(readingPassage)

  useEffect(() => {
    dispatch(getAllVerbalQues(authToken, 1, level))
  }, [level])
  const handleLabel = data => {
    if (data) {
      setLevel(data?.value)
    } else {
      setLevel("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={ "Reading"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>{ ""}</h4>
              <div className="d-flex align-items-center">
                {/* <div className="search-box me-2">
                  <Toggle
                    checked={state.checked}
                    text="Is Active"
                    size={state.size}
                    disabled={state.disabled}
                    onChange={handleChange}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                  />
                </div> */}
                <div>
                  <div className="position-relative">
                    <Link
                      to={`/reading-comprehension/passage/create`}
                    >
                      <Button
                        type="button"
                        color="primary"
                        className="btn-circle me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Passage
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>
        <div>
      
      <div
        style={{
          width: "200px",
          marginLeft: "auto",
          marginBottom: "10px",
          marginBottom: "10px",
        }}
      >
        <Select
          name="status"
          className="select-form-width"
          classNamePrefix="select2-selection"
          placeholder="Select level"
          cacheOptions
          getOptionLabel={e => e.name}
          getOptionValue={e => e.value}
          isClearable
          options={levelType}
          onChange={handleLabel}
        />
      </div>
     </div>
        {verbalQuestionLoading ? (
          <div className="text-center  pt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            {verbalQuestion?.length > 0 ? (
              (verbalQuestion || []).map((data, key) => {
                return (
                  <Col md="6" key={key} >
                    <CardComponent>
                      <CardTitle tag="h5">
                        {/* {data?.description}  */}
                      </CardTitle>
                      <CardText className="passage-description" style={{height:"150px"}}>
                      {data?.description?.slice(0,600)} ...
                      </CardText>
                      <div className="d-flex justify-content-end" >
                        <Link
                          // to={`/reading-comprehension/${1}/${data?._id}/questionCreate`}
                          to={`/reading-comprehension/singlePassage/${data?._id}/${data?.questionQuant}`}
                        >
                          <div>
                            <Button
                              type="button"
                              color="primary"
                              className="btn btn-circle btn-sm m-1"
                            >
                              View Details
                            </Button>
                          </div>
                        </Link>
                        <Link
                          to={`/reading-comprehension/passage/${data?._id}/update`}
                        >
                          <div>
                            <Button
                              type="button"
                              color="primary"
                              style={{
                                background: "transparent",
                                color: "#485ec4",
                                border: "1px solid #485ec4",
                              }}
                              className="btn btn-sm m-1"
                            >
                              Edit Passage
                            </Button>
                          </div>
                        </Link>
                      </div>
                    </CardComponent>
                  </Col>
                )
              })
            ) : (
              <div>
                <NoData>
                  <p className="mb-0">Currently you have no passage.</p>
                  <p>
                    For next step first{" "}
                    <Link
                      to={`/service`}
                    >
                      Create passage
                    </Link>
                  </p>
                </NoData>
              </div>
            )}
          </Row>
        )}
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(ReadingPassage)
